import React from "react";
import { calcTaxIncludedPrice } from "dinii-self-js-lib/tax";
import { sumBy } from "lodash";
import { formatPrice } from "util/price";

import { LocalActivePlanDataSummary } from "../type";

type Props = {
  summary: LocalActivePlanDataSummary;
};

export const ActivePlanContent = React.memo<Props>(({ summary }) => {
  const { tableUserId, name, activePlanOptions, activePlanOpenPriceMeta } = summary;

  const totalQuantity = activePlanOpenPriceMeta
    ? activePlanOpenPriceMeta.quantity
    : sumBy(
        activePlanOptions.flatMap((apo) => apo.activePlanChoices),
        (apc) => apc.quantity,
      );

  return (
    <div>
      <p>
        {name} - {totalQuantity} 点
      </p>
      <pre>
        卓（{tableUserId}）{"\n\n"}
        {activePlanOptions
          .map((option) => {
            const choices = option.activePlanChoices
              .map((choice) => {
                const price = formatPrice(
                  calcTaxIncludedPrice({
                    price: choice.price,
                    taxRate: summary.taxRate,
                    taxMethod: summary.taxMethod,
                  }),
                );
                return `  ${choice.name} - ${price} × ${choice.quantity}`;
              })
              .join("\n");

            return `${option.name}\n${choices}`;
          })
          .join("\n")}
      </pre>
    </div>
  );
});
