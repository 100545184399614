import { useMemo } from "react";
import dayjs from "dayjs";
import { isNotNull, isNotNullish } from "dinii-self-js-lib/types";
import { uniqBy } from "lodash";

import { useDeviceStatus } from "hooks/useDeviceStatus";
import { useLocalAreaPrinters } from "hooks/useLocalAreaPrinters";
import { usePrinterControllerSettings } from "hooks/usePrinterControllerSettings";

export const LOCAL_PRINTER_INFO_EXPIRATION_DAYS = 7;
export const isPrinterInfoExpired = (updatedAt: dayjs.Dayjs) =>
  updatedAt.isBefore(dayjs().subtract(LOCAL_PRINTER_INFO_EXPIRATION_DAYS, "days"));

export const useStandaloneOperationSupportAvailability = ({
  shopId,
}: {
  shopId: string | undefined;
}) => {
  const { statuses: shopPrinters } = useDeviceStatus({ shopId });
  const { printers: localAreaPrinters } = useLocalAreaPrinters({ shopId });

  const directPrintShopPrinters = useMemo(
    () => shopPrinters.filter(({ epsonDeviceIdToRoleIdMap }) => epsonDeviceIdToRoleIdMap),
    [shopPrinters],
  );
  const printerControllerShopClientIds = useMemo(
    () =>
      shopPrinters
        .filter(({ epsonDeviceIdToRoleIdMap }) => !epsonDeviceIdToRoleIdMap)
        .map(({ uid }) => uid),
    [shopPrinters],
  );
  const { printerControllerSettings } = usePrinterControllerSettings({
    shopClientIds: printerControllerShopClientIds,
  });

  const localAreaPrinterShopClientNameToLocalAreaPrinterMap = useMemo(
    () =>
      new Map(
        localAreaPrinters
          .map((localAreaPrinter) =>
            localAreaPrinter.shopClientName
              ? ([localAreaPrinter.shopClientName, localAreaPrinter] as const)
              : null,
          )
          .filter(isNotNull)
          // NOTE: QA 環境では複数のプリンターに同じ shopClient が割り当てられていることがあるため、更新が新しいものを優先する
          .sort(
            ([, a], [, b]) =>
              (a.deviceInfoUpdatedAt?.toDate().getTime() ?? 0) -
              (b.deviceInfoUpdatedAt?.toDate().getTime() ?? 0),
          ),
      ),
    [localAreaPrinters],
  );

  const shopPrintersWithLocalAreaPrinter = useMemo(
    () =>
      directPrintShopPrinters
        .map((shopPrinter) => {
          const { email } = shopPrinter;
          const shopPrinterShopClientName = email?.split("@")[0] ?? null;
          if (!shopPrinterShopClientName) return null;

          const localAreaPrinter =
            localAreaPrinterShopClientNameToLocalAreaPrinterMap.get(shopPrinterShopClientName) ??
            null;
          return { shopPrinter, localAreaPrinter };
        })
        .filter(isNotNull),
    [directPrintShopPrinters, localAreaPrinterShopClientNameToLocalAreaPrinterMap],
  );

  const unknownLocalAreaPrinters = useMemo(
    () =>
      localAreaPrinters.filter(
        ({ macAddress }) =>
          !shopPrintersWithLocalAreaPrinter.some(
            ({ localAreaPrinter }) => localAreaPrinter?.macAddress === macAddress,
          ),
      ),
    [localAreaPrinters, shopPrintersWithLocalAreaPrinter],
  );

  const roleAssignments = useMemo(
    () =>
      [
        ...shopPrintersWithLocalAreaPrinter.flatMap(({ shopPrinter, localAreaPrinter }) =>
          Object.entries(shopPrinter.epsonDeviceIdToRoleIdMap ?? {}).map(([deviceId, role]) =>
            role
              ? {
                  type: "DirectPrint" as const,
                  role: { roleId: role.roleId, name: role.name },
                  shopPrinter,
                  deviceId,
                  localAreaPrinter,
                }
              : null,
          ),
        ),
        ...Object.values(printerControllerSettings).flatMap((roles) =>
          roles.map((role) => ({
            type: "PrinterController" as const,
            role: { roleId: role.roleId, name: role.roleName },
          })),
        ),
      ]
        .filter(isNotNullish)
        .sort((a, b) => a.role.roleId - b.role.roleId),
    [printerControllerSettings, shopPrintersWithLocalAreaPrinter],
  );

  const hasNoRoleAssignment = !roleAssignments.length;

  const hasDuplicatedRoleAssignment =
    roleAssignments.length !== uniqBy(roleAssignments, ({ role }) => role.roleId).length;

  const hasPrinterControllerRoleAssignment = roleAssignments.some(
    ({ type }) => type === "PrinterController",
  );

  const hasCashRegisterUnavailablePrinter = roleAssignments.some((assignment) =>
    assignment.type === "DirectPrint" ? !assignment.localAreaPrinter : false,
  );

  const hasOutdatedDeviceInfo = roleAssignments.some((assignment) => {
    if (assignment.type !== "DirectPrint") return false;
    const updatedAtTimestamp = assignment.localAreaPrinter?.deviceInfoUpdatedAt;
    if (!updatedAtTimestamp) return false;
    return isPrinterInfoExpired(dayjs(updatedAtTimestamp.toDate()));
  });

  const canEnableSafely = Boolean(
    !hasNoRoleAssignment &&
      !hasDuplicatedRoleAssignment &&
      !hasPrinterControllerRoleAssignment &&
      !hasCashRegisterUnavailablePrinter &&
      !hasOutdatedDeviceInfo,
  );

  return {
    canEnableSafely,
    hasNoRoleAssignment,
    hasDuplicatedRoleAssignment,
    hasPrinterControllerRoleAssignment,
    hasCashRegisterUnavailablePrinter,
    hasOutdatedDeviceInfo,
    roleAssignments,
    unknownLocalAreaPrinters,
  };
};
