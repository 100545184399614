import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { uniq } from "lodash";

import { UpdateFilterConditionFunctionType, useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import { useTableUserGroupingEventsGetTableUserGroupingEventsQuery } from "pages/TableUserGroupingEvents/queries";
import { FilterConditions } from "pages/TableUserGroupingEvents/TableUserFilter";

const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const useTableUserGroupingEvents = () => {
  const { getSearchParam, setSearchParam } = useSearchParams<
    "tableName" | "shopId" | "fromDate" | "untilDate"
  >();
  const shopId = getSearchParam("shopId");
  const tableName = getSearchParam("tableName");

  const untilDate = dayjs(getSearchParam("untilDate") || new Date()).endOf("day");
  const fromDate = dayjs(getSearchParam("fromDate") || untilDate.subtract(1, "month")).startOf(
    "day",
  );
  const [selectableDateRange, setSelectableDateRange] = useState<[string, string] | null>([
    fromDate.format(DATETIME_FORMAT),
    untilDate.format(DATETIME_FORMAT),
  ]);

  const { hasFilterConditions, filterConditions, updateFilterCondition, setFilterConditions } =
    useFilterConditions<FilterConditions>({
      tableName,
    });

  const { data, loading } = useTableUserGroupingEventsGetTableUserGroupingEventsQuery(
    shopId
      ? {
          variables: {
            shopId,
            from: fromDate.format(DATETIME_FORMAT),
            until: untilDate.format(DATETIME_FORMAT),
          },
        }
      : { skip: true },
  );

  const tableUserGroupingEvents = useMemo(
    () => data?.tableUserGroupingEvent ?? [],
    [data?.tableUserGroupingEvent],
  );

  const filteredTableUserGroupingEvents = useMemo(
    () =>
      tableUserGroupingEvents.filter(
        ({ tableUser, tableUserGroupingEventTableUsers }) =>
          !filterConditions.tableName ||
          tableUser.table.name === filterConditions.tableName ||
          tableUserGroupingEventTableUsers.some(
            ({ tableUser }) => tableUser.table.name === filterConditions.tableName,
          ),
      ),
    [tableUserGroupingEvents, filterConditions.tableName],
  );

  const tableNames = useMemo(
    () =>
      uniq(
        tableUserGroupingEvents
          .flatMap(({ tableUser, tableUserGroupingEventTableUsers }) => [
            tableUser.table.name,
            ...tableUserGroupingEventTableUsers.map(({ tableUser }) => tableUser.table.name),
          ])
          .sort((a, b) =>
            Number.isNaN(Number(a)) || Number.isNaN(Number(b))
              ? a.localeCompare(b)
              : Number(a) - Number(b),
          ),
      ),
    [tableUserGroupingEvents],
  );

  const handleUpdateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions> =
    useCallback(
      (filter) => {
        updateFilterCondition(filter);
        if ("tableName" in filter) setSearchParam("tableName", filter.tableName);
      },
      [updateFilterCondition, setSearchParam],
    );

  const handleClearFilterCondition = useCallback(() => {
    setFilterConditions({});
    setSearchParam("tableName", null);
  }, [setFilterConditions, setSearchParam]);

  const handleCalendarSelectableDateRangeChange = useCallback(
    (_: RangeValue, [fromDate, untilDate]: [string, string]) => {
      if (!fromDate && untilDate) {
        setSelectableDateRange([
          dayjs(untilDate).subtract(1, "month").startOf("day").format("YYYY-MM-DD HH:mm"),
          untilDate,
        ]);
        return;
      }
      if (fromDate && !untilDate) {
        setSelectableDateRange([
          fromDate,
          dayjs(fromDate).add(1, "month").endOf("day").format("YYYY-MM-DD HH:mm"),
        ]);
        return;
      }
      if (fromDate && untilDate) {
        setSelectableDateRange([
          dayjs(untilDate).subtract(1, "month").startOf("day").format("YYYY-MM-DD HH:mm"),
          dayjs(fromDate).add(1, "month").endOf("day").format("YYYY-MM-DD HH:mm"),
        ]);
        return;
      }
      setSelectableDateRange(null);
    },
    [setSelectableDateRange],
  );

  const handleCalendarDateChange = useCallback(
    (_: RangeValue, [fromDate, untilDate]: [string, string]) => {
      setSearchParam("fromDate", fromDate);
      setSearchParam("untilDate", untilDate);
    },
    [setSearchParam],
  );

  const disabledDate = useCallback(
    (current: dayjs.Dayjs) =>
      Boolean(
        selectableDateRange &&
          (dayjs(selectableDateRange[1]).isBefore(current) ||
            dayjs(selectableDateRange[0]).isAfter(current)),
      ),
    [selectableDateRange],
  );

  return {
    fromDate,
    untilDate,
    tableUserGroupingEvents: filteredTableUserGroupingEvents,
    loading,
    tableNames,
    handleUpdateFilterCondition,
    handleClearFilterCondition,
    filterConditions,
    hasFilterConditions,
    handleCalendarSelectableDateRangeChange,
    handleCalendarDateChange,
    disabledDate,
  };
};
