import { defaultDataIdFromObject, gql, InMemoryCache } from "@apollo/client";

const query = gql`
  query {
    currentUser @client
    currentCompany @client
    currentShop @client
    navigationDrawer @client
  }
`;

const writeDefaultQuery = (cache: InMemoryCache) =>
  cache.writeQuery({
    query,
    data: {
      currentUser: undefined,
      currentCompany: undefined,
      currentShop: undefined,
      navigationDrawer: { collapsed: null, openKeys: null },
    },
  });

export const createCache = () => {
  const cache = new InMemoryCache({
    /* eslint-disable @typescript-eslint/no-base-to-string */
    dataIdFromObject(object) {
      switch (object.__typename) {
        case "category":
          if (object.categoryId) return `${object.__typename}:${object.categoryId}`;
          return defaultDataIdFromObject(object);
        case "clerk":
          if (object.clerkId) return `${object.__typename}:${object.clerkId}`;
          return defaultDataIdFromObject(object);
        case "company":
          if (object.companyId) return `${object.__typename}:${object.companyId}`;
          return defaultDataIdFromObject(object);
        case "menu":
          if (object.menuId) return `${object.__typename}:${object.menuId}`;
          return defaultDataIdFromObject(object);
        case "categoryMenu":
          if (object.categoryMenuId) return `${object.__typename}:${object.categoryMenuId}`;
          return defaultDataIdFromObject(object);
        case "option":
          if (object.optionId) return `${object.__typename}:${object.optionId}`;
          return defaultDataIdFromObject(object);
        case "choice":
          if (object.choiceId) return `${object.__typename}:${object.choiceId}`;
          return defaultDataIdFromObject(object);
        case "plan":
          if (object.planId) return `${object.__typename}:${object.planId}`;
          return defaultDataIdFromObject(object);
        case "planOption":
          if (object.planOptionId) return `${object.__typename}:${object.planOptionId}`;
          return defaultDataIdFromObject(object);
        case "planChoice":
          if (object.planChoiceId) return `${object.__typename}:${object.planChoiceId}`;
          return defaultDataIdFromObject(object);
        case "planGroup":
          if (object.planGroupId) return `${object.__typename}:${object.planGroupId}`;
          return defaultDataIdFromObject(object);
        case "serviceAdmin":
          if (object.serviceAdminId) return `${object.__typename}:${object.serviceAdminId}`;
          return defaultDataIdFromObject(object);
        case "dashboardAccount":
          if (object.dashboardAccountId) return `${object.__typename}:${object.dashboardAccountId}`;
          return defaultDataIdFromObject(object);
        case "shop":
          if (object.shopId) return `${object.__typename}:${object.shopId}`;
          return defaultDataIdFromObject(object);
        case "table":
          if (object.tableId) return `${object.__typename}:${object.tableId}`;
          return defaultDataIdFromObject(object);
        case "role":
          if (object.roleId) return `${object.__typename}:${object.roleId}`;
          return defaultDataIdFromObject(object);
        case "pikaichiConfig":
          if (object.pikaichiConfigId) return `${object.__typename}:${object.pikaichiConfigId}`;
          return defaultDataIdFromObject(object);
        case "pikaichiMenu":
          if (object.pikaichiMenuId) return `${object.__typename}:${object.pikaichiMenuId}`;
          return defaultDataIdFromObject(object);
        case "pikaichiMenuMenu":
          if (object.pikaichiMenuId && object.menuId) {
            return `${object.__typename}:${object.pikaichiMenuId}-${object.menuId}`;
          }
          return defaultDataIdFromObject(object);
        case "pikaichiMenuChoice":
          if (object.pikaichiMenuId && object.choiceId) {
            return `${object.__typename}:${object.pikaichiMenuId}-${object.choiceId}`;
          }
          return defaultDataIdFromObject(object);
        case "pikaichiMenuPlan":
          if (object.pikaichiMenuId && object.planId) {
            return `${object.__typename}:${object.pikaichiMenuId}-${object.planId}`;
          }
          return defaultDataIdFromObject(object);
        case "pikaichiMenuPlanChoice":
          if (object.pikaichiMenuId && object.planChoiceId) {
            return `${object.__typename}:${object.pikaichiMenuId}-${object.planChoiceId}`;
          }
          return defaultDataIdFromObject(object);
        case "tecAggregationConfig":
          if (object.id) return `${object.__typename}:${object.id}`;
          return defaultDataIdFromObject(object);
        case "tecAggregationMenu":
          if (object.id) return `${object.__typename}:${object.id}`;
          return defaultDataIdFromObject(object);
        case "accountingSlipImage":
          if (object.accountingSlipImageId) {
            return `${object.__typename}:${object.accountingSlipImageId}`;
          }
          return defaultDataIdFromObject(object);
        case "onSitePayment":
          if (object.onSitePaymentId) return `${object.__typename}:${object.onSitePaymentId}`;
          return defaultDataIdFromObject(object);
        case "onSitePaymentDetail":
          if (object.onSitePaymentDetailId) {
            return `${object.__typename}:${object.onSitePaymentDetailId}`;
          }
          return defaultDataIdFromObject(object);
        case "onSitePaymentSales":
          if (object.onSitePaymentSalesId) {
            return `${object.__typename}:${object.onSitePaymentSalesId}`;
          }
          return defaultDataIdFromObject(object);
        case "roleTablesTable":
          if (object.roleRoleId && object.tableTableId) {
            return `${object.__typename}:${object.roleRoleId}:${object.tableTableId}`;
          }
          return defaultDataIdFromObject(object);
        case "stock":
          if (object.stockId) return `${object.__typename}:${object.stockId}`;
          return defaultDataIdFromObject(object);
        case "shopMenu":
          if (object.shopId && object.menuId) {
            return `${object.__typename}:${object.shopId}:${object.menuId}`;
          }
          return defaultDataIdFromObject(object);
        case "shopMenuKitchenRole":
          if (object.shopId && object.menuId && object.roleId) {
            return `${object.__typename}:${object.shopId}:${object.menuId}:${object.roleId}`;
          }
          return defaultDataIdFromObject(object);
        default:
          return defaultDataIdFromObject(object);
      }
    },
    /* eslint-enable @typescript-eslint/no-base-to-string */
  });

  writeDefaultQuery(cache);

  return cache;
};
