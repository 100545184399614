import { isNotNullish } from "dinii-self-js-lib/types";

import { client } from "./client";
import { converter, SupportedConfigSections } from "./converter";
import { ManagedConfig, PrinterAddress } from "./types";

type Message = { error: string } | { info: string };

export const createEpsonWebConfigUpdateScript = (printer: PrinterAddress, input: ManagedConfig) => {
  const utils = {
    ...client,
    ...converter,
    sleep: (msec: number) =>
      new Promise<"SLEEP_RESULT_TIMEOUT">((resolve) =>
        setTimeout(() => resolve("SLEEP_RESULT_TIMEOUT"), msec),
      ),
    isNotNullish,
  };

  return `{
    (${(async (
      {
        invokeCpssio,
        fetchIndexOptions,
        invokeRequestToCore0,
        toWebConfig,
        fromWebConfig,
        sleep,
        isNotNullish,
      }: typeof utils,
      printer: PrinterAddress,
      input: ManagedConfig,
    ) => {
      const messageElement = document.querySelector("body");
      const resetMessage = async () => {
        if (!messageElement) return;
        messageElement.innerHTML = "";
        await sleep(500);
      };
      const showMessages = async (messages: Message[]) => {
        if (!messageElement) return;
        const info = messages
          .flatMap((message) => ("info" in message ? [...message.info.split("\n"), ""] : null))
          .filter(isNotNullish);
        const error = messages
          .map((message) => ("error" in message ? [...message.error.split("\n"), ""] : null))
          .filter(isNotNullish);
        messageElement.innerHTML =
          messageElement.innerHTML +
          [
            `<pre>`,
            info.length ? `${info.join("<br>")}<br>` : "",
            error.length ? `<span style="color:red;">${error.join("<br>")}</span>` : "",
            `</pre>`,
          ].join("");
        // DOM への変更を適用するためにsleepしている
        await sleep(500);
      };

      const fetchConfig = async () => {
        const result = await invokeCpssio(printer, {
          get: {
            cpssio: {
              PrinterList: {},
              ServerDirectPrint: {},
              StatusNotification: {},
            },
          },
        });

        if (!result.data) {
          // eslint-disable-next-line no-console
          console.log(result.error);
          await showMessages([{ error: `現在の設定の取得に失敗しました: ${result.error?.type}` }]);
          return null;
        }

        const { PrinterList, ServerDirectPrint, StatusNotification } = result.data;
        if (!PrinterList || !ServerDirectPrint || !StatusNotification) {
          await showMessages([{ error: "レスポンスに問題が見つかりました" }]);
          return null;
        }

        const raw: SupportedConfigSections = { PrinterList, ServerDirectPrint, StatusNotification };
        const managed: ManagedConfig | null = fromWebConfig(raw);
        return { raw, managed };
      };

      const updateConfig = async (raw: SupportedConfigSections) => {
        const next = toWebConfig(input, raw);
        if (!next) {
          return;
        }

        await invokeRequestToCore0(printer, "MODEIN");
        await invokeCpssio(printer, { set: { cpssio: next } });
        await invokeRequestToCore0(printer, "MODEOUT");
        await sleep(3000);
        const result = await Promise.race([fetchIndexOptions(printer), sleep(60000)]);

        if (result === "SLEEP_RESULT_TIMEOUT") {
          await showMessages([
            { info: "更新にタイムアウトしました。更新に失敗している可能性があります。" },
          ]);
          return;
        }
      };

      const createConfigText = (managed: ManagedConfig | null) =>
        [
          `  環境: ${managed?.env}`,
          `  /printer/direct_print のインターバル: ${managed?.printInterval}`,
          `  /printer/direct_print/status のインターバル: ${managed?.statusInterval}`,
          `  shopClient 名: ${managed?.shopClientName}`,
          `  デバイスID一覧:`,
          managed?.printerList?.map(
            ({ deviceId, ipAddress, model }) => `    ${deviceId}: ${ipAddress} [${model}]`,
          ),
        ].join("\n");

      const main = async () => {
        await resetMessage();
        await showMessages([{ info: "現在の設定を取得中です..." }]);
        const oldConfig = await fetchConfig();
        if (!oldConfig) {
          return;
        }

        await showMessages([
          {
            info: [
              `現在の設定`,
              createConfigText(oldConfig.managed ?? null),
              "",
              `新しい設定`,
              createConfigText({
                ...input,
                printerList: input.printerList ?? oldConfig.managed?.printerList ?? null,
              }),
            ].join("\n"),
          },
        ]);

        await sleep(3000);

        if (!confirm("設定を反映しますか？")) {
          await showMessages([{ error: "キャンセルされました" }]);
          return;
        }

        await showMessages([{ info: "設定を反映中です..." }]);
        await updateConfig(oldConfig.raw);
        const newConfig = await fetchConfig();
        if (!newConfig) return;

        await showMessages([
          {
            info: [`反映された設定`, createConfigText(newConfig.managed ?? null)].join("\n"),
          },
        ]);
      };

      main();
    }).toString()})(
      (${(u: typeof utils): typeof utils => ({
        isValidPrivateIpAddress: u.isValidPrivateIpAddress.bind(u),
        getAuthorizationHeader: u.getAuthorizationHeader.bind(u),
        sendWebConfigIRequest: u.sendWebConfigIRequest.bind(u),
        invokeCpssio: u.invokeCpssio.bind(u),
        invokeRequestToCore0: u.invokeRequestToCore0.bind(u),
        fetchIndexOptions: u.fetchIndexOptions.bind(u),

        REDACTED_PASSWORD: u.REDACTED_PASSWORD,
        PRIMARY_PRINTER_TARGET_DEVICE_ID: u.PRIMARY_PRINTER_TARGET_DEVICE_ID,
        parseProductInfoFromUrl: u.parseProductInfoFromUrl.bind(u),
        parseDirectPrintUrl: u.parseDirectPrintUrl.bind(u),
        stringifyDirectPrintUrl: u.stringifyDirectPrintUrl.bind(u),
        fromWebConfig: u.fromWebConfig.bind(u),
        toWebConfig: u.toWebConfig.bind(u),

        sleep: u.sleep,
        isNotNullish: u.isNotNullish,
      })})({
        ${Object.entries(utils)
          .map(([key, value]) => {
            if (typeof value === "string") return `${key}: ${JSON.stringify(value)}`;

            const fnText = value.toString();
            return fnText.startsWith(key) || fnText.startsWith(`async ${key}`)
              ? fnText
              : `${key}: ${value.toString()}`;
          })
          .join(",\n")}
      }),
      ${JSON.stringify(printer)},
      ${JSON.stringify(input)},
    );
  }`;
};
