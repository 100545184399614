import React from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import jaJP from "antd/es/locale/ja_JP";
import { ApolloProvider } from "@apollo/client";
import { AppContextProvider } from "context/AppContext";

import { App } from "components/App";
import { Router } from "components/Router";
import { client } from "store/client";
import { GlobalStyle } from "styles/global";

import "antd/dist/reset.css";

const queryClient = new QueryClient();

export const Root = () => (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={jaJP}>
          <AppContextProvider>
            <GlobalStyle />
            <App>
              <Router />
              <Toaster />
            </App>
          </AppContextProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </ApolloProvider>
  </BrowserRouter>
);
