import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CashRegisterStatusGetShopClients = gql`
    query CashRegisterStatusGetShopClients($shopId: uuid!) {
  shopClient(where: {shopId: {_eq: $shopId}}) {
    id: shopClientId
    shopClientId
    name
    email
    shopId
  }
}
    `;
export type CashRegisterStatusGetShopClientsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type CashRegisterStatusGetShopClientsQuery = (
  { __typename?: 'query_root' }
  & { shopClient: Array<(
    { __typename?: 'shopClient' }
    & Pick<Types.ShopClient, 'shopClientId' | 'name' | 'email' | 'shopId'>
    & { id: Types.ShopClient['shopClientId'] }
  )> }
);


export const CashRegisterStatusGetShopClientsDocument = gql`
    query CashRegisterStatusGetShopClients($shopId: uuid!) {
  shopClient(where: {shopId: {_eq: $shopId}}) {
    id: shopClientId
    shopClientId
    name
    email
    shopId
  }
}
    `;

/**
 * __useCashRegisterStatusGetShopClientsQuery__
 *
 * To run a query within a React component, call `useCashRegisterStatusGetShopClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashRegisterStatusGetShopClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashRegisterStatusGetShopClientsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useCashRegisterStatusGetShopClientsQuery(baseOptions: Apollo.QueryHookOptions<CashRegisterStatusGetShopClientsQuery, CashRegisterStatusGetShopClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CashRegisterStatusGetShopClientsQuery, CashRegisterStatusGetShopClientsQueryVariables>(CashRegisterStatusGetShopClientsDocument, options);
      }
export function useCashRegisterStatusGetShopClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CashRegisterStatusGetShopClientsQuery, CashRegisterStatusGetShopClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CashRegisterStatusGetShopClientsQuery, CashRegisterStatusGetShopClientsQueryVariables>(CashRegisterStatusGetShopClientsDocument, options);
        }
export type CashRegisterStatusGetShopClientsQueryHookResult = ReturnType<typeof useCashRegisterStatusGetShopClientsQuery>;
export type CashRegisterStatusGetShopClientsLazyQueryHookResult = ReturnType<typeof useCashRegisterStatusGetShopClientsLazyQuery>;
export type CashRegisterStatusGetShopClientsQueryResult = Apollo.QueryResult<CashRegisterStatusGetShopClientsQuery, CashRegisterStatusGetShopClientsQueryVariables>;