import { collection, doc, increment, setDoc } from "firebase/firestore";

import { firestore } from "libs/firebase";

const ref = collection(firestore, "deviceRemoteManagement");

const statusRef = collection(firestore, "deviceStatus");

export const softReboot = (uid: string) => {
  if (window.confirm(`${uid}を再起動しますか？`)) {
    // eslint-disable-next-line import/no-named-as-default-member
    setDoc(doc(ref, uid), { softRebootId: increment(1) }, { merge: true });
  } else {
    window.alert("キャンセルされました"); // 警告ダイアログを表示
  }
};

export const saveMemo = (docId: string, memo: string) => {
  setDoc(doc(statusRef, docId), { memo }, { merge: true });
  window.alert("保存しました");
};

export const saveDisableAlertUntilDate = (docId: string, disableAlertUntilDate?: Date | null) => {
  setDoc(doc(statusRef, docId), { disableAlertUntilDate }, { merge: true });
  window.alert("保存しました");
};

export const reboot = (uid: string) => {
  if (window.confirm(`${uid}を再起動しますか？`)) {
    // eslint-disable-next-line import/no-named-as-default-member
    setDoc(doc(ref, uid), { rebootId: increment(1) }, { merge: true });
  } else {
    window.alert("キャンセルされました"); // 警告ダイアログを表示
  }
};

export const sendCommandWithoutConfirm = async (uid: string, command: string) => {
  await setDoc(doc(ref, uid), { command: "none" }, { merge: true });
  setTimeout(async () => {
    await setDoc(doc(ref, uid), { command }, { merge: true });
  }, 2000);
};

export const checkBluetooth = async (uid: string) => {
  await sendCommandWithoutConfirm(uid, "checkBluetooth");
};

export const sendCommand = async (uid: string, command: string) => {
  if (window.confirm(`send ${command} to ${uid}?`)) {
    await sendCommandWithoutConfirm(uid, command);
  } else {
    window.alert("キャンセルされました"); // 警告ダイアログを表示
  }
};
