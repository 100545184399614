import React, { memo } from "react";
import { Form, FormItemProps } from "antd";
import { isNotNullish } from "dinii-self-js-lib/types";
import { createNotificationTargetCompanyKey } from "models/notificationSettings";

import { FormHelp } from "components/Form/FormHelp";
import { InputCustomFormatText } from "components/Input/InputCustomFormatText";

type Props = {
  disabled: boolean;
} & Omit<FormItemProps, "name" | "label" | "children">;

export const CompanyKeysByTextField = memo<Props>(({ disabled, ...props }) => (
  <Form.Item
    label={<FormHelp label="業態単位(JSON)" help="全選択 → コピー＆ペーストで入力してください" />}
    name="companyKeys"
    {...props}
  >
    <InputCustomFormatText
      disabled={disabled}
      serialize={(data) =>
        JSON.stringify(data.map((key) => key.split(":")[1] ?? null).filter(isNotNullish))
      }
      deserialize={(value) => {
        try {
          const rawData = JSON.parse(value);
          if (!Array.isArray(rawData)) return [];
          return rawData
            .map((item) => (typeof item === "string" ? item : null))
            .filter(isNotNullish)
            .map((companyId) => createNotificationTargetCompanyKey(companyId));
        } catch {
          return [];
        }
      }}
    />
  </Form.Item>
));
