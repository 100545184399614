import "firebase/auth";

import axiosBase from "axios";

import { auth } from "./firebase";

export const getBaseURL = () => {
  switch (import.meta.env.APP_ENV) {
    case "local":
      return "http://localhost:8080";
    default:
      return import.meta.env.API_BASE_URL;
  }
};

export const request = axiosBase.create({
  baseURL: getBaseURL(),
});

request.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (!user) throw new Error("user not login");
  return {
    ...config,
    headers: {
      ...config.headers,
      "x-auth-key": await user.getIdToken(),
    },
  };
});
