import React, { memo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { ActivePlan } from "../types";

type Props = {
  loading: boolean;
  activePlans: ActivePlan[];
};

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0f0f0;
  &:last-child {
    border-bottom: none;
  }
`;

const OptionName = styled.p`
  margin-bottom: 0;
`;

const ChoiceItem = styled.p`
  margin: 0 0 0 12px;
`;

export const ActivePlanTable = memo<Props>(({ loading, activePlans }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "プラン名",
      render(_: unknown, event: ActivePlan) {
        return event.name;
      },
    },
    {
      title: "適用日時",
      width: 500,
      render(_: unknown, event: ActivePlan) {
        return dayjs(event.startAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "終了日時",
      render(_: unknown, event: ActivePlan) {
        if (!event.endAt) return "無制限";
        return dayjs(event.endAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "オプション",
      render(_: unknown, event: ActivePlan) {
        return (
          <List>
            {event.activePlanOptions.map((option) => (
              <ListItem key={option.activePlanOptionId}>
                <OptionName>{option.name}</OptionName>
                {option.activePlanChoices.map((choice) => (
                  <ChoiceItem key={choice.activePlanChoiceId}>
                    {choice.name} x {choice.quantity}
                  </ChoiceItem>
                ))}
              </ListItem>
            ))}
          </List>
        );
      },
    },
  ];

  return (
    <Table
      rowKey={({ id }) => id}
      columns={columns}
      dataSource={activePlans}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
