import React, { memo } from "react";
import { Tag } from "antd";

import { TableUser } from "types/graphql";

type Props = {
  isTakeOut: TableUser["isTakeOut"];
};

export const TableUserTakeOutStatus = memo<Props>(({ isTakeOut }) =>
  isTakeOut ? <Tag color="default">店外</Tag> : <Tag color="default">店内</Tag>,
);
