import React, { memo, useMemo } from "react";
import { Form, FormItemProps, Select } from "antd";
import { createNotificationTargetShopKey } from "models/notificationSettings";

import { Company } from "hooks/useShops/types";

type Props = {
  loading: boolean;
  disabled: boolean;
  companies: Company[];
  getShopName: (shopId: string) => string | undefined;
} & Omit<FormItemProps, "name" | "label" | "children">;

export const ShopKeysField = memo<Props>(
  ({ loading, disabled, companies, getShopName, ...props }) => {
    const options = useMemo(
      () =>
        companies.map(({ name: companyName, shops }) => ({
          label: companyName,
          options: shops.map(({ shopId }) => ({
            filterKey: `${companyName} ${getShopName(shopId)}`,
            label: getShopName(shopId),
            value: createNotificationTargetShopKey(shopId),
          })),
        })),
      [companies, getShopName],
    );

    return (
      <Form.Item label="店舗個別配信" name="shopKeys" {...props}>
        <Select
          mode="multiple"
          loading={loading}
          disabled={disabled}
          placeholder="選択してください"
          optionFilterProp="filterKey"
          options={options}
        />
      </Form.Item>
    );
  },
);
