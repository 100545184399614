import React, { createContext, memo, useCallback, useContext, useEffect, useState } from "react";
import { isKeyInObject } from "dinii-self-js-lib/types";

import { claimKeyForHasuraDefaultRole, claimNamespaceForHasura } from "../constants/hasura";
import { auth } from "../libs/firebase";

const SELECTED_SHOP_ID_KEY = "selectedShopId";

export const SELECTED_THEME_KEY = "selectedTheme";

export type AppContext = {
  theme: string;
  setTheme: (theme: string) => void;
  isAuthenticated: boolean | null;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  selectedShopId: string | null;
  setSelectedShopId: (shopId: string | null) => void;
  selectedMenuKey: string;
  setSelectedMenuKey: (key: string) => void;
};

const initialAppContext: AppContext = {
  theme: localStorage.getItem(SELECTED_THEME_KEY) ?? "light",
  setTheme: () => {},
  isAuthenticated: null,
  setIsAuthenticated: () => {},
  selectedShopId: localStorage.getItem(SELECTED_SHOP_ID_KEY),
  setSelectedShopId: () => {},
  selectedMenuKey: "/",
  setSelectedMenuKey: () => {},
};

export const AppContext = createContext<AppContext>(initialAppContext);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = memo<React.PropsWithChildren>(({ children }) => {
  const [theme, setTheme] = useState(initialAppContext.theme);
  const [isAuthenticated, setIsAuthenticated] = useState(initialAppContext.isAuthenticated);
  const [selectedShopId, setSelectedShopId] = useState(initialAppContext.selectedShopId);
  const [selectedMenuKey, setSelectedMenuKey] = useState(initialAppContext.selectedMenuKey);

  const onSetSelectedShopId = useCallback((shopId: string | null) => {
    if (shopId) {
      localStorage.setItem(SELECTED_SHOP_ID_KEY, shopId);
    } else {
      localStorage.removeItem(SELECTED_SHOP_ID_KEY);
    }
    setSelectedShopId(shopId);
  }, []);

  const onSelectTheme = useCallback((theme: string) => {
    setTheme(theme);
    localStorage.setItem(SELECTED_THEME_KEY, theme);
  }, []);

  useEffect(
    () =>
      auth.onIdTokenChanged(async (currentUser) => {
        const claims = (await currentUser?.getIdTokenResult())?.claims;
        const hasuraClaims = claims?.[claimNamespaceForHasura];
        const role = isKeyInObject(hasuraClaims, claimKeyForHasuraDefaultRole)
          ? hasuraClaims?.[claimKeyForHasuraDefaultRole]
          : null;

        setIsAuthenticated(role === "monitor");
      }),
    [],
  );

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme: onSelectTheme,
        isAuthenticated,
        setIsAuthenticated,
        selectedShopId,
        setSelectedShopId: onSetSelectedShopId,
        selectedMenuKey,
        setSelectedMenuKey,
      }}
    >
      {children}
    </AppContext.Provider>
  );
});
