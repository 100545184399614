import React, { memo } from "react";
import { Tag } from "antd";

import { TableUser } from "types/graphql";

type Props = {
  state: TableUser["state"];
};

export const TableUserStatus = memo<Props>(({ state }) => {
  switch (state) {
    case "active":
      return <Tag color="error">アクティブ</Tag>;
    case "registerCheckInProgress":
    case "tableCheckInProgress":
    case "tableCheckRequested":
      return <Tag color="warning">会計待ち</Tag>;
    case "inactive":
      return <Tag color="default">インアクティブ</Tag>;
  }
});
