import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import { useAppContext } from "context/AppContext";

type Props = {
  to: string;
  text: string;
} & MenuItemProps;

export const MenuItem = memo<Props>(({ to, text, children: _, ...props }) => {
  const { setSelectedMenuKey } = useAppContext();

  return (
    <Menu.Item onClick={() => setSelectedMenuKey(to.replace(/\?.*$/, ""))} {...props}>
      <span>{text}</span>
      <Link to={to} />
    </Menu.Item>
  );
});
