import React, { memo, useCallback, useMemo, useState } from "react";
import { useAsync, useAsyncFn } from "react-use";
import { Alert, Button } from "antd";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { isStaging } from "constants/environments";
import { useSearchParams } from "hooks/useSearchParams";
import { useShops } from "hooks/useShops";
import { ShopClientTable } from "pages/ShopClients/ShopClientTable";

import { ShopClient } from "../../entity/shopClient.entity";
import { request } from "../../libs/api";

import { CreateShopClientDialog } from "./CreateShopClientDialog";
import { RemoveShopClientDialog } from "./RemoveShopClientDialog";
import { SetShopClientClaimsDialog } from "./SetShopClientClaimsDialog";

export const ShopClients = memo(() => {
  const [shopClients, setShopClients] = useState<ShopClient[]>([]);
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId");
  const [createShopClientModalOpen, setCreateShopClientModalOpen] = useState(false);
  const [selectedShopClient, setSelectedShopClient] = useState<ShopClient | null>(null);
  const [shopClientToBeRemoved, setShopClientToBeRemoved] = useState<ShopClient | null>(null);
  const { shops } = useShops();
  const selectedShop = useMemo(
    () => shops.find((shop) => shop.shopId === shopId) ?? null,
    [shops, shopId],
  );

  const [{ loading }, fetchShopClients] = useAsyncFn(async () => {
    if (!selectedShop?.company) return;

    const { data } = await request.get(`shops/${selectedShop.shopId}/shop_clients`);

    setShopClients(data);
  }, [selectedShop]);

  useAsync(async () => {
    await fetchShopClients();
  }, [fetchShopClients]);

  const closeDialog = useCallback(async () => {
    setCreateShopClientModalOpen(false);
    setSelectedShopClient(null);
    setShopClientToBeRemoved(null);

    await fetchShopClients();
  }, [fetchShopClients]);

  return (
    <MainLayout title="Shop Clients">
      <ShopSelector />

      <Spacer size={16} />

      {isStaging && (
        <>
          <Alert
            type="info"
            message="ステージング用のアカウントについて"
            description="ステージングでのパスワードはすべて testshop2020 になります"
          />
          <Spacer size={16} />
        </>
      )}

      <Button onClick={() => setCreateShopClientModalOpen(true)}>Create Shop Client</Button>

      <Spacer size={16} />

      <ShopClientTable
        loading={loading}
        shopClients={shopClients}
        setSelectedShopClient={setSelectedShopClient}
        setRemoveShopClient={setShopClientToBeRemoved}
      />

      {selectedShop && (
        <CreateShopClientDialog
          key={createShopClientModalOpen ? "open" : "closed"}
          shop={selectedShop}
          isOpen={createShopClientModalOpen}
          onClose={closeDialog}
        />
      )}

      {selectedShopClient && (
        <SetShopClientClaimsDialog
          shopClient={selectedShopClient}
          isOpen={selectedShopClient !== null}
          onClose={closeDialog}
        />
      )}

      {shopClientToBeRemoved && (
        <RemoveShopClientDialog
          shopClient={shopClientToBeRemoved}
          isOpen={shopClientToBeRemoved !== null}
          onClose={closeDialog}
        />
      )}
    </MainLayout>
  );
});
