import React, { memo } from "react";
import styled from "styled-components";
import { Breadcrumb } from "antd";

const Wrapper = styled.div`
  margin: 32px 0 0 24px;
`;

export type LocationBreadcrumbProps = {
  items: {
    name: string;
  }[];
};

export const LocationBreadcrumb = memo<LocationBreadcrumbProps>(({ items }) => (
  <Wrapper>
    <Breadcrumb>
      {items.map((item) => (
        <Breadcrumb.Item key={item.name}>{item.name}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  </Wrapper>
));
