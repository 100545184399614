import { useAsyncFn } from "react-use";
import { Form } from "antd";

export type ShopClientFormFormValues = {
  name?: string;
  email?: string;
  password?: string;
};

const getInitialValues = () => ({});

export const useCreateShopClientDialogForm = ({
  onSubmit,
}: {
  onSubmit: (formValues: ShopClientFormFormValues) => Promise<void>;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const [{ loading }, submit] = useAsyncFn(async () => {
    const values = form.getFieldsValue() as ShopClientFormFormValues;
    await onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit, submitting: loading };
};
