import React from "react";
import { sumBy } from "lodash";
import { formatPrice } from "util/price";

import { LocalOnSitePaymentDataSummary } from "../type";

type Props = {
  summary: LocalOnSitePaymentDataSummary;
};

export const OnSitePaymentContent = React.memo<Props>(({ summary }) => {
  const { tableUserIds, details } = summary;
  return (
    <div>
      <p>{formatPrice(sumBy(details, (detail) => detail.amount))}</p>
      <pre>{tableUserIds.map((tableUserId) => `卓（${tableUserId}）`).join("\n")}</pre>
    </div>
  );
});
