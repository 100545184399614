import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopsGetCompaniesAndShops = gql`
    query ShopsGetCompaniesAndShops {
  company(where: {archivedAt: {_is_null: true}}, order_by: {companyId: asc}) {
    id
    companyId
    corporationId
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {shopId: asc}) {
      shopId
    }
  }
  shop(
    where: {archivedAt: {_is_null: true}}
    order_by: [{companyId: asc}, {shopId: asc}]
  ) {
    shopId
    name
    enableDefaultInflowSourceTag
    company {
      id
      serial
    }
  }
}
    `;
export type ShopsGetCompaniesAndShopsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopsGetCompaniesAndShopsQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'companyId' | 'corporationId' | 'name'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId'>
    )> }
  )>, shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'enableDefaultInflowSourceTag'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'serial'>
    ) }
  )> }
);


export const ShopsGetCompaniesAndShopsDocument = gql`
    query ShopsGetCompaniesAndShops {
  company(where: {archivedAt: {_is_null: true}}, order_by: {companyId: asc}) {
    id
    companyId
    corporationId
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {shopId: asc}) {
      shopId
    }
  }
  shop(
    where: {archivedAt: {_is_null: true}}
    order_by: [{companyId: asc}, {shopId: asc}]
  ) {
    shopId
    name
    enableDefaultInflowSourceTag
    company {
      id
      serial
    }
  }
}
    `;

/**
 * __useShopsGetCompaniesAndShopsQuery__
 *
 * To run a query within a React component, call `useShopsGetCompaniesAndShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsGetCompaniesAndShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsGetCompaniesAndShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopsGetCompaniesAndShopsQuery(baseOptions?: Apollo.QueryHookOptions<ShopsGetCompaniesAndShopsQuery, ShopsGetCompaniesAndShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopsGetCompaniesAndShopsQuery, ShopsGetCompaniesAndShopsQueryVariables>(ShopsGetCompaniesAndShopsDocument, options);
      }
export function useShopsGetCompaniesAndShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsGetCompaniesAndShopsQuery, ShopsGetCompaniesAndShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopsGetCompaniesAndShopsQuery, ShopsGetCompaniesAndShopsQueryVariables>(ShopsGetCompaniesAndShopsDocument, options);
        }
export type ShopsGetCompaniesAndShopsQueryHookResult = ReturnType<typeof useShopsGetCompaniesAndShopsQuery>;
export type ShopsGetCompaniesAndShopsLazyQueryHookResult = ReturnType<typeof useShopsGetCompaniesAndShopsLazyQuery>;
export type ShopsGetCompaniesAndShopsQueryResult = Apollo.QueryResult<ShopsGetCompaniesAndShopsQuery, ShopsGetCompaniesAndShopsQueryVariables>;