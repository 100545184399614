/* eslint-disable import/no-duplicates */
import "firebase/auth";
import "firebase/firestore";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, doc, getFirestore, onSnapshot, setDoc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: import.meta.env.FIREBASE_API_KEY,
  authDomain: import.meta.env.AUTH_DOMAIN,
  databaseURL: import.meta.env.FIREBASE_DB,
  projectId: import.meta.env.FIREBASE_PROJECT,
  storageBucket: import.meta.env.FIREBASE_STORAGE,
  appId: import.meta.env.FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

type RoleAlertSettings = {
  shopId: string;
  roleId: number;
  disableAlertUntilDate: Date | null;
};

const roleAlertSettingsRef = (shopId: string) =>
  collection(firestore, `shops/${shopId}/roleAlertSettings`);

export const subscribeRoleAlertSettings = ({
  shopId,
  onSubscribe,
}: {
  shopId: string;
  onSubscribe: (data: RoleAlertSettings[]) => void;
}): (() => void) => {
  const ref = roleAlertSettingsRef(shopId);

  return onSnapshot(ref, (snapshot) =>
    onSubscribe(snapshot.docs.map((doc) => doc.data() as RoleAlertSettings)),
  );
};

export const setRoleAlertSettings = async ({
  shopId,
  roleId,
  disableAlertUntilDate,
}: {
  shopId: string;
  roleId: number;
  disableAlertUntilDate: Date | null;
}): Promise<void> =>
  setDoc(
    doc(roleAlertSettingsRef(shopId), String(roleId)),
    { shopId, roleId, disableAlertUntilDate },
    { merge: true },
  );

export const markRecordAsDispatched = (path: string[], field: "deactivatedAt" | "dispatchedAt") =>
  updateDoc(doc(firestore, path.join("/")), { [field]: new Date().toISOString() });
