import React, { useCallback, useMemo, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Button, DatePicker, message } from "antd";
import dayjs from "dayjs";
import { uniq } from "lodash";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { UpdateFilterConditionFunctionType, useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import { FilterConditions, TableUserFilter } from "pages/TableUserStatus/TableUserFilter";
import { TableUserStatistics } from "pages/TableUserStatus/TableUserStatistics";
import { TableUser } from "pages/TableUserStatus/types";

import {
  useTableUserStatusDeactivateTableUserMutation,
  useTableUserStatusGetTablesQuery,
  useTableUserStatusGetTableUsersQuery,
} from "./queries";
import { TableUserTable } from "./TableUserTable";

const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

const getStateForFilterCondition = (
  tableUser: TableUser,
): NonNullable<FilterConditions["status"]>[number] => {
  if (tableUser.state === "active" || tableUser.state === "inactive") return tableUser.state;
  return "checking";
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
`;

export const filterTableUsers = (
  tableUsers: TableUser[],
  { tableName, status }: FilterConditions,
) =>
  tableUsers.filter(
    (tableUser) =>
      (tableName === undefined || tableUser.table.name === tableName) &&
      (status === undefined || status.includes(getStateForFilterCondition(tableUser))),
  );

export const TableUserStatus = () => {
  const { getSearchParam, getSearchParamAll, setSearchParam } = useSearchParams<
    "tableName" | "status" | "shopId" | "fromDate" | "untilDate"
  >();
  const shopId = getSearchParam("shopId");
  const status = getSearchParamAll<"active" | "checking" | "inactive">("status");
  const tableName = getSearchParam("tableName");

  const untilDate = dayjs(getSearchParam("untilDate") || new Date()).endOf("day");
  const fromDate = dayjs(getSearchParam("fromDate") || untilDate.subtract(1, "month")).startOf(
    "day",
  );

  const [selectableDateRange, setSelectableDateRange] = useState<[string, string] | null>([
    fromDate.format(DATETIME_FORMAT),
    untilDate.format(DATETIME_FORMAT),
  ]);

  const {
    data: getTableUsersData,
    loading: loadingTableUsers,
    refetch: refetchTableUsers,
  } = useTableUserStatusGetTableUsersQuery(
    shopId
      ? {
          variables: {
            shopId,
            from: fromDate.format(DATETIME_FORMAT),
            until: untilDate.format(DATETIME_FORMAT),
          },
        }
      : { skip: true },
  );
  const tableUsers = useMemo(() => getTableUsersData?.tableUser ?? [], [getTableUsersData]);

  const { data: getTablesData, loading: loadingTables } = useTableUserStatusGetTablesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const tables = getTablesData?.table ?? [];

  const [deactivateTableUserMutation, { loading: deactivatingTableUser }] =
    useTableUserStatusDeactivateTableUserMutation();

  const deactivateTableUser = useCallback(
    async ({ shopId, tableUserId }: { shopId: string; tableUserId: string }) => {
      const { errors } = await deactivateTableUserMutation({
        variables: { input: { tableUserId, shopId } },
      });
      if (errors) {
        message.error(errors[0]?.message ?? "不具合が発生しました");
      } else {
        await refetchTableUsers();
      }
    },
    [deactivateTableUserMutation, refetchTableUsers],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, setFilterConditions } =
    useFilterConditions<FilterConditions>({
      tableName,
      status: status.length > 0 ? status : undefined,
    });

  const filteredTableUsers = useMemo(
    () => filterTableUsers(tableUsers, filterConditions),
    [tableUsers, filterConditions],
  );

  const tableNames = useMemo(
    () =>
      uniq(
        tableUsers
          .map(({ table }) => table.name)
          .sort((a, b) =>
            Number.isNaN(Number(a)) || Number.isNaN(Number(b))
              ? a.localeCompare(b)
              : Number(a) - Number(b),
          ),
      ),
    [tableUsers],
  );

  const [{ loading: isDeactivating }, deactivateAll] = useAsyncFn(async () => {
    if (!shopId) return;

    const allErrors = await Promise.all(
      tableUsers.map(async ({ id }) => {
        const { errors = [] } = await deactivateTableUserMutation({
          variables: { input: { tableUserId: id, shopId } },
        });
        return errors;
      }),
    );
    const errors = allErrors.flat();

    if (errors.length > 0) {
      message.error(errors[0]?.message ?? "不具合が発生しました");
    } else {
      await refetchTableUsers();
    }
  }, [deactivateTableUserMutation, refetchTableUsers, shopId, tableUsers]);

  const handleUpdateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions> =
    useCallback(
      (filter) => {
        updateFilterCondition(filter);
        if ("tableName" in filter) setSearchParam("tableName", filter.tableName);
        if ("status" in filter) setSearchParam("status", filter.status);
      },
      [updateFilterCondition, setSearchParam],
    );

  const handleClearFilterCondition = useCallback(() => {
    // NOTE: useFilterConditionsの初期値にクエリパラメーターを使用しており、clearFilterConditionsだと初期値をセットされてしまいクリアにならない場合がある
    setFilterConditions({});
    setSearchParam("status", null);
    setSearchParam("tableName", null);
  }, [setFilterConditions, setSearchParam]);

  const loading = loadingTableUsers || loadingTables || deactivatingTableUser;

  return (
    <MainLayout title="テーブル利用状況">
      <ShopSelector />

      <Spacer size={16} />

      <DatePicker.RangePicker
        onCalendarChange={(_, [fromDate, untilDate]) => {
          if (!fromDate && untilDate) {
            setSelectableDateRange([
              dayjs(untilDate).subtract(1, "month").startOf("day").format("YYYY-MM-DD HH:mm"),
              untilDate,
            ]);
            return;
          }
          if (fromDate && !untilDate) {
            setSelectableDateRange([
              fromDate,
              dayjs(fromDate).add(1, "month").endOf("day").format("YYYY-MM-DD HH:mm"),
            ]);
            return;
          }
          if (fromDate && untilDate) {
            setSelectableDateRange([
              dayjs(untilDate).subtract(1, "month").startOf("day").format("YYYY-MM-DD HH:mm"),
              dayjs(fromDate).add(1, "month").endOf("day").format("YYYY-MM-DD HH:mm"),
            ]);
            return;
          }
          setSelectableDateRange(null);
        }}
        onChange={(_, [fromDate, untilDate]) => {
          setSearchParam("fromDate", fromDate);
          setSearchParam("untilDate", untilDate);
        }}
        disabledDate={(current: dayjs.Dayjs) =>
          Boolean(
            selectableDateRange &&
              (dayjs(selectableDateRange[1]).isBefore(current) ||
                dayjs(selectableDateRange[0]).isAfter(current)),
          )
        }
        defaultValue={[fromDate, untilDate]}
      />
      <Spacer size={16} />

      <TableUserStatistics tableUsers={tableUsers} tables={tables} />

      <TableUserFilter
        tableNames={tableNames}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={handleUpdateFilterCondition}
        clearFilterConditions={handleClearFilterCondition}
      />

      {import.meta.env.APP_ENV !== "production" && shopId && (
        <Container>
          <Button loading={isDeactivating} onClick={deactivateAll}>
            すべて削除
          </Button>
        </Container>
      )}

      <TableUserTable
        loading={loading}
        tableUsers={filteredTableUsers}
        deactivateTableUser={deactivateTableUser}
      />
    </MainLayout>
  );
};
