import { useCallback } from "react";
import { Form } from "antd";

import { PrinterAddress } from "libs/createEpsonWebConfigUpdateScript/types";

const useForm = Form.useForm;

type FormValues = {
  ipAddress: string | undefined;
  serialNumber: string | undefined;
};

const getInitialValues = (): FormValues => ({
  ipAddress: undefined,
  serialNumber: undefined,
});

export const usePrinterAddressForm = ({
  onSubmit,
}: {
  onSubmit: (next: PrinterAddress | null) => void;
}) => {
  const [form] = useForm<FormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const { ipAddress, serialNumber } = form.getFieldsValue();
    onSubmit(ipAddress && serialNumber ? { ipAddress, serialNumber } : null);
  }, [form, onSubmit]);

  return { form, submit, initialValues };
};
