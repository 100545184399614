import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SignInSignIn = gql`
    mutation SignInSignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) @client
}
    `;
export type SignInSignInMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type SignInSignInMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'signIn'>
);


export const SignInSignInDocument = gql`
    mutation SignInSignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) @client
}
    `;
export type SignInSignInMutationFn = Apollo.MutationFunction<SignInSignInMutation, SignInSignInMutationVariables>;

/**
 * __useSignInSignInMutation__
 *
 * To run a mutation, you first call `useSignInSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInSignInMutation, { data, loading, error }] = useSignInSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInSignInMutation, SignInSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInSignInMutation, SignInSignInMutationVariables>(SignInSignInDocument, options);
      }
export type SignInSignInMutationHookResult = ReturnType<typeof useSignInSignInMutation>;
export type SignInSignInMutationResult = Apollo.MutationResult<SignInSignInMutation>;
export type SignInSignInMutationOptions = Apollo.BaseMutationOptions<SignInSignInMutation, SignInSignInMutationVariables>;