import React, { memo } from "react";
import styled from "styled-components";
import { Button, Tag } from "antd";
import { ShopClient } from "entity/shopClient.entity";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

const isAvailable = (shopClient: ShopClient): boolean => {
  try {
    return Object.hasOwnProperty.call(shopClient.customClaims, "shopId");
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Code = styled.div`
  font-size: 10px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
`;

type Props = {
  loading: boolean;
  shopClients: ShopClient[];
  setSelectedShopClient: (shopClient: ShopClient) => void;
  setRemoveShopClient: (shopClient: ShopClient) => void;
};

export const ShopClientTable = memo<Props>(
  ({ loading, shopClients, setSelectedShopClient, setRemoveShopClient }) => {
    const [pagination, setPagination] = usePagination();

    const columns = [
      { title: "Shop ID", dataIndex: "shopId" },
      { title: "Shop Client ID", dataIndex: "shopClientId" },
      { title: "Shop Client Name", dataIndex: "name" },
      { title: "FirebaseAuth Email", dataIndex: "email" },
      {
        title: "Roles",
        render(_: unknown, { roles }: ShopClient) {
          return roles.map((role) => <Tag key={role}>{role}</Tag>);
        },
      },
      {
        title: "Is Available",
        render(_: unknown, shopClient: ShopClient) {
          return isAvailable(shopClient) ? (
            <Tag color="success">使用可能</Tag>
          ) : (
            <Tag color="error">使用不可能</Tag>
          );
        },
      },
      {
        title: "Custom Claims",
        render(_: unknown, shopClient: ShopClient) {
          return (
            <Code>
              <pre>
                <code>{JSON.stringify(shopClient.customClaims, null, 2)}</code>
              </pre>
            </Code>
          );
        },
      },
      {
        title: "",
        align: "center",
        fixed: "right",
        width: 60,
        render(_: string, shopClient: ShopClient) {
          return (
            <>
              <Button onClick={() => setSelectedShopClient(shopClient)}>Set Custom Claims</Button>
              <Button onClick={() => setRemoveShopClient(shopClient)}>Delete Shop Client</Button>
            </>
          );
        },
      } as const,
    ];

    return (
      <Table
        rowKey="shopClientId"
        columns={columns}
        dataSource={shopClients}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
