import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TableUserDetailGetTableUser = gql`
    query TableUserDetailGetTableUser($tableUserId: uuid!) {
  tableUser_by_pk(id: $tableUserId) {
    activatedAt
    billableTableUserId
    companyId
    deactivatedAt
    id
    numPeople
    shopId
    state
    isTakeOut
    table {
      tableId
      name
    }
    tableClearReason
    taxIncludedPaidAmount
  }
  childrenTableUsers: tableUser(where: {billableTableUserId: {_eq: $tableUserId}}) {
    id
  }
}
    `;
export const TableUserDetailGetMenuOrderItemEvents = gql`
    query TableUserDetailGetMenuOrderItemEvents($tableUserId: uuid!) {
  menuOrderItemCreatedEvent(
    where: {menuOrderItem: {order: {tableUser: {id: {_eq: $tableUserId}}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        orderedUserId
        orderedClerkName
        tableUser {
          id
          table {
            tableId
            name
          }
        }
        tableUserByOrderedtableuserid {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    createdAt
  }
  menuOrderItemQuantityIncreasedEvent(
    where: {menuOrderItem: {order: {tableUser: {id: {_eq: $tableUserId}}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
        tableUserByOrderedtableuserid {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
  menuOrderItemQuantityDecreasedEvent(
    where: {menuOrderItem: {order: {tableUser: {id: {_eq: $tableUserId}}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
        tableUserByOrderedtableuserid {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
}
    `;
export const TableUserDetailGetTableUserMovedEvents = gql`
    query TableUserDetailGetTableUserMovedEvents($tableUserId: uuid!) {
  tableUserMovedEvent(where: {tableUser: {id: {_eq: $tableUserId}}}) {
    id
    movedAt
    tableUser {
      id
      activatedAt
      numPeople
    }
    fromTable {
      name
    }
    toTable {
      name
    }
  }
}
    `;
export const TableUserDetailGetCheckInEvents = gql`
    query TableUserDetailGetCheckInEvents($tableUserId: uuid!) {
  checkInEvent(where: {tableUser: {id: {_eq: $tableUserId}}}) {
    id
    checkedInAt
    customer {
      id
      checkInEvents(order_by: {checkedInAt: desc}, limit: 10) {
        id
        checkedInAt
        shop {
          shopId
          name
        }
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
    }
  }
}
    `;
export const TableUserDetailGetOnSitePayment = gql`
    query TableUserDetailGetOnSitePayment($tableUserId: uuid!) {
  onSitePayment(
    where: {onSitePaymentTableUsers: {tableUserId: {_eq: $tableUserId}}}
  ) {
    createdAt
    localAccountingSlipId
    onSitePaymentId
    payingActivePlanChoices {
      itemSales {
        billingAmount
        taxAmount
      }
    }
    payingActivePlanOpenPriceMetas {
      itemSales {
        billingAmount
        taxAmount
      }
    }
    payingMenuOrderItems {
      itemSales {
        billingAmount
        taxAmount
      }
    }
  }
}
    `;
export const TableUserDetailGetActivePlan = gql`
    query TableUserDetailGetActivePlan($tableUserId: uuid!) {
  activePlan(
    where: {tableUser: {id: {_eq: $tableUserId}}}
    order_by: {startAt: desc}
  ) {
    id
    name
    startAt
    endAt
    activePlanOptions {
      name
      activePlanOptionId
      activePlanChoices {
        name
        quantity
        activePlanChoiceId
      }
    }
  }
}
    `;
export const TableUserDetailGetTableUserCustomer = gql`
    query TableUserDetailGetTableUserCustomer($tableUserId: uuid!) {
  tableUserCustomer(where: {tableUserId: {_eq: $tableUserId}}) {
    customerId
    tableUser {
      activatedAt
    }
  }
}
    `;
export type TableUserDetailGetTableUserQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetTableUserQuery = (
  { __typename?: 'query_root' }
  & { tableUser_by_pk?: Types.Maybe<(
    { __typename?: 'tableUser' }
    & Pick<Types.TableUser, 'activatedAt' | 'billableTableUserId' | 'companyId' | 'deactivatedAt' | 'id' | 'numPeople' | 'shopId' | 'state' | 'isTakeOut' | 'tableClearReason' | 'taxIncludedPaidAmount'>
    & { table: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'tableId' | 'name'>
    ) }
  )>, childrenTableUsers: Array<(
    { __typename?: 'tableUser' }
    & Pick<Types.TableUser, 'id'>
  )> }
);

export type TableUserDetailGetMenuOrderItemEventsQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetMenuOrderItemEventsQuery = (
  { __typename?: 'query_root' }
  & { menuOrderItemCreatedEvent: Array<(
    { __typename?: 'menuOrderItemCreatedEvent' }
    & Pick<Types.MenuOrderItemCreatedEvent, 'id' | 'createdAt'>
    & { menuOrderItem: (
      { __typename?: 'menuOrderItem' }
      & Pick<Types.MenuOrderItem, 'id' | 'name' | 'quantity'>
      & { order: (
        { __typename?: 'order' }
        & Pick<Types.Order, 'id' | 'orderedUserId' | 'orderedClerkName'>
        & { tableUser: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ), tableUserByOrderedtableuserid: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ) }
      ) }
    ) }
  )>, menuOrderItemQuantityIncreasedEvent: Array<(
    { __typename?: 'menuOrderItemQuantityIncreasedEvent' }
    & Pick<Types.MenuOrderItemQuantityIncreasedEvent, 'id' | 'quantity' | 'diffQuantity' | 'createdAt' | 'updatedClerkName'>
    & { menuOrderItem: (
      { __typename?: 'menuOrderItem' }
      & Pick<Types.MenuOrderItem, 'id' | 'name' | 'quantity'>
      & { order: (
        { __typename?: 'order' }
        & Pick<Types.Order, 'id'>
        & { tableUser: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ), tableUserByOrderedtableuserid: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ) }
      ) }
    ) }
  )>, menuOrderItemQuantityDecreasedEvent: Array<(
    { __typename?: 'menuOrderItemQuantityDecreasedEvent' }
    & Pick<Types.MenuOrderItemQuantityDecreasedEvent, 'id' | 'quantity' | 'diffQuantity' | 'createdAt' | 'updatedClerkName'>
    & { menuOrderItem: (
      { __typename?: 'menuOrderItem' }
      & Pick<Types.MenuOrderItem, 'id' | 'name' | 'quantity'>
      & { order: (
        { __typename?: 'order' }
        & Pick<Types.Order, 'id'>
        & { tableUser: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ), tableUserByOrderedtableuserid: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ) }
      ) }
    ) }
  )> }
);

export type TableUserDetailGetTableUserMovedEventsQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetTableUserMovedEventsQuery = (
  { __typename?: 'query_root' }
  & { tableUserMovedEvent: Array<(
    { __typename?: 'tableUserMovedEvent' }
    & Pick<Types.TableUserMovedEvent, 'id' | 'movedAt'>
    & { tableUser: (
      { __typename?: 'tableUser' }
      & Pick<Types.TableUser, 'id' | 'activatedAt' | 'numPeople'>
    ), fromTable: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'name'>
    ), toTable: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'name'>
    ) }
  )> }
);

export type TableUserDetailGetCheckInEventsQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetCheckInEventsQuery = (
  { __typename?: 'query_root' }
  & { checkInEvent: Array<(
    { __typename?: 'checkInEvent' }
    & Pick<Types.CheckInEvent, 'id' | 'checkedInAt'>
    & { customer: (
      { __typename?: 'customer' }
      & Pick<Types.Customer, 'id'>
      & { checkInEvents: Array<(
        { __typename?: 'checkInEvent' }
        & Pick<Types.CheckInEvent, 'id' | 'checkedInAt'>
        & { shop: (
          { __typename?: 'shop' }
          & Pick<Types.Shop, 'shopId' | 'name'>
        ), tableUser?: Types.Maybe<(
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        )> }
      )> }
    ) }
  )> }
);

export type TableUserDetailGetOnSitePaymentQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetOnSitePaymentQuery = (
  { __typename?: 'query_root' }
  & { onSitePayment: Array<(
    { __typename?: 'onSitePayment' }
    & Pick<Types.OnSitePayment, 'createdAt' | 'localAccountingSlipId' | 'onSitePaymentId'>
    & { payingActivePlanChoices: Array<(
      { __typename?: 'payingActivePlanChoice' }
      & { itemSales?: Types.Maybe<(
        { __typename?: 'itemSales' }
        & Pick<Types.ItemSales, 'billingAmount' | 'taxAmount'>
      )> }
    )>, payingActivePlanOpenPriceMetas: Array<(
      { __typename?: 'payingActivePlanOpenPriceMeta' }
      & { itemSales?: Types.Maybe<(
        { __typename?: 'itemSales' }
        & Pick<Types.ItemSales, 'billingAmount' | 'taxAmount'>
      )> }
    )>, payingMenuOrderItems: Array<(
      { __typename?: 'payingMenuOrderItem' }
      & { itemSales?: Types.Maybe<(
        { __typename?: 'itemSales' }
        & Pick<Types.ItemSales, 'billingAmount' | 'taxAmount'>
      )> }
    )> }
  )> }
);

export type TableUserDetailGetActivePlanQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetActivePlanQuery = (
  { __typename?: 'query_root' }
  & { activePlan: Array<(
    { __typename?: 'activePlan' }
    & Pick<Types.ActivePlan, 'id' | 'name' | 'startAt' | 'endAt'>
    & { activePlanOptions: Array<(
      { __typename?: 'activePlanOption' }
      & Pick<Types.ActivePlanOption, 'name' | 'activePlanOptionId'>
      & { activePlanChoices: Array<(
        { __typename?: 'activePlanChoice' }
        & Pick<Types.ActivePlanChoice, 'name' | 'quantity' | 'activePlanChoiceId'>
      )> }
    )> }
  )> }
);

export type TableUserDetailGetTableUserCustomerQueryVariables = Types.Exact<{
  tableUserId: Types.Scalars['uuid'];
}>;


export type TableUserDetailGetTableUserCustomerQuery = (
  { __typename?: 'query_root' }
  & { tableUserCustomer: Array<(
    { __typename?: 'tableUserCustomer' }
    & Pick<Types.TableUserCustomer, 'customerId'>
    & { tableUser: (
      { __typename?: 'tableUser' }
      & Pick<Types.TableUser, 'activatedAt'>
    ) }
  )> }
);


export const TableUserDetailGetTableUserDocument = gql`
    query TableUserDetailGetTableUser($tableUserId: uuid!) {
  tableUser_by_pk(id: $tableUserId) {
    activatedAt
    billableTableUserId
    companyId
    deactivatedAt
    id
    numPeople
    shopId
    state
    isTakeOut
    table {
      tableId
      name
    }
    tableClearReason
    taxIncludedPaidAmount
  }
  childrenTableUsers: tableUser(where: {billableTableUserId: {_eq: $tableUserId}}) {
    id
  }
}
    `;

/**
 * __useTableUserDetailGetTableUserQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetTableUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetTableUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetTableUserQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetTableUserQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetTableUserQuery, TableUserDetailGetTableUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetTableUserQuery, TableUserDetailGetTableUserQueryVariables>(TableUserDetailGetTableUserDocument, options);
      }
export function useTableUserDetailGetTableUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetTableUserQuery, TableUserDetailGetTableUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetTableUserQuery, TableUserDetailGetTableUserQueryVariables>(TableUserDetailGetTableUserDocument, options);
        }
export type TableUserDetailGetTableUserQueryHookResult = ReturnType<typeof useTableUserDetailGetTableUserQuery>;
export type TableUserDetailGetTableUserLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetTableUserLazyQuery>;
export type TableUserDetailGetTableUserQueryResult = Apollo.QueryResult<TableUserDetailGetTableUserQuery, TableUserDetailGetTableUserQueryVariables>;
export const TableUserDetailGetMenuOrderItemEventsDocument = gql`
    query TableUserDetailGetMenuOrderItemEvents($tableUserId: uuid!) {
  menuOrderItemCreatedEvent(
    where: {menuOrderItem: {order: {tableUser: {id: {_eq: $tableUserId}}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        orderedUserId
        orderedClerkName
        tableUser {
          id
          table {
            tableId
            name
          }
        }
        tableUserByOrderedtableuserid {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    createdAt
  }
  menuOrderItemQuantityIncreasedEvent(
    where: {menuOrderItem: {order: {tableUser: {id: {_eq: $tableUserId}}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
        tableUserByOrderedtableuserid {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
  menuOrderItemQuantityDecreasedEvent(
    where: {menuOrderItem: {order: {tableUser: {id: {_eq: $tableUserId}}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
        tableUserByOrderedtableuserid {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
}
    `;

/**
 * __useTableUserDetailGetMenuOrderItemEventsQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetMenuOrderItemEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetMenuOrderItemEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetMenuOrderItemEventsQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetMenuOrderItemEventsQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetMenuOrderItemEventsQuery, TableUserDetailGetMenuOrderItemEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetMenuOrderItemEventsQuery, TableUserDetailGetMenuOrderItemEventsQueryVariables>(TableUserDetailGetMenuOrderItemEventsDocument, options);
      }
export function useTableUserDetailGetMenuOrderItemEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetMenuOrderItemEventsQuery, TableUserDetailGetMenuOrderItemEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetMenuOrderItemEventsQuery, TableUserDetailGetMenuOrderItemEventsQueryVariables>(TableUserDetailGetMenuOrderItemEventsDocument, options);
        }
export type TableUserDetailGetMenuOrderItemEventsQueryHookResult = ReturnType<typeof useTableUserDetailGetMenuOrderItemEventsQuery>;
export type TableUserDetailGetMenuOrderItemEventsLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetMenuOrderItemEventsLazyQuery>;
export type TableUserDetailGetMenuOrderItemEventsQueryResult = Apollo.QueryResult<TableUserDetailGetMenuOrderItemEventsQuery, TableUserDetailGetMenuOrderItemEventsQueryVariables>;
export const TableUserDetailGetTableUserMovedEventsDocument = gql`
    query TableUserDetailGetTableUserMovedEvents($tableUserId: uuid!) {
  tableUserMovedEvent(where: {tableUser: {id: {_eq: $tableUserId}}}) {
    id
    movedAt
    tableUser {
      id
      activatedAt
      numPeople
    }
    fromTable {
      name
    }
    toTable {
      name
    }
  }
}
    `;

/**
 * __useTableUserDetailGetTableUserMovedEventsQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetTableUserMovedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetTableUserMovedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetTableUserMovedEventsQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetTableUserMovedEventsQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetTableUserMovedEventsQuery, TableUserDetailGetTableUserMovedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetTableUserMovedEventsQuery, TableUserDetailGetTableUserMovedEventsQueryVariables>(TableUserDetailGetTableUserMovedEventsDocument, options);
      }
export function useTableUserDetailGetTableUserMovedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetTableUserMovedEventsQuery, TableUserDetailGetTableUserMovedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetTableUserMovedEventsQuery, TableUserDetailGetTableUserMovedEventsQueryVariables>(TableUserDetailGetTableUserMovedEventsDocument, options);
        }
export type TableUserDetailGetTableUserMovedEventsQueryHookResult = ReturnType<typeof useTableUserDetailGetTableUserMovedEventsQuery>;
export type TableUserDetailGetTableUserMovedEventsLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetTableUserMovedEventsLazyQuery>;
export type TableUserDetailGetTableUserMovedEventsQueryResult = Apollo.QueryResult<TableUserDetailGetTableUserMovedEventsQuery, TableUserDetailGetTableUserMovedEventsQueryVariables>;
export const TableUserDetailGetCheckInEventsDocument = gql`
    query TableUserDetailGetCheckInEvents($tableUserId: uuid!) {
  checkInEvent(where: {tableUser: {id: {_eq: $tableUserId}}}) {
    id
    checkedInAt
    customer {
      id
      checkInEvents(order_by: {checkedInAt: desc}, limit: 10) {
        id
        checkedInAt
        shop {
          shopId
          name
        }
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTableUserDetailGetCheckInEventsQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetCheckInEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetCheckInEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetCheckInEventsQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetCheckInEventsQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetCheckInEventsQuery, TableUserDetailGetCheckInEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetCheckInEventsQuery, TableUserDetailGetCheckInEventsQueryVariables>(TableUserDetailGetCheckInEventsDocument, options);
      }
export function useTableUserDetailGetCheckInEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetCheckInEventsQuery, TableUserDetailGetCheckInEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetCheckInEventsQuery, TableUserDetailGetCheckInEventsQueryVariables>(TableUserDetailGetCheckInEventsDocument, options);
        }
export type TableUserDetailGetCheckInEventsQueryHookResult = ReturnType<typeof useTableUserDetailGetCheckInEventsQuery>;
export type TableUserDetailGetCheckInEventsLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetCheckInEventsLazyQuery>;
export type TableUserDetailGetCheckInEventsQueryResult = Apollo.QueryResult<TableUserDetailGetCheckInEventsQuery, TableUserDetailGetCheckInEventsQueryVariables>;
export const TableUserDetailGetOnSitePaymentDocument = gql`
    query TableUserDetailGetOnSitePayment($tableUserId: uuid!) {
  onSitePayment(
    where: {onSitePaymentTableUsers: {tableUserId: {_eq: $tableUserId}}}
  ) {
    createdAt
    localAccountingSlipId
    onSitePaymentId
    payingActivePlanChoices {
      itemSales {
        billingAmount
        taxAmount
      }
    }
    payingActivePlanOpenPriceMetas {
      itemSales {
        billingAmount
        taxAmount
      }
    }
    payingMenuOrderItems {
      itemSales {
        billingAmount
        taxAmount
      }
    }
  }
}
    `;

/**
 * __useTableUserDetailGetOnSitePaymentQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetOnSitePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetOnSitePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetOnSitePaymentQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetOnSitePaymentQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetOnSitePaymentQuery, TableUserDetailGetOnSitePaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetOnSitePaymentQuery, TableUserDetailGetOnSitePaymentQueryVariables>(TableUserDetailGetOnSitePaymentDocument, options);
      }
export function useTableUserDetailGetOnSitePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetOnSitePaymentQuery, TableUserDetailGetOnSitePaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetOnSitePaymentQuery, TableUserDetailGetOnSitePaymentQueryVariables>(TableUserDetailGetOnSitePaymentDocument, options);
        }
export type TableUserDetailGetOnSitePaymentQueryHookResult = ReturnType<typeof useTableUserDetailGetOnSitePaymentQuery>;
export type TableUserDetailGetOnSitePaymentLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetOnSitePaymentLazyQuery>;
export type TableUserDetailGetOnSitePaymentQueryResult = Apollo.QueryResult<TableUserDetailGetOnSitePaymentQuery, TableUserDetailGetOnSitePaymentQueryVariables>;
export const TableUserDetailGetActivePlanDocument = gql`
    query TableUserDetailGetActivePlan($tableUserId: uuid!) {
  activePlan(
    where: {tableUser: {id: {_eq: $tableUserId}}}
    order_by: {startAt: desc}
  ) {
    id
    name
    startAt
    endAt
    activePlanOptions {
      name
      activePlanOptionId
      activePlanChoices {
        name
        quantity
        activePlanChoiceId
      }
    }
  }
}
    `;

/**
 * __useTableUserDetailGetActivePlanQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetActivePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetActivePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetActivePlanQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetActivePlanQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetActivePlanQuery, TableUserDetailGetActivePlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetActivePlanQuery, TableUserDetailGetActivePlanQueryVariables>(TableUserDetailGetActivePlanDocument, options);
      }
export function useTableUserDetailGetActivePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetActivePlanQuery, TableUserDetailGetActivePlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetActivePlanQuery, TableUserDetailGetActivePlanQueryVariables>(TableUserDetailGetActivePlanDocument, options);
        }
export type TableUserDetailGetActivePlanQueryHookResult = ReturnType<typeof useTableUserDetailGetActivePlanQuery>;
export type TableUserDetailGetActivePlanLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetActivePlanLazyQuery>;
export type TableUserDetailGetActivePlanQueryResult = Apollo.QueryResult<TableUserDetailGetActivePlanQuery, TableUserDetailGetActivePlanQueryVariables>;
export const TableUserDetailGetTableUserCustomerDocument = gql`
    query TableUserDetailGetTableUserCustomer($tableUserId: uuid!) {
  tableUserCustomer(where: {tableUserId: {_eq: $tableUserId}}) {
    customerId
    tableUser {
      activatedAt
    }
  }
}
    `;

/**
 * __useTableUserDetailGetTableUserCustomerQuery__
 *
 * To run a query within a React component, call `useTableUserDetailGetTableUserCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserDetailGetTableUserCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserDetailGetTableUserCustomerQuery({
 *   variables: {
 *      tableUserId: // value for 'tableUserId'
 *   },
 * });
 */
export function useTableUserDetailGetTableUserCustomerQuery(baseOptions: Apollo.QueryHookOptions<TableUserDetailGetTableUserCustomerQuery, TableUserDetailGetTableUserCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserDetailGetTableUserCustomerQuery, TableUserDetailGetTableUserCustomerQueryVariables>(TableUserDetailGetTableUserCustomerDocument, options);
      }
export function useTableUserDetailGetTableUserCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserDetailGetTableUserCustomerQuery, TableUserDetailGetTableUserCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserDetailGetTableUserCustomerQuery, TableUserDetailGetTableUserCustomerQueryVariables>(TableUserDetailGetTableUserCustomerDocument, options);
        }
export type TableUserDetailGetTableUserCustomerQueryHookResult = ReturnType<typeof useTableUserDetailGetTableUserCustomerQuery>;
export type TableUserDetailGetTableUserCustomerLazyQueryHookResult = ReturnType<typeof useTableUserDetailGetTableUserCustomerLazyQuery>;
export type TableUserDetailGetTableUserCustomerQueryResult = Apollo.QueryResult<TableUserDetailGetTableUserCustomerQuery, TableUserDetailGetTableUserCustomerQueryVariables>;