import React, { memo, useMemo } from "react";
import { Switch, TimePicker } from "antd";
import dayjs from "dayjs";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { ShopSetInput } from "types/graphql";

import { ShopStatus } from "../types";

type Props = {
  loading?: boolean;
  shopStatuses: ShopStatus[];
  updateShop(shop: { shopId: string } & ShopSetInput): void;
};

const format = "HH:mm";

export const ShopStatusTable = memo<Props>(({ loading, shopStatuses, updateShop }) => {
  const [pagination, setPagination] = usePagination();

  const columns = useMemo(
    () => [
      {
        title: "業態 ID",
        width: 100,
        dataIndex: "companyId",
      },
      {
        title: "店舗 ID",
        width: 100,
        dataIndex: "shopId",
      },
      {
        title: "店舗名",
        width: 200,
        dataIndex: "name",
      },
      {
        title: "営業日変更時刻",
        render(_: string, { changeDateTime, shopId }: ShopStatus) {
          return (
            <TimePicker
              onChange={(value) => updateShop({ shopId, changeDateTime: value?.format("HH:mm") })}
              allowClear={false}
              value={dayjs(changeDateTime, format)}
              format={format}
            />
          );
        },
      },
      {
        title: "PC 切断時刻",
        render(_: string, { shopId, shopCloseConnectionTime }: ShopStatus) {
          return (
            <TimePicker
              allowClear={false}
              value={dayjs(shopCloseConnectionTime, format)}
              format={format}
              onChange={(value) =>
                updateShop({ shopId, shopCloseConnectionTime: value?.format("HH:mm") })
              }
            />
          );
        },
      },
      {
        title: "PC 再起動時刻",
        render(_: string, { shopId, shopRebootTime }: ShopStatus) {
          return (
            <TimePicker
              allowClear={false}
              value={dayjs(shopRebootTime, format, false)}
              format={format}
              onChange={(value) => updateShop({ shopId, shopRebootTime: value?.format("HH:mm") })}
            />
          );
        },
      },
      {
        title: "開店時刻",
        render(_: string, { shopId, open }: ShopStatus) {
          return (
            <TimePicker
              allowClear={false}
              value={dayjs(open, format)}
              format={format}
              onChange={(value) => updateShop({ shopId, open: value?.format("HH:mm") })}
            />
          );
        },
      },
      {
        title: "閉店時刻",
        render(_: string, { shopId, close }: ShopStatus) {
          const rawHour = Number(close.split(":")?.[0]);
          const hour = rawHour > 23 ? rawHour - 24 : rawHour;
          return (
            <TimePicker
              allowClear={false}
              value={dayjs(`${hour}:${close.split(":")?.[1] ?? "00"}`, format, false)}
              format={format}
              onChange={(value) => {
                const rawHour = value?.hour() ?? 0;
                const hour = rawHour < 13 ? rawHour + 24 : rawHour;
                updateShop({ shopId, close: `${hour}:${value?.format("mm")}` });
              }}
            />
          );
        },
      },
      {
        title: "アラート",
        dataIndex: "enableAlert",
        render(_: string, { enableAlert, shopId }: ShopStatus) {
          return (
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={enableAlert}
              onChange={(checked) => {
                updateShop({ shopId, enableAlert: checked });
              }}
            />
          );
        },
      },
      {
        title: "最終レジ精算時刻",
        render(_: string, { closeCashRegisters }: ShopStatus) {
          return closeCashRegisters[0]
            ? dayjs(closeCashRegisters[0].createdAt).format("YYYY/MM/DD HH:mm")
            : "なし";
        },
        defaultSortOrder: "descend" as const,
        sorter: (a: ShopStatus, b: ShopStatus) =>
          dayjs(a.closeCashRegisters[0]?.createdAt ?? 0).diff(
            dayjs(b.closeCashRegisters[0]?.createdAt ?? 0),
          ),
      },
    ],
    [updateShop],
  );

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={shopStatuses}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
