import { useCallback, useEffect, useRef, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";

import { firestore } from "libs/firebase";

export type CashRegisterSettings = {
  printerConnectionName?: string;
  printerConnectionTarget?: string;
  cashChangerConnectionTarget?: string;
  drawerPassword?: string;
  disableEnhancedOfflineSupport?: boolean;
  disableStandaloneOperationSupport?: boolean;
};

export const useTabletDeviceRemoteManagement = ({ shopClientIds }: { shopClientIds: string[] }) => {
  const [cashRegisterSettings, setCashRegisterSettings] = useState<
    Record<string, CashRegisterSettings>
  >({});
  const loadingRef = useRef<Record<string, boolean>>({});

  const fetchCashRegisterRemoteManagement = useCallback(async (shopClientId: string) => {
    if (loadingRef.current[shopClientId]) return;
    loadingRef.current[shopClientId] = true;
    // TODO: Rename to cashRegisterRemoteManagement
    const record = await getDoc(
      doc(collection(firestore, "tabletDeviceRemoteManagement"), shopClientId),
    );

    if (record.exists()) {
      const data = record.data();
      if (data) {
        setCashRegisterSettings((settings) => ({ ...settings, [shopClientId]: data }));
      }
    }
    loadingRef.current[shopClientId] = false;
  }, []);

  useEffect(() => {
    shopClientIds.forEach((shopClientId) => fetchCashRegisterRemoteManagement(shopClientId));
    setCashRegisterSettings((settings) =>
      Object.fromEntries(
        Object.entries(settings).filter(([shopClientId]) => shopClientIds.includes(shopClientId)),
      ),
    );
  }, [fetchCashRegisterRemoteManagement, shopClientIds]);

  return { cashRegisterSettings, fetchCashRegisterRemoteManagement };
};
