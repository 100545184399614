import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { InputNumber, Typography } from "antd";
import { zeroIfNaN } from "dinii-self-js-lib/number";

import { Spacer } from "components/Spacer";

type Props = {
  shopId: string | null;
};

const Container = styled(Typography.Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const OneTimeCodeGenerator = memo<Props>(({ shopId }) => {
  const [seed, setSeed] = useState<number>(0);

  const base = shopId?.split("-").at(1);
  const code = base && seed > 999 ? `${parseInt(base, 16) ^ parseInt(`${seed}`, 10)}` : null;

  const onChange = useCallback((next: string | null) => {
    setSeed(zeroIfNaN(Number(next)));
  }, []);

  return (
    <Container>
      <strong>ワンタイムコード生成:</strong>
      <Spacer inline size={8} />
      <InputNumber<string>
        disabled={!shopId}
        min="0"
        max="9999"
        controls={false}
        onChange={onChange}
      />
      <Spacer inline size={8} />
      <strong>
        →{" "}
        {code ||
          (shopId ? "レジに表示されているコードを入力してください" : "店舗を指定してください")}
      </strong>
    </Container>
  );
});
