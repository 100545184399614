import React, { memo } from "react";
import styled from "styled-components";

import { Slip } from "pages/PrintTargets/PrintTargetTable/Slip";

type Props = {
  numberOfPeople: number;
  orderedAt: string;
  tableName: string;
  name: string;
  quantity?: string;
  optionText: string;
  remark: string;
  isMadeForOptionSlip?: boolean;
  ordererName: string;
};

const Container = styled(Slip)``;

const OptionHeader = styled.div`
  text-align: center;
`;

const ShortSlipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  text-decoration: underline;
  text-underline-position: under;
`;

const ShortSlipContent = styled.div`
  display: flex;
  margin-top: 8px;
`;

const ShortSlipContentLeft = styled.div`
  margin-top: 8px;
`;

const ShortSlipContentRight = styled.div`
  margin-top: 8px;
`;

const ShortSlipContentQuantity = styled.span`
  margin-right: 8px;
`;

const ShortSlipContentOption = styled.div`
  font-size: 12px;
`;

const ShortSlipContentRemark = styled.div``;

const ShortSlipContentOrdererName = styled.div`
  text-align: right;
  font-size: 10px;
`;

export const ShortSlip = memo<Props>(
  ({
    numberOfPeople,
    orderedAt,
    tableName,
    name,
    quantity,
    optionText,
    remark,
    isMadeForOptionSlip,
    ordererName,
  }) => (
    <Container>
      {isMadeForOptionSlip ? <OptionHeader>ーオプション用ー</OptionHeader> : null}
      <ShortSlipHeader>
        <div>
          {orderedAt} 人数{numberOfPeople}
        </div>
        <div>{tableName}卓</div>
      </ShortSlipHeader>
      <ShortSlipContent>
        <ShortSlipContentLeft>
          {quantity ? <ShortSlipContentQuantity>{quantity}</ShortSlipContentQuantity> : null}
        </ShortSlipContentLeft>
        <ShortSlipContentRight>
          {name}
          {optionText ? <ShortSlipContentOption>{optionText}</ShortSlipContentOption> : null}
          {remark ? <ShortSlipContentRemark>※ {remark}</ShortSlipContentRemark> : null}
        </ShortSlipContentRight>
      </ShortSlipContent>
      <ShortSlipContentOrdererName>{ordererName}</ShortSlipContentOrdererName>
    </Container>
  ),
);
