import React, { memo } from "react";
import { Button } from "antd";

type Props = {
  loadMore: () => Promise<void>;
  isLast: boolean;
};

export const LoadMoreButton = memo<Props>(({ loadMore, isLast }) => (
  <Button onClick={() => loadMore()} disabled={isLast}>
    {isLast ? "すべて取得済です" : "更に取得"}
  </Button>
));
