import React, { memo } from "react";
import styled from "styled-components";
import { Button, TableColumnsType } from "antd";
import dayjs from "dayjs";

import { Table } from "components/Table";
import { isProduction } from "constants/environments";
import { DeviceStatus } from "hooks/useDeviceStatus";
import { usePagination } from "hooks/usePagination";
import {
  checkBluetooth,
  reboot,
  saveDisableAlertUntilDate,
  saveMemo,
  sendCommand,
  softReboot,
} from "pages/DeviceMonitoring/deviceStatus";
import { DisableAlertField } from "pages/DeviceMonitoring/DeviceStatusTable/DisableAlertField";
import { MemoField } from "pages/DeviceMonitoring/DeviceStatusTable/MemoField";

const getDirectPrintLogLink = (status: DeviceStatus) => {
  if (!status.epsonDeviceIdToRoleIdMap) return;
  const emailPrefix = status.email?.split("@")?.[0];
  const directPrintLogLink = isProduction
    ? `https://console.cloud.google.com/logs/viewer?project=dinii-self-prod&minLogLevel=0&expandAll=false&timestamp=2021-07-21T06:52:16.144000000Z&customFacets=jsonPayload.meta.request.body.ConnectionType,jsonPayload.meta.request.body.ResponseFile&limitCustomFacetWidth=true&dateRangeStart=2021-07-20T11:50:16.000Z&dateRangeEnd=2021-07-20T11:53:16.000Z&interval=CUSTOM&scrollTimestamp=2021-07-20T11:52:05.192790000Z&advancedFilter=resource.type%20%3D%20%22cloud_run_revision%22%0Aresource.labels.service_name%20%3D%20%22dinii-self-backend-production%22%0Aseverity%3E%3DDEFAULT%0AjsonPayload.meta.request.body.ID%3D%22${emailPrefix}%22`
    : `https://console.cloud.google.com/logs/viewer?project=dinii-self-stag&organizationId=560334665177&minLogLevel=0&expandAll=false&timestamp=2021-07-21T07:03:51.235000000Z&customFacets=jsonPayload.metadata.meta.request.body.ConnectionType&limitCustomFacetWidth=false&dateRangeStart=2021-07-21T06:04:54.386Z&dateRangeEnd=2021-07-21T07:04:54.386Z&interval=PT1H&resource=gae_app%2Fmodule_id%2Fdefault&logName=projects%2Fdinii-self-stag%2Flogs%2Fappengine.googleapis.com%252Fstdout&logName=projects%2Fdinii-self-stag%2Flogs%2Fappengine.googleapis.com%252Fstderr&logName=projects%2Fdinii-self-stag%2Flogs%2Fappengine.googleapis.com%252Fnginx.request&logName=projects%2Fdinii-self-stag%2Flogs%2Fwinston_log&scrollTimestamp=2021-07-21T07:04:48.561000108Z&advancedFilter=resource.type%3D%22gae_app%22%0Aresource.labels.module_id%3D%22default%22%0AlogName%3D(%22projects%2Fdinii-self-stag%2Flogs%2Fwinston_log%22)%0AjsonPayload.metadata.meta.request.body.ID%3D%22${emailPrefix}%22`;
  return directPrintLogLink;
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  gap: 8px;
`;

const Code = styled.div`
  font-size: 10px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
`;

type Props = {
  loading?: boolean;
  deviceStatuses: DeviceStatus[];
  onClickDeviceStatus: (status: DeviceStatus) => void;
  onClickDeviceSettings: (status: DeviceStatus) => void;
};

export const DeviceStatusTable = memo<Props>(
  ({ loading, deviceStatuses, onClickDeviceStatus, onClickDeviceSettings }) => {
    const [pagination, setPagination] = usePagination();

    const columns: TableColumnsType<DeviceStatus> = [
      {
        title: "タイプ",
        width: 80,
        render(_: unknown, status: DeviceStatus) {
          const isEpsonPrinter = Boolean(status.epsonDeviceIdToRoleIdMap);
          return isEpsonPrinter ? `Epson Printer Direct Print` : `Dinii PC(${status.appVersion})`;
        },
      },
      { title: "UID", dataIndex: "uid", width: 150 },
      { title: "Serial Number", dataIndex: "epsonPrinterSerialNumber", width: 150 },
      {
        title: "最終更新",
        width: 150,
        render(_: unknown, status: DeviceStatus) {
          const [date, time] = dayjs(status.lastUpdatedAt.toDate())
            .format("M月D日,H時m分s秒")
            .split(",");
          return (
            <>
              {date}
              <br />
              {time}
            </>
          );
        },
      },
      { title: "ステータス", dataIndex: "lastUpdatedType", width: 150 },
      {
        title: "詳細",
        render(_: unknown, status: DeviceStatus) {
          const isEpsonPrinter = Boolean(status.epsonDeviceIdToRoleIdMap);

          if (!isEpsonPrinter) {
            return (
              <>
                <p>{status.detail}</p>
                <p>最終受信コマンド: {status.lastReceivedCommand}</p>
              </>
            );
          }

          try {
            const detail = JSON.parse(status?.detail ?? "");
            return (
              <Code>
                <pre>
                  <code>{JSON.stringify(detail, null, 2)}</code>
                </pre>
              </Code>
            );
          } catch (err) {
            console.error(err);
            return status.detail;
          }
        },
      },
      {
        title: "メモ",
        fixed: "right",
        width: 250,
        render(_: unknown, status: DeviceStatus) {
          return (
            <MemoField
              defaultValue={status.memo ?? ""}
              onSave={(memo) => saveMemo(status.docId, memo)}
            />
          );
        },
      },
      {
        title: "",
        align: "center",
        fixed: "right",
        width: 250,
        render(_: unknown, status: DeviceStatus) {
          const isEpsonPrinter = Boolean(status.epsonDeviceIdToRoleIdMap);

          return (
            <>
              {!isEpsonPrinter && (
                <Column>
                  <Button onClick={() => sendCommand(status.uid, "pingPrinter")}>
                    プリンターping
                  </Button>
                  <Button onClick={() => sendCommand(status.uid, "startListenPrinter")}>
                    通信開始
                  </Button>
                  <Button onClick={() => sendCommand(status.uid, "stopListenPrinter")}>
                    通信終了
                  </Button>
                  <Button onClick={() => checkBluetooth(status.uid)}>BTの状態を確認する</Button>
                </Column>
              )}
              {!isEpsonPrinter && (
                <Column>
                  <Button onClick={() => softReboot(status.uid)}>アプリ再起動</Button>
                  <Button onClick={() => reboot(status.uid)}>端末再起動</Button>
                </Column>
              )}

              <Column>
                <Button type="primary" onClick={() => onClickDeviceStatus(status)}>
                  プリンター状況の確認
                </Button>
                <Button type="primary" onClick={() => onClickDeviceSettings(status)}>
                  プリンター設定
                </Button>
                {isEpsonPrinter && (
                  <Button>
                    <a
                      href={getDirectPrintLogLink(status)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CloudRun ログ
                    </a>
                  </Button>
                )}
              </Column>

              <Column>
                <DisableAlertField
                  defaultValue={status.disableAlertUntilDate?.toDate()}
                  onSave={(date) => saveDisableAlertUntilDate(status.docId, date)}
                />
              </Column>
            </>
          );
        },
      } as const,
    ];

    return (
      <Table
        rowKey="uid"
        scroll={{ x: "calc(100% + 500px)" }}
        columns={columns}
        dataSource={deviceStatuses}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
