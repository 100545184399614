import * as Types from '../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EpsonDevicePrinterSettingsDialogTestPrint = gql`
    mutation EpsonDevicePrinterSettingsDialogTestPrint($input: TestPrintInput!) {
  monitorApiTestPrint(input: $input)
}
    `;
export type EpsonDevicePrinterSettingsDialogTestPrintMutationVariables = Types.Exact<{
  input: Types.TestPrintInput;
}>;


export type EpsonDevicePrinterSettingsDialogTestPrintMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'monitorApiTestPrint'>
);


export const EpsonDevicePrinterSettingsDialogTestPrintDocument = gql`
    mutation EpsonDevicePrinterSettingsDialogTestPrint($input: TestPrintInput!) {
  monitorApiTestPrint(input: $input)
}
    `;
export type EpsonDevicePrinterSettingsDialogTestPrintMutationFn = Apollo.MutationFunction<EpsonDevicePrinterSettingsDialogTestPrintMutation, EpsonDevicePrinterSettingsDialogTestPrintMutationVariables>;

/**
 * __useEpsonDevicePrinterSettingsDialogTestPrintMutation__
 *
 * To run a mutation, you first call `useEpsonDevicePrinterSettingsDialogTestPrintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEpsonDevicePrinterSettingsDialogTestPrintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [epsonDevicePrinterSettingsDialogTestPrintMutation, { data, loading, error }] = useEpsonDevicePrinterSettingsDialogTestPrintMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEpsonDevicePrinterSettingsDialogTestPrintMutation(baseOptions?: Apollo.MutationHookOptions<EpsonDevicePrinterSettingsDialogTestPrintMutation, EpsonDevicePrinterSettingsDialogTestPrintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EpsonDevicePrinterSettingsDialogTestPrintMutation, EpsonDevicePrinterSettingsDialogTestPrintMutationVariables>(EpsonDevicePrinterSettingsDialogTestPrintDocument, options);
      }
export type EpsonDevicePrinterSettingsDialogTestPrintMutationHookResult = ReturnType<typeof useEpsonDevicePrinterSettingsDialogTestPrintMutation>;
export type EpsonDevicePrinterSettingsDialogTestPrintMutationResult = Apollo.MutationResult<EpsonDevicePrinterSettingsDialogTestPrintMutation>;
export type EpsonDevicePrinterSettingsDialogTestPrintMutationOptions = Apollo.BaseMutationOptions<EpsonDevicePrinterSettingsDialogTestPrintMutation, EpsonDevicePrinterSettingsDialogTestPrintMutationVariables>;