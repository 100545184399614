import { isNotNullish } from "dinii-self-js-lib/types";

export type ItemSales = {
  billingAmount: number;
  taxAmount: number;
};

type Maybe<T> = T | null | undefined;

export const extractItemSales = (onSitePayment: {
  payingMenuOrderItems: { itemSales?: Maybe<ItemSales> }[];
  payingActivePlanChoices: { itemSales?: Maybe<ItemSales> }[];
  payingActivePlanOpenPriceMetas: { itemSales?: Maybe<ItemSales> }[];
}) =>
  [
    ...onSitePayment.payingMenuOrderItems.map((item) => item.itemSales),
    ...onSitePayment.payingActivePlanChoices.map((item) => item.itemSales),
    ...onSitePayment.payingActivePlanOpenPriceMetas.map((item) => item.itemSales),
  ].filter(isNotNullish);
