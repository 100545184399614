export const isObject = (value: unknown): value is Record<string, unknown> =>
  value !== null && typeof value === "object";

export const isString = (value: unknown): value is string => typeof value === "string";

export const isNumber = (value: unknown): value is number => typeof value === "number";

export const isNotNull = <T>(value: T): value is Exclude<T, null> => value !== null;

export const isNotUndefined = <T>(value: T): value is Exclude<T, undefined> => value !== undefined;

export const isNotNullable = <T>(value: T): value is Exclude<T, null | undefined> =>
  value !== null && value !== undefined;
