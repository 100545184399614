import { useCallback, useEffect, useMemo, useRef } from "react";

/**
 * useEffect などの再評価に影響を与えずに最新の値を callback 内から取得したいケースが時々ある。
 * useImmutableGetter 内のパターンをそれぞれの場所で実装することも出来るが毎回同じパターンのため共通化している。
 */
export const useImmutableGetter = <T>(factory: () => T, deps: React.DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(factory, deps);
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return useCallback(() => ref.current, []);
};
