import React, { memo } from "react";
import { message, Modal } from "antd";

import { ShopClient } from "../../../entity/shopClient.entity";

import { useRemoveShopClientMutation } from "./queries";

type Props = {
  shopClient: ShopClient;
  isOpen: boolean;
  onClose: () => void;
};

export const RemoveShopClientDialog = memo<Props>(({ shopClient, isOpen, onClose }) => {
  const [removeShopClient, { loading }] = useRemoveShopClientMutation({
    variables: { input: { shopClientId: shopClient.shopClientId } },
    onCompleted: () => {
      message.success("削除しました");
      onClose();
    },
    onError: (e) => {
      console.error(e);
      message.error("削除に失敗しました");
      onClose();
    },
  });

  return (
    <Modal
      title={shopClient.email}
      open={isOpen}
      okText="削除"
      onOk={() => removeShopClient()}
      onCancel={onClose}
      confirmLoading={loading}
    >
      以下のShopClientを削除しますか?
      <p>
        Shop Client ID: {shopClient.shopClientId}
        <br />
        Name: {shopClient.name}
      </p>
    </Modal>
  );
});
