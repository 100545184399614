import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Table, Typography } from "antd";
import dayjs from "dayjs";

import { usePagination } from "hooks/usePagination";
import { CheckInEventCustomer } from "pages/TableUserDetail/types";

const { Paragraph } = Typography;

type CustomerCheckInEvent = CheckInEventCustomer["checkInEvents"][number];

type Props = {
  checkInEvents: CustomerCheckInEvent[];
};

export const CustomerCheckInEventTable = memo<Props>(({ checkInEvents }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "チェックイン時刻",
      render(_: unknown, { checkedInAt }: CustomerCheckInEvent) {
        return dayjs(checkedInAt).format("MM/DD HH:mm");
      },
    },
    {
      title: "店舗",
      render(_: unknown, { shop }: CustomerCheckInEvent) {
        return shop.name;
      },
    },
    {
      title: "卓",
      render(_: unknown, { tableUser }: CustomerCheckInEvent) {
        return tableUser?.table.name;
      },
    },
    {
      title: "テーブルユーザー ID",
      render(_: unknown, { tableUser, shop }: CustomerCheckInEvent) {
        if (!tableUser) return null;

        return (
          <Paragraph copyable={{ text: tableUser.id }}>
            <Link to={`/table-users/${tableUser.id}?shopId=${shop.shopId}`}>{tableUser.id}</Link>
          </Paragraph>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={checkInEvents}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
