import React, { memo } from "react";

import { LocalStartTableUserDataSummary } from "../type";

type Props = {
  summary: LocalStartTableUserDataSummary;
};

export const StartTableUserContent = memo<Props>(({ summary }) => {
  const { id, numberOfPeople } = summary;
  return (
    <div>
      <p>{numberOfPeople}人</p>
      <pre>卓（{id}</pre>
    </div>
  );
});
