import { useCallback } from "react";

import { useAppContext } from "../../context/AppContext";

import { useShopsGetCompaniesAndShopsQuery } from "./queries";

export const useShops = () => {
  const { isAuthenticated } = useAppContext();
  const {
    loading,
    data: getCompaniesAndShopsData,
    refetch,
  } = useShopsGetCompaniesAndShopsQuery({
    skip: !isAuthenticated,
  });
  const companies = getCompaniesAndShopsData?.company ?? [];
  const shops = getCompaniesAndShopsData?.shop ?? [];

  const companyIdToCompanyNameMap = Object.fromEntries(
    companies.map(({ companyId, name }) => [companyId, name]),
  );
  const getCompanyName = useCallback(
    (companyId: number) => companyIdToCompanyNameMap[`${companyId}`],
    [companyIdToCompanyNameMap],
  );

  const shopIdToShopNameMap = Object.fromEntries(shops.map(({ shopId, name }) => [shopId, name]));
  const getShopName = useCallback(
    (shopId: string) => shopIdToShopNameMap[shopId],
    [shopIdToShopNameMap],
  );

  return {
    loading,
    shops,
    companies,
    getShopName,
    getCompanyName,
    refetch,
  };
};
