import React, { memo } from "react";
import { Table, TableColumnsType } from "antd";

import { OfflineFeatureActivity, OfflineFeatureType } from "../type";

type Props = {
  activities: OfflineFeatureActivity[];
};

export const OfflineFeatureActivityTable = memo<Props>(({ activities }) => {
  const columns: TableColumnsType<OfflineFeatureActivity> = [
    {
      title: "種類",
      dataIndex: "type",
      width: 100,
      fixed: "left",
      render(_, { type }) {
        switch (type) {
          case OfflineFeatureType.TableUser:
            return "卓立ち上げ";
          case OfflineFeatureType.Order:
            return "注文";
          case OfflineFeatureType.ActivePlan:
            return "プラン開始";
          case OfflineFeatureType.Payment:
            return "会計";
          default:
            throw new Error(`unreachable ${type}`);
        }
      },
    },
    {
      title: "作成時刻",
      dataIndex: "issuedAt",
      width: 130,
      fixed: "left",
      render(_, { issuedAt }) {
        return issuedAt.format("YYYY-MM-DD\nHH:mm:ss");
      },
    },
    {
      title: "データ送信時刻",
      dataIndex: "resolvedAt",
      width: 130,
      fixed: "left",
      render(_, { resolvedAt }) {
        return resolvedAt ? resolvedAt.format("YYYY-MM-DD\nHH:mm:ss") : "未送信";
      },
    },
    {
      title: "概要",
      dataIndex: "summary",
      render(_, { summary }) {
        return <pre>{summary}</pre>;
      },
    },
  ];

  return <Table scroll={{ x: "calc(100% + 360px)" }} dataSource={activities} columns={columns} />;
});
