import React, { Fragment, memo } from "react";
import styled from "styled-components";
import { Alert, Card, Form, Typography } from "antd";
import { NotificationStatus } from "models/notificationSettings";

import { Spacer } from "components/Spacer";
import { useShops } from "hooks/useShops";

import { NotificationSettingsFormValues } from "../useNotificationSettingsForm";

import { ChannelsField } from "./ChannelsField";
import { CompanyKeysByTextField } from "./CompanyKeysByTextField";
import { CompanyKeysField } from "./CompanyKeysField";
import { DeliveryTypeField } from "./DeliveryTypeField";
import { ShopKeysByTextField } from "./ShopKeysByTextField";
import { ShopKeysField } from "./ShopKeysField";

const CodeWrapper = styled.pre`
  background: #ffffff88;
  border: 1px solid #00000022;
  border-radius: 5px;
  padding: 12px;
`;

export const NotificationDeliveryFields = memo(({ status }: { status: NotificationStatus }) => {
  const disabled = status !== "draft" && status !== "reserved" && status !== "started";

  const { loading, companies, getShopName } = useShops();

  return (
    <Card>
      <Typography.Title level={4}>配信対象</Typography.Title>
      <DeliveryTypeField disabled={disabled} />
      <Form.Item<NotificationSettingsFormValues> noStyle dependencies={["deliveryType"]}>
        {(form) => {
          const isNarrowcast = form.getFieldValue("deliveryType") === "narrowcast";
          const validateKeys = async () => {
            const { companyKeys, shopKeys } = form.getFieldsValue([
              "companyKeys",
              "shopKeys",
            ]) as Pick<NotificationSettingsFormValues, "companyKeys" | "shopKeys">;
            if (!companyKeys.length && !shopKeys.length) {
              throw new Error("業態単位または店舗個別単位または両方で配信先を指定してください");
            }
          };
          return (
            <>
              <CompanyKeysField
                loading={loading}
                disabled={disabled || !isNarrowcast}
                companies={companies}
                rules={isNarrowcast ? [{ validator: validateKeys }] : undefined}
              />
              <CompanyKeysByTextField disabled={disabled || !isNarrowcast} />
              <ShopKeysField
                loading={loading}
                disabled={disabled || !isNarrowcast}
                companies={companies}
                getShopName={getShopName}
                rules={isNarrowcast ? [{ validator: validateKeys }] : undefined}
              />
              <ShopKeysByTextField disabled={disabled || !isNarrowcast} />
              <Alert
                description={
                  <>
                    <p>
                      カンマ区切り店舗名から shopId へ変換するには以下のような BigQuery
                      クエリを利用してください。
                      <br />※ WHERE 句は任意のものに置き換え可能
                      <br />※ Dashboard や Monitor で表示される店舗名 (shop.name) を利用してください
                    </p>
                    <CodeWrapper>
                      {[
                        `SELECT`,
                        `  TO_JSON_STRING(ARRAY_AGG(shopId)) AS json`,
                        `FROM`,
                        `  \`pos.public_shop\``,
                        `WHERE`,
                        `  name IN UNNEST(SPLIT("店舗名1,店舗名2,店舗名3",","));`,
                      ].map((line, index) => (
                        <Fragment key={index}>
                          {line}
                          <br />
                        </Fragment>
                      ))}
                    </CodeWrapper>
                  </>
                }
              />
            </>
          );
        }}
      </Form.Item>
      <Spacer size={16} />
      <ChannelsField
        disabled={disabled}
        required
        rules={[{ required: true, message: "配信対象デバイスを選択してください" }]}
      />
    </Card>
  );
});
