import React, { memo, useState } from "react";
import styled from "styled-components";
import { Button, Checkbox, List, message, Modal, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";

import { Role } from "../../entity/role.entity";
import { request } from "../../libs/api";

const startReplacementRequest = async ({
  shopId,
  roleId,
  replaceToRoleId,
}: {
  shopId: string;
  roleId: number;
  replaceToRoleId: number;
}): Promise<string> => {
  try {
    await request.post(`admin/${shopId}/roleReplacement/${roleId}`, { replaceTo: replaceToRoleId });
    return "ok";
  } catch (e) {
    return `error: ${e}`;
  }
};

const listStyle = { flex: 1 };

const ListContainer = styled.div`
  display: flex;
  gap: 32px;
`;

type Props = {
  roles: Role[];
  refetch: () => void;
};

export const EmergencyPrintBackupButton = memo<Props>(({ roles, refetch }) => {
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId");

  const [openReplacementDialog, setOpenReplacementDialog] = useState(false);
  const [fromRole, setFromRole] = useState(null as Role | null);
  const [toRole, setToRole] = useState(null as Role | null);

  const onClickStartButton = () => {
    if (!shopId || !fromRole || !toRole) {
      return;
    }
    const result = window.confirm("緊急伝票振り分け設定を適用します。よろしいですか？");
    if (!result) {
      return;
    }

    startReplacementRequest({
      shopId,
      roleId: fromRole.roleId,
      replaceToRoleId: toRole.roleId,
    }).then((ret) => {
      message.success(ret);
      refetch();
      setOpenReplacementDialog(false);
    });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpenReplacementDialog(true)}
        icon={<WarningOutlined />}
        danger
      >
        緊急伝票振替
      </Button>
      <Modal
        title="振替設定"
        open={openReplacementDialog}
        width={800}
        onOk={() => onClickStartButton()}
        okText="適用"
        onCancel={() => setOpenReplacementDialog(false)}
      >
        <ShopSelector />

        <Spacer size={16} />

        {shopId && (
          <ListContainer>
            <List
              // eslint-disable-next-line react/forbid-component-props
              style={listStyle}
              header="From"
              bordered
              dataSource={roles.filter((r) => r.shopId === shopId)}
              renderItem={(role) => (
                <List.Item>
                  <Typography>
                    {role.name}({role.roleId})
                  </Typography>

                  <Checkbox
                    disabled={toRole?.roleId === role.roleId}
                    onChange={() => setFromRole(role)}
                    checked={fromRole?.roleId === role.roleId}
                  />
                </List.Item>
              )}
            />

            <List
              // eslint-disable-next-line react/forbid-component-props
              style={listStyle}
              header="To"
              bordered
              dataSource={roles.filter((r) => r.shopId === shopId)}
              renderItem={(role) => (
                <List.Item>
                  <Typography>
                    {role.name}({role.roleId})
                  </Typography>

                  <Checkbox
                    disabled={fromRole?.roleId === role.roleId}
                    onChange={() => setToRole(role)}
                    checked={toRole?.roleId === role.roleId}
                  />
                </List.Item>
              )}
            />
          </ListContainer>
        )}
      </Modal>
    </>
  );
});
