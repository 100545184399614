import dayjs from "dayjs";
import { calcTaxIncludedPrice } from "dinii-self-js-lib/tax";
import { sumBy } from "lodash";

import { TaxMethodEnum } from "types/graphql";

export const OfflineFeatureType = {
  TableUser: "tableUser",
  Order: "order",
  ActivePlan: "activePlan",
  Payment: "payment",
} as const;

export type OfflineFeatureType = typeof OfflineFeatureType[keyof typeof OfflineFeatureType];

export type OfflineFeatureActivity = {
  type: OfflineFeatureType;
  recordId: string;
  cashRegisterId: string | null;
  issuedAt: dayjs.Dayjs;
  resolvedAt: dayjs.Dayjs | null;
  summary: string;
};

export type LocalDataSummary =
  | LocalOrderDataSummary
  | LocalActivePlanDataSummary
  | LocalOnSitePaymentDataSummary
  | LocalStartTableUserDataSummary;

export type LocalOrderDataSummary = {
  type: "order";
  id: string;
  tableUserId: string;
  orderedAt: string;

  menuOrderItems: {
    id: string;
    name: string;
    price: number;
    taxRate: number;
    taxMethod: TaxMethodEnum;
    quantity: number;

    menuOrderItemOptions: {
      id: string;
      name: string;

      menuOrderItemChoices: {
        id: string;
        name: string;
        price: number;
        quantity: number;
      }[];
    }[];
  }[];
};
type MenuOrderItemSummary = LocalOrderDataSummary["menuOrderItems"][number];
type MenuOrderItemOptionSummary = MenuOrderItemSummary["menuOrderItemOptions"][number];
type MenuOrderItemChoiceSummary = MenuOrderItemOptionSummary["menuOrderItemChoices"][number];

// NOTE: hasura のパーミッションなどを考えないと型を直書きするしかないので一旦 any にしてしまう
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any;

export const createLocalOrderDataSummary = ({ order }: { order: Any }): LocalOrderDataSummary => ({
  type: "order",
  id: order.id,
  orderedAt: order.orderedAt,
  tableUserId: order.billableTableUserId,
  menuOrderItems: order.menuOrderItems.map((moi: Any) => ({
    id: moi.id,
    name: moi.name,
    price: moi.price,
    taxRate: moi.taxRate,
    taxMethod: moi.taxMethod,
    quantity: moi.quantity,
    menuOrderItemOptions: moi.menuOrderItemOptions.map((option: Any) => ({
      id: option.id,
      name: option.name,
      menuOrderItemChoices: option.menuOrderItemChoices.map((choice: Any) => ({
        id: choice.id,
        name: choice.name,
        price: choice.price,
        quantity: choice.quantity,
      })) as MenuOrderItemChoiceSummary[],
    })) as MenuOrderItemOptionSummary[],
  })) as MenuOrderItemSummary[],
});

export type LocalActivePlanDataSummary = {
  type: "activePlan";
  id: string;
  taxRate: number;
  taxMethod: TaxMethodEnum;
  tableUserId: string;
  createdAt: string;
  name: string;

  activePlanOpenPriceMeta: {
    quantity: number;
  } | null;

  activePlanOptions: {
    id: string;
    name: string;

    activePlanChoices: {
      id: string;
      name: string;
      price: number;
      quantity: number;
    }[];
  }[];
};
type ActivePlanOptionSummary = LocalActivePlanDataSummary["activePlanOptions"][number];
type ActivePlanChoiceSummary = ActivePlanOptionSummary["activePlanChoices"][number];

export const createLocalActivePlanDataSummary = ({
  activePlan,
}: {
  activePlan: Any;
}): LocalActivePlanDataSummary => ({
  type: "activePlan",
  id: activePlan.id,
  taxRate: activePlan.taxRate,
  taxMethod: activePlan.taxMethod,
  tableUserId: activePlan.tableUserId,
  createdAt: activePlan.createdAt,
  name: activePlan.name,
  activePlanOpenPriceMeta: activePlan.activePlanOpenPriceMeta,
  activePlanOptions: activePlan.activePlanOptions.map((option: Any) => ({
    id: option.id,
    name: option.name,
    activePlanChoices: option.activePlanChoices.map((choice: Any) => ({
      id: choice.id,
      name: choice.name,
      price: choice.price,
      quantity: choice.quantity,
    })) as ActivePlanChoiceSummary[],
  })) as ActivePlanOptionSummary[],
});

export type LocalOnSitePaymentDataSummary = {
  type: "onSitePayment";
  id: string;
  tableUserIds: string[];
  createdAt: string;

  details: {
    name: string;
    amount: number;
  }[];
};
type OnSitePaymentDetailSummary = LocalOnSitePaymentDataSummary["details"][number];

export const createLocalOnSitePaymentDataSummary = ({
  onSitePayment,
}: {
  onSitePayment: Any;
}): LocalOnSitePaymentDataSummary => ({
  type: "onSitePayment",
  id: onSitePayment.id,
  tableUserIds: onSitePayment.onSitePaymentTableUsers.map(
    ({ tableUserId }: Any) => tableUserId,
  ) as string[],
  createdAt: onSitePayment.createdAt,
  details: onSitePayment.onSitePaymentDetails.map((detail: Any) => ({
    amount: detail.netPrice,
  })) as OnSitePaymentDetailSummary[],
});

export type LocalStartTableUserDataSummary = {
  type: "tableUser";
  id: string;
  activatedAt: string;
  numberOfPeople: number;
};

export const createLocalStartTableUserDataSummary = ({
  tableUser,
}: {
  tableUser: Any;
}): LocalStartTableUserDataSummary => ({
  type: "tableUser",
  id: tableUser.id,
  activatedAt: tableUser.activatedAt,
  numberOfPeople: tableUser.numPeople,
});

export const calcMenuOrderItemPrice = (item: LocalOrderDataSummary["menuOrderItems"][number]) => {
  const calculatedItemPrice = calcTaxIncludedPrice(item);

  const calculatedSelectionsPrice = sumBy(item.menuOrderItemOptions, (option) =>
    sumBy(option.menuOrderItemChoices, (choice) =>
      calcTaxIncludedPrice({
        ...item,
        price: choice.price,
      }),
    ),
  );

  return calculatedItemPrice + calculatedSelectionsPrice;
};
