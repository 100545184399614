import React, { memo } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { getTitle } from "util/title";

import { Navigation } from "components/Layout/BaseLayout/Navigation";
import { auth } from "libs/firebase";

import { LocationBreadcrumb, LocationBreadcrumbProps } from "./LocationBreadcrumb";

const signOut = async () => auth.signOut();

const StyledLayout = styled(Layout)`
  height: 100%;
`;

const StyledContent = styled(Layout.Content)`
  overflow-y: scroll;
`;

const Content = styled.div`
  padding: 0 24px 24px;
`;

const PageTitle = styled.span`
  font-size: 24px;
`;

export type BaseLayoutProps = {
  title: string;
  breadcrumb?: LocationBreadcrumbProps;
  headerExtra?: JSX.Element;
  onBack?: () => void;
  children: React.ReactNode;
};

export const BaseLayout = memo<BaseLayoutProps>(
  ({ title, breadcrumb, headerExtra, onBack, children }) => {
    const pageTitle = getTitle(title);

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <StyledLayout>
          <Navigation signOut={signOut} />
          <StyledContent>
            {breadcrumb && <LocationBreadcrumb items={breadcrumb.items} />}
            <PageHeader
              title={<PageTitle>{title}</PageTitle>}
              extra={headerExtra}
              onBack={onBack}
            />
            <Content>{children}</Content>
          </StyledContent>
        </StyledLayout>
      </>
    );
  },
);
