import React, { memo } from "react";
import styled from "styled-components";
import { Card, Col, Row, Statistic } from "antd";

import { Table, TableUser } from "pages/TableUserStatus/types";

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

type Props = {
  tableUsers: TableUser[];
  tables: Table[];
};

export const TableUserStatistics = memo<Props>(({ tableUsers, tables }) => (
  <Wrapper>
    <Row gutter={16}>
      <Col span={8}>
        <Card>
          <Statistic
            title="アクティブテーブル数"
            value={tableUsers.filter(({ state }) => state === "active").length}
            suffix="台"
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic
            title="会計待ちテーブル数"
            value={
              tableUsers.filter(({ state }) => state !== "active" && state !== "inactive").length
            }
            suffix="台"
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          <Statistic title="合計テーブル数" value={tables.length} suffix="台" />
        </Card>
      </Col>
    </Row>
  </Wrapper>
));
