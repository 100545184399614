import { useCallback } from "react";
import { Form } from "antd";

export type CashRegisterSettingsFormFormValues = {
  printerConnectionName?: string;
  printerConnectionTarget?: string;
  cashChangerConnectionTarget?: string;
  drawerPassword?: string;
};

const getInitialValues = (settings: CashRegisterSettingsFormFormValues) => settings;

export const useCashRegisterSettingsForm = ({
  settings,
  onSubmit,
}: {
  settings: CashRegisterSettingsFormFormValues;
  onSubmit: (settings: CashRegisterSettingsFormFormValues) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(settings);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as CashRegisterSettingsFormFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
