import React, { memo, useCallback, useEffect, useState } from "react";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { DeviceStatus, useDeviceStatus } from "hooks/useDeviceStatus";
import { useSearchParams } from "hooks/useSearchParams";
import { sendCommand } from "pages/DeviceMonitoring/deviceStatus";
import { DeviceStatusTable } from "pages/DeviceMonitoring/DeviceStatusTable";

import { useSelectedShop } from "../../hooks/useSelectedShop";
import { useShops } from "../../hooks/useShops";

import { EpsonDevicePrinterSettingsDialog } from "./EpsonDevicePrinterSettingsDialog";
import { PrinterSettingsDialog } from "./PrinterSettingsDialog";
import { PrinterStatusesDialog } from "./PrinterStatusDialog";

export const DeviceMonitoring = memo(() => {
  const [selectedStatus, setSelectedStatus] = useState<DeviceStatus | null>(null);
  const [printerSettingsDialog, setPrinterSettingsDialog] = useState(false);
  const [epsonPrinterSettingsDialog, setEpsonPrinterSettingsDialog] = useState(false);
  const [printerStatusesDialog, setPrinterStatusesDialog] = useState(false);

  const { getSearchParam } = useSearchParams<"shopId">();
  const { setSelectedShop } = useSelectedShop();
  const { shops } = useShops();

  const [shopSet, setShopSet] = useState(false);
  const shopId = getSearchParam("shopId");

  useEffect(() => {
    if (shopSet) return;
    if (!shopId) return;
    const shop = shops.find((s) => s.shopId === shopId);
    if (!shop) return;

    setSelectedShop(shop.shopId);
    setShopSet(true);
  }, [setSelectedShop, shops, shopSet, shopId]);

  const { statuses } = useDeviceStatus({ shopId });

  const onClickDeviceStatus = useCallback((status: DeviceStatus) => {
    setSelectedStatus(status);
    setPrinterStatusesDialog(true);
  }, []);

  const onClickDeviceSettings = useCallback((status: DeviceStatus) => {
    setSelectedStatus(status);
    status.epsonDeviceIdToRoleIdMap
      ? setEpsonPrinterSettingsDialog(true)
      : setPrinterSettingsDialog(true);
  }, []);

  return (
    <MainLayout title="印刷端末監視">
      <ShopSelector />

      <Spacer size={16} />

      <DeviceStatusTable
        deviceStatuses={statuses}
        onClickDeviceStatus={onClickDeviceStatus}
        onClickDeviceSettings={onClickDeviceSettings}
      />

      <PrinterSettingsDialog
        isOpen={printerSettingsDialog}
        shopId={selectedStatus?.shopId ?? undefined}
        uid={selectedStatus?.uid ?? undefined}
        onClose={() => setPrinterSettingsDialog(false)}
      />
      <PrinterStatusesDialog
        isOpen={printerStatusesDialog}
        isEpson={Boolean(selectedStatus?.epsonDeviceIdToRoleIdMap)}
        uid={selectedStatus?.uid ?? undefined}
        onClose={() => setPrinterStatusesDialog(false)}
        onClickPing={() => selectedStatus && sendCommand(selectedStatus.uid, "pingPrinter")}
      />
      <EpsonDevicePrinterSettingsDialog
        status={selectedStatus ?? undefined}
        isOpen={epsonPrinterSettingsDialog}
        onClose={() => setEpsonPrinterSettingsDialog(false)}
      />
    </MainLayout>
  );
});
