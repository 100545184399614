import React, { useCallback, useMemo } from "react";

import { MainLayout } from "components/Layout/MainLayout";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import { useSelectedShop } from "hooks/useSelectedShop";
import { FilterConditions, ShopStatusFilter } from "pages/ShopStatuses/ShopStatusFilter";
import { ShopStatus } from "pages/ShopStatuses/types";
import { ShopSetInput } from "types/graphql";

import { useShopStatusesGetShopsQuery, useShopStatusesUpdateShopMutation } from "./queries";
import { ShopStatusTable } from "./ShopStatusTable";

export const filterShopStatus = (
  shopStatus: ShopStatus[],
  { companyId, shopId }: FilterConditions,
) =>
  shopStatus.filter(
    (shop) =>
      (companyId === undefined || companyId === shop.companyId) &&
      (shopId === undefined || shopId === shop.shopId),
  );

export const ShopStatuses = () => {
  const { setSelectedShop } = useSelectedShop();
  const { getSearchParam, setSearchParam } = useSearchParams<"companyId" | "shopId">();
  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({
      shopId: getSearchParam("shopId"),
      companyId: getSearchParam("companyId"),
    });

  const {
    data: shopStatusesData,
    loading: loadingShopStatuses,
    refetch: refetchShopStatuses,
  } = useShopStatusesGetShopsQuery();

  const shopStatuses = useMemo(() => shopStatusesData?.shop ?? [], [shopStatusesData]);

  const [updateShopMutation, { loading: loadingUpdateShop }] = useShopStatusesUpdateShopMutation({
    onCompleted: () => refetchShopStatuses(),
  });

  const updateShop = useCallback(
    (input: { shopId: string } & ShopSetInput) => {
      const shopId = input.shopId;
      updateShopMutation({ variables: { shopId, input } });
    },
    [updateShopMutation],
  );

  const loading = loadingShopStatuses || loadingUpdateShop;

  const filteredShopStatuses = useMemo(
    () => filterShopStatus(shopStatuses, filterConditions),
    [shopStatuses, filterConditions],
  );

  const handleUpdateFilterCondition = useCallback(
    (conditions: FilterConditions) => {
      if ("companyId" in conditions) {
        setSelectedShop(null);
        updateFilterCondition({ shopId: undefined, ...conditions });
        setSearchParam("shopId", null);
        setSearchParam("companyId", conditions.companyId);
        return;
      }

      if ("shopId" in conditions) {
        setSelectedShop(conditions.shopId ?? null);
        updateFilterCondition({ companyId: undefined, ...conditions });
        setSearchParam("shopId", conditions.shopId);
        setSearchParam("companyId", null);
        return;
      }

      updateFilterCondition(conditions);
    },
    [updateFilterCondition, setSelectedShop, setSearchParam],
  );

  const handleClearFilterCondition = useCallback(() => {
    setSelectedShop(null);
    clearFilterConditions();
    setSearchParam("shopId", null);
    setSearchParam("companyId", null);
  }, [clearFilterConditions, setSelectedShop, setSearchParam]);

  return (
    <MainLayout title="店舗状況">
      <ShopStatusFilter
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={handleUpdateFilterCondition}
        clearFilterConditions={handleClearFilterCondition}
      />

      <ShopStatusTable
        shopStatuses={filteredShopStatuses}
        loading={loading}
        updateShop={updateShop}
      />
    </MainLayout>
  );
};
