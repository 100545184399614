import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PrintTargetsGetRoles = gql`
    query PrintTargetsGetRoles($shopId: uuid!) {
  role(where: {shopId: {_eq: $shopId}}, order_by: {roleId: asc}) {
    roleId
    name
  }
}
    `;
export const PrintTargetsSetPrintTargetIsPrinted = gql`
    mutation PrintTargetsSetPrintTargetIsPrinted($input: SetPrintTargetIsPrintedInput!) {
  monitorApiSetPrintTargetIsPrinted(input: $input)
}
    `;
export type PrintTargetsGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type PrintTargetsGetRolesQuery = (
  { __typename?: 'query_root' }
  & { role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'roleId' | 'name'>
  )> }
);

export type PrintTargetsSetPrintTargetIsPrintedMutationVariables = Types.Exact<{
  input: Types.SetPrintTargetIsPrintedInput;
}>;


export type PrintTargetsSetPrintTargetIsPrintedMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'monitorApiSetPrintTargetIsPrinted'>
);


export const PrintTargetsGetRolesDocument = gql`
    query PrintTargetsGetRoles($shopId: uuid!) {
  role(where: {shopId: {_eq: $shopId}}, order_by: {roleId: asc}) {
    roleId
    name
  }
}
    `;

/**
 * __usePrintTargetsGetRolesQuery__
 *
 * To run a query within a React component, call `usePrintTargetsGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintTargetsGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintTargetsGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function usePrintTargetsGetRolesQuery(baseOptions: Apollo.QueryHookOptions<PrintTargetsGetRolesQuery, PrintTargetsGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrintTargetsGetRolesQuery, PrintTargetsGetRolesQueryVariables>(PrintTargetsGetRolesDocument, options);
      }
export function usePrintTargetsGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrintTargetsGetRolesQuery, PrintTargetsGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrintTargetsGetRolesQuery, PrintTargetsGetRolesQueryVariables>(PrintTargetsGetRolesDocument, options);
        }
export type PrintTargetsGetRolesQueryHookResult = ReturnType<typeof usePrintTargetsGetRolesQuery>;
export type PrintTargetsGetRolesLazyQueryHookResult = ReturnType<typeof usePrintTargetsGetRolesLazyQuery>;
export type PrintTargetsGetRolesQueryResult = Apollo.QueryResult<PrintTargetsGetRolesQuery, PrintTargetsGetRolesQueryVariables>;
export const PrintTargetsSetPrintTargetIsPrintedDocument = gql`
    mutation PrintTargetsSetPrintTargetIsPrinted($input: SetPrintTargetIsPrintedInput!) {
  monitorApiSetPrintTargetIsPrinted(input: $input)
}
    `;
export type PrintTargetsSetPrintTargetIsPrintedMutationFn = Apollo.MutationFunction<PrintTargetsSetPrintTargetIsPrintedMutation, PrintTargetsSetPrintTargetIsPrintedMutationVariables>;

/**
 * __usePrintTargetsSetPrintTargetIsPrintedMutation__
 *
 * To run a mutation, you first call `usePrintTargetsSetPrintTargetIsPrintedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintTargetsSetPrintTargetIsPrintedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printTargetsSetPrintTargetIsPrintedMutation, { data, loading, error }] = usePrintTargetsSetPrintTargetIsPrintedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintTargetsSetPrintTargetIsPrintedMutation(baseOptions?: Apollo.MutationHookOptions<PrintTargetsSetPrintTargetIsPrintedMutation, PrintTargetsSetPrintTargetIsPrintedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintTargetsSetPrintTargetIsPrintedMutation, PrintTargetsSetPrintTargetIsPrintedMutationVariables>(PrintTargetsSetPrintTargetIsPrintedDocument, options);
      }
export type PrintTargetsSetPrintTargetIsPrintedMutationHookResult = ReturnType<typeof usePrintTargetsSetPrintTargetIsPrintedMutation>;
export type PrintTargetsSetPrintTargetIsPrintedMutationResult = Apollo.MutationResult<PrintTargetsSetPrintTargetIsPrintedMutation>;
export type PrintTargetsSetPrintTargetIsPrintedMutationOptions = Apollo.BaseMutationOptions<PrintTargetsSetPrintTargetIsPrintedMutation, PrintTargetsSetPrintTargetIsPrintedMutationVariables>;