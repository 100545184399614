import React, { memo, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { uniq } from "lodash";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { UpdateFilterConditionFunctionType, useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import { FilterConditions, OrderFilter } from "pages/Orders/OrderFilter";
import { OrderTable } from "pages/Orders/OrderTable";
import { useOrdersGetMenuOrderItemEventsQuery } from "pages/Orders/queries";
import { MenuOrderItemEvent } from "pages/Orders/types";

export const filterMenuOrderItemEvents = (
  events: MenuOrderItemEvent[],
  { tableName }: FilterConditions,
) =>
  events.filter(
    (event) =>
      tableName === undefined || event.menuOrderItem.order.tableUser.table.name === tableName,
  );

export const Orders = memo(() => {
  const { getSearchParam, setSearchParam } = useSearchParams<"tableName" | "shopId">();
  const shopId = getSearchParam("shopId");

  const threeDaysAgo = useMemo(() => dayjs().subtract(3, "days").toISOString(), []);

  const { loading, data: menuOrderItemEventsData } = useOrdersGetMenuOrderItemEventsQuery(
    shopId ? { variables: { shopId, from: threeDaysAgo } } : { skip: true },
  );

  const events = useMemo(
    () =>
      [
        ...(menuOrderItemEventsData?.menuOrderItemCreatedEvent ?? []),
        ...(menuOrderItemEventsData?.menuOrderItemQuantityDecreasedEvent ?? []),
        ...(menuOrderItemEventsData?.menuOrderItemQuantityIncreasedEvent ?? []),
      ].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    [menuOrderItemEventsData],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({
      tableName: getSearchParam("tableName"),
    });

  const filteredEvents = useMemo(
    () => filterMenuOrderItemEvents(events, filterConditions),
    [events, filterConditions],
  );

  const tableNames = useMemo(
    () =>
      uniq(
        events
          .map(({ menuOrderItem }) => menuOrderItem.order.tableUser.table.name)
          .sort((a, b) =>
            Number.isNaN(Number(a)) || Number.isNaN(Number(b))
              ? a.localeCompare(b)
              : Number(a) - Number(b),
          ),
      ),
    [events],
  );

  const handleUpdateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions> =
    useCallback(
      (filter) => {
        updateFilterCondition(filter);
        setSearchParam("tableName", filter.tableName);
      },
      [setSearchParam, updateFilterCondition],
    );

  const handleClearFilterConditions = useCallback(() => {
    setSearchParam("tableName", null);
    clearFilterConditions();
  }, [setSearchParam, clearFilterConditions]);

  return (
    <MainLayout title="注文状況">
      <ShopSelector />

      <Spacer size={16} />

      <OrderFilter
        tableNames={tableNames}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={handleUpdateFilterCondition}
        clearFilterConditions={handleClearFilterConditions}
      />
      <OrderTable loading={loading} events={filteredEvents} />
    </MainLayout>
  );
});
