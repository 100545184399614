import React, { memo } from "react";
import { Typography } from "antd";
import dayjs from "dayjs";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import {
  TableUserGroupingEvent,
  TableUserGroupingEventTableUser,
} from "pages/TableUserGroupingEvents/types";

import { TableUserGroupingEventTableUserStateEnum } from "../../../types/graphql";

const Paragraph = Typography.Paragraph;

type Props = {
  loading?: boolean;
  tableUserGroupingEvents: TableUserGroupingEvent[];
};

const StateDescription: { [key in TableUserGroupingEventTableUserStateEnum]: string } = {
  added: "作成",
  removed: "削除",
  maintained: "維持",
};

const columns = [
  {
    title: "親の卓 ID",
    width: 200,
    render(_: unknown, { billableTableUserId }: TableUserGroupingEvent) {
      return <Paragraph copyable={{ text: billableTableUserId }}>{billableTableUserId}</Paragraph>;
    },
  },
  {
    title: "親のテーブル ID",
    width: 200,
    render(
      _: unknown,
      {
        tableUser: {
          table: { tableId },
        },
      }: TableUserGroupingEvent,
    ) {
      return tableId;
    },
  },
  {
    title: "親のテーブル名",
    width: 120,
    render(
      _: string,
      {
        tableUser: {
          table: { name },
        },
      }: TableUserGroupingEvent,
    ) {
      return name;
    },
  },
  {
    title: "時刻",
    width: 120,
    render(_: string, { createdAt }: TableUserGroupingEvent) {
      const date = dayjs.utc(createdAt);
      return date.tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm");
    },
  },
];

const expandableColumns = [
  {
    title: "影響のあった卓 ID",
    width: 200,
    render(_: unknown, { tableUserId }: TableUserGroupingEventTableUser) {
      return <Paragraph copyable={{ text: tableUserId }}>{tableUserId}</Paragraph>;
    },
  },
  {
    title: "影響のあったのテーブル ID",
    width: 200,
    render(
      _: unknown,
      {
        tableUser: {
          table: { tableId },
        },
      }: TableUserGroupingEventTableUser,
    ) {
      return tableId;
    },
  },
  {
    title: "影響のあったのテーブル名",
    width: 120,
    render(
      _: string,
      {
        tableUser: {
          table: { name },
        },
      }: TableUserGroupingEventTableUser,
    ) {
      return name;
    },
  },
  {
    title: "状態",
    width: 120,
    render(_: string, { state }: TableUserGroupingEventTableUser) {
      return StateDescription[state];
    },
  },
  {
    title: "リビジョン",
    width: 120,
    render(_: string, { revision }: TableUserGroupingEventTableUser) {
      return revision;
    },
  },
];

export const TableUserGroupingEventsTable = memo<Props>(({ loading, tableUserGroupingEvents }) => {
  const [pagination, setPagination] = usePagination();

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={tableUserGroupingEvents}
      expandable={{
        rowExpandable: ({ tableUserGroupingEventTableUsers }: TableUserGroupingEvent) =>
          tableUserGroupingEventTableUsers.length > 0,
        expandedRowRender: ({ tableUserGroupingEventTableUsers }: TableUserGroupingEvent) => (
          <Table
            pagination={false}
            columns={expandableColumns}
            dataSource={tableUserGroupingEventTableUsers}
          />
        ),
      }}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => {
        setPagination(pagination);
      }}
    />
  );
});
