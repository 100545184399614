import { useMemo } from "react";

import { useAppContext } from "../../context/AppContext";
import { useShops } from "../useShops";

export const useSelectedShop = () => {
  const { selectedShopId, setSelectedShopId } = useAppContext();
  const { companies, shops, refetch } = useShops();

  const selectedShop = useMemo(
    () => shops.find((shop) => shop.shopId === selectedShopId) ?? null,
    [shops, selectedShopId],
  );

  const selectedCompany = useMemo(
    () => companies.find((company) => company.id === selectedShop?.company.id),
    [selectedShop, companies],
  );

  return {
    companies,
    shops,
    selectedCompany,
    selectedShop,
    setSelectedShop: setSelectedShopId,
    refetch,
  };
};
