import React, { memo, useCallback, useMemo } from "react";
import { Collapse } from "antd";
import dayjs from "dayjs";
import { isNotNull } from "dinii-self-js-lib/types";
import { isNotNullable, isNumber, isObject } from "util/type/primitive";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { UpdateFilterConditionFunctionType, useFilterConditions } from "hooks/useFilterConditions";
import { useSearchParams } from "hooks/useSearchParams";
import { useSelectedShop } from "hooks/useSelectedShop";
import { FilterConditions, LogFilter } from "pages/MutationLogs/LogFilter";
import { MutationLogsTable } from "pages/MutationLogs/MutationLogsTable";
import {
  useMutationLogsGetCategoriesQuery,
  useMutationLogsGetChoicesQuery,
  useMutationLogsGetMenusQuery,
  useMutationLogsGetPlanChoicesQuery,
  useMutationLogsGetPlansQuery,
  useMutationLogsGetTablesQuery,
} from "pages/MutationLogs/queries";
import { useLoggingQuery } from "pages/MutationLogs/queries.logging";

const { Panel } = Collapse;

const pickRecursively = (object: Record<string, unknown>, key: string): unknown => {
  if (key in object) return object[key];

  return Object.values(object)
    .map((value) => (isObject(value) ? pickRecursively(value, key) : undefined))
    .filter(isNotNullable)[0];
};

type EntityKey = "menuId" | "choiceId" | "planId" | "planChoiceId" | "categoryId" | "tableId";

export const MutationLogs = memo(() => {
  const { selectedShop, selectedCompany } = useSelectedShop();
  const { getSearchParam, setSearchParam } = useSearchParams<"role" | "date">();

  const role = getSearchParam("role") ?? "handy";

  const date = dayjs(getSearchParam("date") || new Date());
  const from = date.tz("Asia/Tokyo").startOf("day").toISOString();
  const to = date.tz("Asia/Tokyo").endOf("day").toISOString();

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({ role, date });

  const { loading, data, hasNextPage, fetchNextPage } = useLoggingQuery(
    selectedShop && selectedCompany && filterConditions
      ? {
          variables: {
            corporationId: selectedCompany.corporationId,
            companyId: selectedCompany.id,
            shopId: selectedShop.shopId,
            role,
            from,
            to,
          },
        }
      : { skip: true },
  );

  const handleUpdateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions> =
    useCallback(
      (filter) => {
        updateFilterCondition(filter);
        if ("role" in filter) setSearchParam("role", filter.role);
        if ("date" in filter) setSearchParam("date", filter.date?.toISOString());
      },
      [setSearchParam, updateFilterCondition],
    );

  const handleClearFilterConditions = useCallback(() => {
    setSearchParam("role", null);
    setSearchParam("date", null);
    clearFilterConditions();
  }, [setSearchParam, clearFilterConditions]);

  const logs = useMemo(
    () =>
      (data?.entries ?? [])
        .map((entry) => {
          const { "logging.googleapis.com/insertId": id, message } = entry;
          const { timestamp } = message;
          const { operation } = message.detail;
          const { query } = operation;

          if (!query.query.startsWith("mutation")) return null;

          return {
            id,
            operationName: query.operationName,
            variables: query.variables,
            timestamp: new Date(timestamp),
            ids: {
              menuId: pickRecursively(query.variables, "menuId"),
              choiceId: pickRecursively(query.variables, "choiceId"),
              planId: pickRecursively(query.variables, "planId"),
              planChoiceId: pickRecursively(query.variables, "planChoiceId"),
              categoryId: pickRecursively(query.variables, "categoryId"),
              tableId: pickRecursively(query.variables, "tableId"),
            },
          };
        })
        .filter(isNotNull),
    [data?.entries],
  );

  const pickIds = useCallback((key: EntityKey) => logs.flatMap(({ ids }) => ids[key]), [logs]);

  const menuIds = useMemo(() => pickIds("menuId").filter(isNumber), [pickIds]);
  const choiceIds = useMemo(() => pickIds("choiceId").filter(isNumber), [pickIds]);
  const planIds = useMemo(() => pickIds("planId").filter(isNumber), [pickIds]);
  const planChoiceIds = useMemo(() => pickIds("planChoiceId").filter(isNumber), [pickIds]);
  const categoryIds = useMemo(() => pickIds("categoryId").filter(isNumber), [pickIds]);
  const tableIds = useMemo(() => pickIds("tableId").filter(isNumber), [pickIds]);

  const { data: menuData } = useMutationLogsGetMenusQuery({ variables: { menuIds } });
  const menus = useMemo(() => menuData?.menu ?? [], [menuData?.menu]);

  const { data: choiceData } = useMutationLogsGetChoicesQuery({ variables: { choiceIds } });
  const choices = useMemo(() => choiceData?.choice ?? [], [choiceData?.choice]);

  const { data: planData } = useMutationLogsGetPlansQuery({ variables: { planIds } });
  const plans = useMemo(() => planData?.plan ?? [], [planData?.plan]);

  const { data: planChoiceData } = useMutationLogsGetPlanChoicesQuery({
    variables: { planChoiceIds },
  });
  const planChoices = useMemo(() => planChoiceData?.planChoice ?? [], [planChoiceData?.planChoice]);

  const { data: categoryData } = useMutationLogsGetCategoriesQuery({ variables: { categoryIds } });
  const categories = useMemo(() => categoryData?.category ?? [], [categoryData?.category]);

  const { data: tableData } = useMutationLogsGetTablesQuery({ variables: { tableIds } });
  const tables = useMemo(() => tableData?.table ?? [], [tableData?.table]);

  const rowItems = useMemo(
    () =>
      logs
        .map((log) => ({
          ...log,
          menu: {
            id: log.ids.menuId,
            name: menus.find((menu) => menu.menuId === log.ids.menuId)?.name,
          },
          choice: {
            id: log.ids.choiceId,
            name: choices.find((choice) => choice.choiceId === log.ids.choiceId)?.name,
          },
          plan: {
            id: log.ids.planId,
            name: plans.find((plan) => plan.planId === log.ids.planId)?.name,
          },
          planChoice: {
            id: log.ids.planChoiceId,
            name: planChoices.find((planChoice) => planChoice.planChoiceId === log.ids.planChoiceId)
              ?.name,
          },
          category: {
            id: log.ids.categoryId,
            name: categories.find((category) => category.categoryId === log.ids.categoryId)?.name,
          },
          table: {
            id: log.ids.tableId,
            name: tables.find((table) => table.tableId === log.ids.tableId)?.name,
          },
        }))
        .filter(isNotNull),
    [categories, choices, logs, menus, planChoices, plans, tables],
  );

  const extraColumns = [
    menuIds.length > 0 ? ("menu" as const) : null,
    choiceIds.length > 0 ? ("choice" as const) : null,
    planIds.length > 0 ? ("plan" as const) : null,
    planChoiceIds.length > 0 ? ("planChoice" as const) : null,
    categoryIds.length > 0 ? ("category" as const) : null,
    tableIds.length > 0 ? ("table" as const) : null,
  ].filter(isNotNull);

  return (
    <MainLayout title="更新ログ">
      <ShopSelector />

      <Spacer size={16} />

      <LogFilter
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={handleUpdateFilterCondition}
        clearFilterConditions={handleClearFilterConditions}
      />

      <Collapse>
        <Panel header="Cloud Logging クエリ" key="query">
          <pre>
            <code>{data?.query}</code>
          </pre>
          <a
            href={`https://console.cloud.google.com/logs/query?project=${
              import.meta.env.FIREBASE_PROJECT
            }`}
          >
            Cloud Logging
          </a>
        </Panel>
      </Collapse>
      <Spacer size={16} />

      <MutationLogsTable
        loading={loading}
        logs={rowItems}
        extraColumns={extraColumns}
        fetchMore={hasNextPage ? fetchNextPage : undefined}
      />
    </MainLayout>
  );
});
