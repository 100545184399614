import React, { memo, useCallback, useEffect } from "react";
import { Form, Input, message, Modal } from "antd";
import { collection, doc, setDoc } from "firebase/firestore";

import { firestore } from "libs/firebase";
import {
  CashRegisterSettingsFormFormValues,
  useCashRegisterSettingsForm,
} from "pages/CashRegisterStatus/CashRegisterSettingsDialog/useCashRegisterSettingsForm";

type Props = {
  shopClientId: string | null;
  settings?: Settings;
  onClose: () => void;
};

type Settings = {
  printerConnectionName?: string;
  printerConnectionTarget?: string;
  cashChangerConnectionTarget?: string;
  drawerPassword?: string;
};

export const CashRegisterSettingsDialog = memo<Props>(
  ({ shopClientId, settings: currentSettings = {}, onClose }: Props) => {
    const onSubmit = useCallback(
      async (formValues: CashRegisterSettingsFormFormValues) => {
        if (!shopClientId) return;

        try {
          await setDoc(
            doc(collection(firestore, "tabletDeviceRemoteManagement"), shopClientId),
            formValues,
            { merge: true },
          );
          message.success("保存しました");
        } catch (e) {
          console.error(e);
          message.error("保存に失敗しました");
        }
      },
      [shopClientId],
    );

    const {
      form,
      initialValues,
      submit: handleSubmit,
    } = useCashRegisterSettingsForm({
      settings: currentSettings,
      onSubmit,
    });

    useEffect(() => form.resetFields(), [form, currentSettings]);

    return (
      <Modal
        open={shopClientId !== null}
        onOk={handleSubmit}
        onCancel={onClose}
        okText="更新"
        cancelText="キャンセル"
      >
        <Form
          name="cashRegisterSettings"
          form={form}
          layout="vertical"
          initialValues={initialValues}
        >
          <Form.Item label="Printer Name" name="printerConnectionName" colon={false}>
            <Input />
          </Form.Item>
          <Form.Item label="Printer Target" name="printerConnectionTarget" colon={false}>
            <Input />
          </Form.Item>
          <Form.Item label="Cash Changer Target" name="cashChangerConnectionTarget" colon={false}>
            <Input />
          </Form.Item>
          <Form.Item label="Drawer Password" name="drawerPassword" colon={false}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
