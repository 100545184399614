import React, { memo } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";

import { useTableUserMovedEventsGetEventsQuery } from "./queries";
import { TableUserMovedEventTable } from "./TableUserMovedEventTable";

export const TableUserMovedEvents = memo(() => {
  const { getSearchParam, setSearchParam } = useSearchParams<"date" | "shopId">();
  const shopId = getSearchParam("shopId");
  const selectedDate = dayjs(getSearchParam("date") || new Date()).format("YYYY-MM-DD");

  const from = selectedDate
    ? dayjs(selectedDate).tz("Asia/Tokyo").startOf("day").toISOString()
    : "";
  const to = selectedDate
    ? dayjs(selectedDate).tz("Asia/Tokyo").clone().add(1, "days").startOf("day").toISOString()
    : "";

  const { data, loading } = useTableUserMovedEventsGetEventsQuery(
    shopId ? { variables: { shopId, from, to } } : { skip: true },
  );
  const tableUserMovedEvents = data?.tableUserMovedEvent ?? [];

  return (
    <MainLayout title="卓移動履歴">
      <ShopSelector />

      <Spacer size={16} />

      <DatePicker
        onChange={(_, dateString) => setSearchParam("date", dateString)}
        defaultValue={selectedDate ? dayjs(selectedDate) : undefined}
      />

      <Spacer size={16} />

      <TableUserMovedEventTable loading={loading} tableUserMovedEvents={tableUserMovedEvents} />
    </MainLayout>
  );
});
