import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TableUserStatusGetTableUsers = gql`
    query TableUserStatusGetTableUsers($shopId: uuid!, $from: timestamptz!, $until: timestamptz!) {
  tableUser(
    where: {shopId: {_eq: $shopId}, activatedAt: {_gte: $from, _lte: $until}}
    order_by: {activatedAt: desc}
    limit: 10000
  ) {
    activatedAt
    billableTableUserId
    companyId
    deactivatedAt
    id
    numPeople
    shopId
    state
    table {
      tableId
      name
    }
    tableClearReason
    taxIncludedPaidAmount
  }
}
    `;
export const TableUserStatusGetTables = gql`
    query TableUserStatusGetTables($shopId: uuid!) {
  table(where: {shopId: {_eq: $shopId}}) {
    tableId
    name
  }
}
    `;
export const TableUserStatusDeactivateTableUser = gql`
    mutation TableUserStatusDeactivateTableUser($input: DeactivateTableUserInput!) {
  monitorApiDeactivateTableUser(input: $input)
}
    `;
export type TableUserStatusGetTableUsersQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['timestamptz'];
  until: Types.Scalars['timestamptz'];
}>;


export type TableUserStatusGetTableUsersQuery = (
  { __typename?: 'query_root' }
  & { tableUser: Array<(
    { __typename?: 'tableUser' }
    & Pick<Types.TableUser, 'activatedAt' | 'billableTableUserId' | 'companyId' | 'deactivatedAt' | 'id' | 'numPeople' | 'shopId' | 'state' | 'tableClearReason' | 'taxIncludedPaidAmount'>
    & { table: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'tableId' | 'name'>
    ) }
  )> }
);

export type TableUserStatusGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type TableUserStatusGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'tableId' | 'name'>
  )> }
);

export type TableUserStatusDeactivateTableUserMutationVariables = Types.Exact<{
  input: Types.DeactivateTableUserInput;
}>;


export type TableUserStatusDeactivateTableUserMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'monitorApiDeactivateTableUser'>
);


export const TableUserStatusGetTableUsersDocument = gql`
    query TableUserStatusGetTableUsers($shopId: uuid!, $from: timestamptz!, $until: timestamptz!) {
  tableUser(
    where: {shopId: {_eq: $shopId}, activatedAt: {_gte: $from, _lte: $until}}
    order_by: {activatedAt: desc}
    limit: 10000
  ) {
    activatedAt
    billableTableUserId
    companyId
    deactivatedAt
    id
    numPeople
    shopId
    state
    table {
      tableId
      name
    }
    tableClearReason
    taxIncludedPaidAmount
  }
}
    `;

/**
 * __useTableUserStatusGetTableUsersQuery__
 *
 * To run a query within a React component, call `useTableUserStatusGetTableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserStatusGetTableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserStatusGetTableUsersQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useTableUserStatusGetTableUsersQuery(baseOptions: Apollo.QueryHookOptions<TableUserStatusGetTableUsersQuery, TableUserStatusGetTableUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserStatusGetTableUsersQuery, TableUserStatusGetTableUsersQueryVariables>(TableUserStatusGetTableUsersDocument, options);
      }
export function useTableUserStatusGetTableUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserStatusGetTableUsersQuery, TableUserStatusGetTableUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserStatusGetTableUsersQuery, TableUserStatusGetTableUsersQueryVariables>(TableUserStatusGetTableUsersDocument, options);
        }
export type TableUserStatusGetTableUsersQueryHookResult = ReturnType<typeof useTableUserStatusGetTableUsersQuery>;
export type TableUserStatusGetTableUsersLazyQueryHookResult = ReturnType<typeof useTableUserStatusGetTableUsersLazyQuery>;
export type TableUserStatusGetTableUsersQueryResult = Apollo.QueryResult<TableUserStatusGetTableUsersQuery, TableUserStatusGetTableUsersQueryVariables>;
export const TableUserStatusGetTablesDocument = gql`
    query TableUserStatusGetTables($shopId: uuid!) {
  table(where: {shopId: {_eq: $shopId}}) {
    tableId
    name
  }
}
    `;

/**
 * __useTableUserStatusGetTablesQuery__
 *
 * To run a query within a React component, call `useTableUserStatusGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserStatusGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserStatusGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useTableUserStatusGetTablesQuery(baseOptions: Apollo.QueryHookOptions<TableUserStatusGetTablesQuery, TableUserStatusGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserStatusGetTablesQuery, TableUserStatusGetTablesQueryVariables>(TableUserStatusGetTablesDocument, options);
      }
export function useTableUserStatusGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserStatusGetTablesQuery, TableUserStatusGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserStatusGetTablesQuery, TableUserStatusGetTablesQueryVariables>(TableUserStatusGetTablesDocument, options);
        }
export type TableUserStatusGetTablesQueryHookResult = ReturnType<typeof useTableUserStatusGetTablesQuery>;
export type TableUserStatusGetTablesLazyQueryHookResult = ReturnType<typeof useTableUserStatusGetTablesLazyQuery>;
export type TableUserStatusGetTablesQueryResult = Apollo.QueryResult<TableUserStatusGetTablesQuery, TableUserStatusGetTablesQueryVariables>;
export const TableUserStatusDeactivateTableUserDocument = gql`
    mutation TableUserStatusDeactivateTableUser($input: DeactivateTableUserInput!) {
  monitorApiDeactivateTableUser(input: $input)
}
    `;
export type TableUserStatusDeactivateTableUserMutationFn = Apollo.MutationFunction<TableUserStatusDeactivateTableUserMutation, TableUserStatusDeactivateTableUserMutationVariables>;

/**
 * __useTableUserStatusDeactivateTableUserMutation__
 *
 * To run a mutation, you first call `useTableUserStatusDeactivateTableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTableUserStatusDeactivateTableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tableUserStatusDeactivateTableUserMutation, { data, loading, error }] = useTableUserStatusDeactivateTableUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTableUserStatusDeactivateTableUserMutation(baseOptions?: Apollo.MutationHookOptions<TableUserStatusDeactivateTableUserMutation, TableUserStatusDeactivateTableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TableUserStatusDeactivateTableUserMutation, TableUserStatusDeactivateTableUserMutationVariables>(TableUserStatusDeactivateTableUserDocument, options);
      }
export type TableUserStatusDeactivateTableUserMutationHookResult = ReturnType<typeof useTableUserStatusDeactivateTableUserMutation>;
export type TableUserStatusDeactivateTableUserMutationResult = Apollo.MutationResult<TableUserStatusDeactivateTableUserMutation>;
export type TableUserStatusDeactivateTableUserMutationOptions = Apollo.BaseMutationOptions<TableUserStatusDeactivateTableUserMutation, TableUserStatusDeactivateTableUserMutationVariables>;