import React, { memo } from "react";

import { EmergencyPrintBackup } from "components/EmergencyPrintBackup";
import { EmergencyPrintBackupButton } from "components/EmergencyPrintBackupButton";
import { Spacer } from "components/Spacer";
import { useEmergencyPrintBackup } from "hooks/useEmergencyPrintBackup";

import { BaseLayout, BaseLayoutProps } from "../BaseLayout";

type Props = {
  withoutEmergencyRoleIdReplacement?: boolean;
} & Omit<BaseLayoutProps, "headerExtra">;

export const MainLayout = memo<Props>(
  ({ title, breadcrumb, onBack, withoutEmergencyRoleIdReplacement, children }) => {
    const { emergencyRoleIdReplacement, roles, refetch } = useEmergencyPrintBackup();

    return (
      <BaseLayout
        title={title}
        breadcrumb={breadcrumb}
        onBack={onBack}
        headerExtra={
          withoutEmergencyRoleIdReplacement ? undefined : (
            <EmergencyPrintBackupButton roles={roles} refetch={refetch} />
          )
        }
      >
        {withoutEmergencyRoleIdReplacement ? null : (
          <>
            <EmergencyPrintBackup
              emergencyRoleIdReplacement={emergencyRoleIdReplacement}
              roles={roles}
              refetch={refetch}
            />
            <Spacer size={16} />
          </>
        )}
        {children}
      </BaseLayout>
    );
  },
);
