import React, { memo } from "react";
import styled from "styled-components";

import { gray } from "constants/colors";
import { Slip } from "pages/PrintTargets/PrintTargetTable/Slip";

type Props = {
  numberOfPeople: number;
  tableName: string;
  orders: {
    name: string;
    description: string;
    quantity?: number;
    price?: number;
    remark: string;
    choices: {
      name: string;
      quantity?: number;
      price?: number;
    }[];
  }[];
  totalPrice: number;
  orderedAt: string;
  ordererName: string;
};

const Container = styled(Slip)``;

const DishUpSlipContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  padding: 8px 0;
  border-top: 1px dashed ${gray[6]};
  border-bottom: 1px dashed ${gray[6]};
`;

const DishUpSlipItem = styled.div`
  display: flex;
`;

const DishUpSlipItemName = styled.div`
  width: 70%;
`;

const DishUpSlipItemQuantity = styled.div`
  width: 10%;
  text-align: right;
`;

const DishUpSlipItemPrice = styled.div`
  width: 20%;
  text-align: right;
`;

const DishUpSlipItemChoiceName = styled.div`
  width: 70%;
  font-size: 12px;
`;

const DishUpSlipItemChoice = styled.div`
  display: flex;
`;

const DishUpSlipItemChoiceQuantity = styled.div`
  width: 10%;
  font-size: 12px;
  text-align: right;
`;

const DishUpSlipItemChoicePrice = styled.div`
  width: 20%;
  font-size: 12px;
  text-align: right;
`;

const DishUpSlipItemRemark = styled.div`
  font-size: 12px;
`;

const NumPeople = styled.div`
  text-decoration: underline;
  text-underline-position: under;
`;

const TableName = styled.div`
  text-decoration: underline;
  text-underline-position: under;
  text-align: right;
`;

const PaidAmount = styled.div`
  text-align: right;
`;

const OrderedAt = styled.div`
  font-size: 10px;
`;

const OrdererName = styled.div`
  font-size: 10px;
`;

export const DishUpSlip = memo<Props>(
  ({ numberOfPeople, tableName, orders, totalPrice, orderedAt, ordererName }) => (
    <Container>
      <NumPeople>客数: {numberOfPeople}</NumPeople>
      <TableName>卓番: {tableName}</TableName>
      <DishUpSlipContent>
        {orders.map(({ name, quantity = "", price = "", remark, choices = [] }) => (
          <>
            <DishUpSlipItem key={name}>
              <DishUpSlipItemName>{name}</DishUpSlipItemName>
              <DishUpSlipItemQuantity>{quantity}</DishUpSlipItemQuantity>
              <DishUpSlipItemPrice>{price}</DishUpSlipItemPrice>
            </DishUpSlipItem>
            {choices.map(({ name, quantity, price }) => (
              <DishUpSlipItemChoice key={name}>
                <DishUpSlipItemChoiceName>{name}</DishUpSlipItemChoiceName>
                <DishUpSlipItemChoiceQuantity>{quantity}</DishUpSlipItemChoiceQuantity>
                <DishUpSlipItemChoicePrice>{price}</DishUpSlipItemChoicePrice>
              </DishUpSlipItemChoice>
            ))}
            {remark ? <DishUpSlipItemRemark>※ {remark}</DishUpSlipItemRemark> : null}
          </>
        ))}
      </DishUpSlipContent>
      <PaidAmount>合計 {totalPrice}</PaidAmount>
      <OrderedAt>{orderedAt}</OrderedAt>
      <OrdererName>{ordererName}</OrdererName>
    </Container>
  ),
);
