import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useShops } from "hooks/useShops";

export type FilterConditions = {
  companyId?: string;
  shopId?: string;
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  align-items: center;
  margin-bottom: 16px;
`;

type Props = {
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const ShopStatusFilter = memo<Props>(
  ({ hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions }) => {
    const { companies, shops } = useShops();

    return (
      <Wrapper>
        <Col flex="1 0">
          <Select<string>
            placeholder="業態"
            optionFilterProp="label"
            options={companies.map(({ id, name }) => ({ label: name, value: id }))}
            value={companies.length ? filterConditions.companyId : null}
            onChange={(companyId) => updateFilterCondition({ companyId })}
            showSearch
            allowClear
          />
        </Col>
        <Col flex="1 0">
          <Select<string>
            placeholder="店舗"
            optionFilterProp="label"
            options={shops.map(({ shopId, name }) => ({ label: name, value: shopId }))}
            value={shops.length ? filterConditions.shopId : null}
            onChange={(shopId) => updateFilterCondition({ shopId })}
            showSearch
            allowClear
          />
        </Col>
        <Col flex="none">
          <Tooltip title="フィルター条件をクリア">
            <Button
              shape="circle"
              icon={<ClearOutlined />}
              disabled={hasFilterConditions}
              onClick={clearFilterConditions}
            />
          </Tooltip>
        </Col>
      </Wrapper>
    );
  },
);
