import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MutationLogsGetMenus = gql`
    query MutationLogsGetMenus($menuIds: [Int!]!) {
  menu(where: {menuId: {_in: $menuIds}}) {
    menuId
    name
  }
}
    `;
export const MutationLogsGetChoices = gql`
    query MutationLogsGetChoices($choiceIds: [Int!]!) {
  choice(where: {choiceId: {_in: $choiceIds}}) {
    choiceId
    name
  }
}
    `;
export const MutationLogsGetPlans = gql`
    query MutationLogsGetPlans($planIds: [Int!]!) {
  plan(where: {planId: {_in: $planIds}}) {
    planId
    name: planName
  }
}
    `;
export const MutationLogsGetPlanChoices = gql`
    query MutationLogsGetPlanChoices($planChoiceIds: [Int!]!) {
  planChoice(where: {planChoiceId: {_in: $planChoiceIds}}) {
    planChoiceId
    name
  }
}
    `;
export const MutationLogsGetCategories = gql`
    query MutationLogsGetCategories($categoryIds: [Int!]!) {
  category(where: {categoryId: {_in: $categoryIds}}) {
    categoryId
    name
  }
}
    `;
export const MutationLogsGetTables = gql`
    query MutationLogsGetTables($tableIds: [Int!]!) {
  table(where: {tableId: {_in: $tableIds}}) {
    tableId
    name
  }
}
    `;
export type MutationLogsGetMenusQueryVariables = Types.Exact<{
  menuIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type MutationLogsGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'menuId' | 'name'>
  )> }
);

export type MutationLogsGetChoicesQueryVariables = Types.Exact<{
  choiceIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type MutationLogsGetChoicesQuery = (
  { __typename?: 'query_root' }
  & { choice: Array<(
    { __typename?: 'choice' }
    & Pick<Types.Choice, 'choiceId' | 'name'>
  )> }
);

export type MutationLogsGetPlansQueryVariables = Types.Exact<{
  planIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type MutationLogsGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId'>
    & { name: Types.Plan['planName'] }
  )> }
);

export type MutationLogsGetPlanChoicesQueryVariables = Types.Exact<{
  planChoiceIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type MutationLogsGetPlanChoicesQuery = (
  { __typename?: 'query_root' }
  & { planChoice: Array<(
    { __typename?: 'planChoice' }
    & Pick<Types.PlanChoice, 'planChoiceId' | 'name'>
  )> }
);

export type MutationLogsGetCategoriesQueryVariables = Types.Exact<{
  categoryIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type MutationLogsGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name'>
  )> }
);

export type MutationLogsGetTablesQueryVariables = Types.Exact<{
  tableIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type MutationLogsGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'tableId' | 'name'>
  )> }
);


export const MutationLogsGetMenusDocument = gql`
    query MutationLogsGetMenus($menuIds: [Int!]!) {
  menu(where: {menuId: {_in: $menuIds}}) {
    menuId
    name
  }
}
    `;

/**
 * __useMutationLogsGetMenusQuery__
 *
 * To run a query within a React component, call `useMutationLogsGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutationLogsGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutationLogsGetMenusQuery({
 *   variables: {
 *      menuIds: // value for 'menuIds'
 *   },
 * });
 */
export function useMutationLogsGetMenusQuery(baseOptions: Apollo.QueryHookOptions<MutationLogsGetMenusQuery, MutationLogsGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutationLogsGetMenusQuery, MutationLogsGetMenusQueryVariables>(MutationLogsGetMenusDocument, options);
      }
export function useMutationLogsGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutationLogsGetMenusQuery, MutationLogsGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutationLogsGetMenusQuery, MutationLogsGetMenusQueryVariables>(MutationLogsGetMenusDocument, options);
        }
export type MutationLogsGetMenusQueryHookResult = ReturnType<typeof useMutationLogsGetMenusQuery>;
export type MutationLogsGetMenusLazyQueryHookResult = ReturnType<typeof useMutationLogsGetMenusLazyQuery>;
export type MutationLogsGetMenusQueryResult = Apollo.QueryResult<MutationLogsGetMenusQuery, MutationLogsGetMenusQueryVariables>;
export const MutationLogsGetChoicesDocument = gql`
    query MutationLogsGetChoices($choiceIds: [Int!]!) {
  choice(where: {choiceId: {_in: $choiceIds}}) {
    choiceId
    name
  }
}
    `;

/**
 * __useMutationLogsGetChoicesQuery__
 *
 * To run a query within a React component, call `useMutationLogsGetChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutationLogsGetChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutationLogsGetChoicesQuery({
 *   variables: {
 *      choiceIds: // value for 'choiceIds'
 *   },
 * });
 */
export function useMutationLogsGetChoicesQuery(baseOptions: Apollo.QueryHookOptions<MutationLogsGetChoicesQuery, MutationLogsGetChoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutationLogsGetChoicesQuery, MutationLogsGetChoicesQueryVariables>(MutationLogsGetChoicesDocument, options);
      }
export function useMutationLogsGetChoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutationLogsGetChoicesQuery, MutationLogsGetChoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutationLogsGetChoicesQuery, MutationLogsGetChoicesQueryVariables>(MutationLogsGetChoicesDocument, options);
        }
export type MutationLogsGetChoicesQueryHookResult = ReturnType<typeof useMutationLogsGetChoicesQuery>;
export type MutationLogsGetChoicesLazyQueryHookResult = ReturnType<typeof useMutationLogsGetChoicesLazyQuery>;
export type MutationLogsGetChoicesQueryResult = Apollo.QueryResult<MutationLogsGetChoicesQuery, MutationLogsGetChoicesQueryVariables>;
export const MutationLogsGetPlansDocument = gql`
    query MutationLogsGetPlans($planIds: [Int!]!) {
  plan(where: {planId: {_in: $planIds}}) {
    planId
    name: planName
  }
}
    `;

/**
 * __useMutationLogsGetPlansQuery__
 *
 * To run a query within a React component, call `useMutationLogsGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutationLogsGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutationLogsGetPlansQuery({
 *   variables: {
 *      planIds: // value for 'planIds'
 *   },
 * });
 */
export function useMutationLogsGetPlansQuery(baseOptions: Apollo.QueryHookOptions<MutationLogsGetPlansQuery, MutationLogsGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutationLogsGetPlansQuery, MutationLogsGetPlansQueryVariables>(MutationLogsGetPlansDocument, options);
      }
export function useMutationLogsGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutationLogsGetPlansQuery, MutationLogsGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutationLogsGetPlansQuery, MutationLogsGetPlansQueryVariables>(MutationLogsGetPlansDocument, options);
        }
export type MutationLogsGetPlansQueryHookResult = ReturnType<typeof useMutationLogsGetPlansQuery>;
export type MutationLogsGetPlansLazyQueryHookResult = ReturnType<typeof useMutationLogsGetPlansLazyQuery>;
export type MutationLogsGetPlansQueryResult = Apollo.QueryResult<MutationLogsGetPlansQuery, MutationLogsGetPlansQueryVariables>;
export const MutationLogsGetPlanChoicesDocument = gql`
    query MutationLogsGetPlanChoices($planChoiceIds: [Int!]!) {
  planChoice(where: {planChoiceId: {_in: $planChoiceIds}}) {
    planChoiceId
    name
  }
}
    `;

/**
 * __useMutationLogsGetPlanChoicesQuery__
 *
 * To run a query within a React component, call `useMutationLogsGetPlanChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutationLogsGetPlanChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutationLogsGetPlanChoicesQuery({
 *   variables: {
 *      planChoiceIds: // value for 'planChoiceIds'
 *   },
 * });
 */
export function useMutationLogsGetPlanChoicesQuery(baseOptions: Apollo.QueryHookOptions<MutationLogsGetPlanChoicesQuery, MutationLogsGetPlanChoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutationLogsGetPlanChoicesQuery, MutationLogsGetPlanChoicesQueryVariables>(MutationLogsGetPlanChoicesDocument, options);
      }
export function useMutationLogsGetPlanChoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutationLogsGetPlanChoicesQuery, MutationLogsGetPlanChoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutationLogsGetPlanChoicesQuery, MutationLogsGetPlanChoicesQueryVariables>(MutationLogsGetPlanChoicesDocument, options);
        }
export type MutationLogsGetPlanChoicesQueryHookResult = ReturnType<typeof useMutationLogsGetPlanChoicesQuery>;
export type MutationLogsGetPlanChoicesLazyQueryHookResult = ReturnType<typeof useMutationLogsGetPlanChoicesLazyQuery>;
export type MutationLogsGetPlanChoicesQueryResult = Apollo.QueryResult<MutationLogsGetPlanChoicesQuery, MutationLogsGetPlanChoicesQueryVariables>;
export const MutationLogsGetCategoriesDocument = gql`
    query MutationLogsGetCategories($categoryIds: [Int!]!) {
  category(where: {categoryId: {_in: $categoryIds}}) {
    categoryId
    name
  }
}
    `;

/**
 * __useMutationLogsGetCategoriesQuery__
 *
 * To run a query within a React component, call `useMutationLogsGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutationLogsGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutationLogsGetCategoriesQuery({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useMutationLogsGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<MutationLogsGetCategoriesQuery, MutationLogsGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutationLogsGetCategoriesQuery, MutationLogsGetCategoriesQueryVariables>(MutationLogsGetCategoriesDocument, options);
      }
export function useMutationLogsGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutationLogsGetCategoriesQuery, MutationLogsGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutationLogsGetCategoriesQuery, MutationLogsGetCategoriesQueryVariables>(MutationLogsGetCategoriesDocument, options);
        }
export type MutationLogsGetCategoriesQueryHookResult = ReturnType<typeof useMutationLogsGetCategoriesQuery>;
export type MutationLogsGetCategoriesLazyQueryHookResult = ReturnType<typeof useMutationLogsGetCategoriesLazyQuery>;
export type MutationLogsGetCategoriesQueryResult = Apollo.QueryResult<MutationLogsGetCategoriesQuery, MutationLogsGetCategoriesQueryVariables>;
export const MutationLogsGetTablesDocument = gql`
    query MutationLogsGetTables($tableIds: [Int!]!) {
  table(where: {tableId: {_in: $tableIds}}) {
    tableId
    name
  }
}
    `;

/**
 * __useMutationLogsGetTablesQuery__
 *
 * To run a query within a React component, call `useMutationLogsGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMutationLogsGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutationLogsGetTablesQuery({
 *   variables: {
 *      tableIds: // value for 'tableIds'
 *   },
 * });
 */
export function useMutationLogsGetTablesQuery(baseOptions: Apollo.QueryHookOptions<MutationLogsGetTablesQuery, MutationLogsGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MutationLogsGetTablesQuery, MutationLogsGetTablesQueryVariables>(MutationLogsGetTablesDocument, options);
      }
export function useMutationLogsGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MutationLogsGetTablesQuery, MutationLogsGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MutationLogsGetTablesQuery, MutationLogsGetTablesQueryVariables>(MutationLogsGetTablesDocument, options);
        }
export type MutationLogsGetTablesQueryHookResult = ReturnType<typeof useMutationLogsGetTablesQuery>;
export type MutationLogsGetTablesLazyQueryHookResult = ReturnType<typeof useMutationLogsGetTablesLazyQuery>;
export type MutationLogsGetTablesQueryResult = Apollo.QueryResult<MutationLogsGetTablesQuery, MutationLogsGetTablesQueryVariables>;