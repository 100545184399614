import React from "react";
import { Route, Routes } from "react-router-dom";

import { CashRegisterStatus } from "pages/CashRegisterStatus";
import { DeviceMonitoring } from "pages/DeviceMonitoring";
import { HandyStatus } from "pages/HandyStatus";
import { MutationLogs } from "pages/MutationLogs";
import { AddNotification } from "pages/Notifications/AddNotification";
import { EditNotification } from "pages/Notifications/EditNotification";
import { NotificationList } from "pages/Notifications/NotificationList";
import { OfflineFeatureUsage } from "pages/OfflineFeatureUsage";
import { Orders } from "pages/Orders";
import { PrinterSetup } from "pages/PrinterSetup";
import { PrintTargets } from "pages/PrintTargets";
import { ServiceWideConfig } from "pages/ServiceWideConfig";
import { ShopClients } from "pages/ShopClients";
import { ShopStatuses } from "pages/ShopStatuses";
import { SignIn } from "pages/SignIn";
import { TableUserDetail } from "pages/TableUserDetail";
import { TableUserGroupingEvents } from "pages/TableUserGroupingEvents";
import { TableUserMovedEvents } from "pages/TableUserMovedEvents";
import { TableUserStatus } from "pages/TableUserStatus";

export type RouteName = keyof typeof routes;

export const routes = {
  deviceMonitoring: {
    path: "/",
    Component: DeviceMonitoring,
  },
  cashRegisterStatus: {
    path: "/cash-register-status",
    Component: CashRegisterStatus,
  },
  handyStatus: {
    path: "/handy-status",
    Component: HandyStatus,
  },
  shopStatuses: {
    path: "/shop-status",
    Component: ShopStatuses,
  },
  shopClients: {
    path: "/shop-clients",
    Component: ShopClients,
  },
  tableUsers: {
    path: "/table-users",
    Component: TableUserStatus,
  },
  tableUserGroupingEvents: {
    path: "/table-user-grouping-events",
    Component: TableUserGroupingEvents,
  },
  tableUserDetail: {
    path: "/table-users/:id",
    Component: TableUserDetail,
  },
  orders: {
    path: "/orders",
    Component: Orders,
  },
  printTargets: {
    path: "/print-targets",
    Component: PrintTargets,
  },
  signIn: {
    path: "/sign-in",
    Component: SignIn,
  },
  tableUserMovedEvents: {
    path: "/table-user-moved-events",
    Component: TableUserMovedEvents,
  },
  notifications: {
    path: "/notifications",
    Component: NotificationList,
    exact: true,
  },
  addNotification: {
    path: "/notifications/add",
    Component: AddNotification,
    exact: true,
  },
  editNotification: {
    path: "/notifications/:id/edit",
    Component: EditNotification,
    exact: true,
  },
  mutationLogs: {
    path: "/mutation-logs",
    Component: MutationLogs,
    exact: true,
  },
  printerSetup: {
    path: "/printer-setup",
    Component: PrinterSetup,
    exact: true,
  },
  offlineFeatureUsage: {
    path: "/offline-feature-usage",
    Component: OfflineFeatureUsage,
    exact: true,
  },
  serviceWideConfig: {
    path: "/service-wide-config",
    Component: ServiceWideConfig,
    exact: true,
  },
};

const routeEntries = Object.entries(routes)
  .sort(([, a], [, b]) => b.path.length - a.path.length)
  .map(([name, props]) => [name as RouteName, props] as const);

export const Router = () => (
  <Routes>
    {routeEntries.map(([name, { path, Component }]) => (
      <Route key={name} path={path} element={<Component />} />
    ))}
  </Routes>
);
