import { useCallback } from "react";
import { Form } from "antd";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import {
  isNotificationTargetCompanyKey,
  isNotificationTargetShopKey,
  NotificationCategory,
  NotificationChannelType,
  NotificationSettings,
  NotificationTargetCompanyKey,
  NotificationTargetShopKey,
} from "models/notificationSettings";
import { isNotNullable } from "util/type/primitive";
import * as uuid from "uuid";

import { Result } from "types/result";

export type NotificationSettingsFormValues = {
  title?: string;
  isImportant?: boolean;
  message?: string;
  category?: NotificationCategory;
  deliveryType: "broadcast" | "narrowcast";
  companyKeys: NotificationTargetCompanyKey[];
  shopKeys: NotificationTargetShopKey[];
  channels: NotificationChannelType[];
  availableTerm: [dayjs.Dayjs | undefined, dayjs.Dayjs | undefined];
};

type NotificationSettingsFormData = Omit<NotificationSettings, "id" | "updatedAt" | "published">;

export type SubmitMode = "saveDraft" | "publish" | "close";

export type SubmitOptions = {
  mode: SubmitMode;
  confirm?: (triggerSubmit: () => void) => void;
};

const getInitialValues = ({
  title,
  labels,
  message,
  targets = ["broadcast"],
  channels = ["handy", "cashRegister", "dashboard"],
  category,
  availableFrom,
  availableUntil,
}: Partial<NotificationSettingsFormData>): NotificationSettingsFormValues => ({
  title,
  isImportant: labels?.includes("important") || false,
  message,
  deliveryType: targets.includes("broadcast") ? "broadcast" : "narrowcast",
  companyKeys: targets.filter(isNotificationTargetCompanyKey),
  shopKeys: targets.filter(isNotificationTargetShopKey),
  channels,
  category,
  availableTerm: [
    availableFrom && dayjs(availableFrom.toDate()),
    availableUntil && dayjs(availableUntil.toDate()),
  ],
});

export const useNotificationSettingsForm = ({
  settings,
  onSubmit,
  onError,
}: {
  settings: Partial<NotificationSettings>;
  onSubmit: ({ mode, settings }: { mode: SubmitMode; settings: NotificationSettings }) => void;
  onError: (errors: Error) => void;
}) => {
  const [form] = Form.useForm<NotificationSettingsFormValues>();
  const initialValues = getInitialValues(settings);

  const createData = useCallback((): Result<NotificationSettingsFormData> => {
    const {
      title,
      isImportant,
      message,
      deliveryType,
      companyKeys,
      shopKeys,
      channels,
      category,
      availableTerm: [availableFrom, availableUntil],
    } = form.getFieldsValue() as NotificationSettingsFormValues;

    const isTitleEmpty = !title;
    const isMessageEmpty = !message;
    const isCategoryEmpty = !category;
    const isDeliveryTypeInvalid = !["broadcast", "narrowcast"].includes(deliveryType);
    const isDeliveryTargetEmpty =
      deliveryType === "narrowcast" && !companyKeys.length && !shopKeys.length;
    const isChannelEmpty = !channels.length;
    const isStartDateEmpty = !availableFrom;
    const isEndDateEmpty = !availableUntil;
    if (
      isTitleEmpty ||
      isMessageEmpty ||
      isCategoryEmpty ||
      isDeliveryTypeInvalid ||
      isDeliveryTargetEmpty ||
      isChannelEmpty ||
      isStartDateEmpty ||
      isEndDateEmpty
    ) {
      return { error: new Error("Incomplete Form Values") };
    }
    return {
      data: {
        title,
        labels: [isImportant ? ("important" as const) : null].filter(isNotNullable),
        message,
        category,
        targets: deliveryType === "broadcast" ? ["broadcast"] : [...companyKeys, ...shopKeys],
        channels,
        availableFrom: Timestamp.fromDate(availableFrom.toDate()),
        availableUntil: Timestamp.fromDate(availableUntil.toDate()),
      },
    };
  }, [form]);

  const submit = useCallback(
    ({ mode, confirm }: SubmitOptions) => {
      const result = createData();
      if (result.data) {
        const triggerSubmit = () => {
          onSubmit({
            mode,
            settings: {
              ...result.data,
              id: settings.id || uuid.v4(),
              availableUntil: mode === "close" ? Timestamp.now() : result.data.availableUntil,
              updatedAt: Timestamp.now(),
              published: settings.published || mode === "publish" || mode === "close",
            },
          });
        };
        confirm ? confirm(triggerSubmit) : triggerSubmit();
      } else {
        onError(result.error);
      }
    },
    [onError, onSubmit, settings.id, settings.published, createData],
  );

  return { form, initialValues, submit, validateValues: createData };
};
