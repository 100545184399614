import * as Types from '../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const RemoveShopClient = gql`
    mutation RemoveShopClient($input: RemoveShopClientInput!) {
  removeShopClient(input: $input) {
    result
  }
}
    `;
export type RemoveShopClientMutationVariables = Types.Exact<{
  input: Types.RemoveShopClientInput;
}>;


export type RemoveShopClientMutation = (
  { __typename?: 'mutation_root' }
  & { removeShopClient: (
    { __typename?: 'RemoveShopClientResult' }
    & Pick<Types.RemoveShopClientResult, 'result'>
  ) }
);


export const RemoveShopClientDocument = gql`
    mutation RemoveShopClient($input: RemoveShopClientInput!) {
  removeShopClient(input: $input) {
    result
  }
}
    `;
export type RemoveShopClientMutationFn = Apollo.MutationFunction<RemoveShopClientMutation, RemoveShopClientMutationVariables>;

/**
 * __useRemoveShopClientMutation__
 *
 * To run a mutation, you first call `useRemoveShopClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveShopClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeShopClientMutation, { data, loading, error }] = useRemoveShopClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveShopClientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveShopClientMutation, RemoveShopClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveShopClientMutation, RemoveShopClientMutationVariables>(RemoveShopClientDocument, options);
      }
export type RemoveShopClientMutationHookResult = ReturnType<typeof useRemoveShopClientMutation>;
export type RemoveShopClientMutationResult = Apollo.MutationResult<RemoveShopClientMutation>;
export type RemoveShopClientMutationOptions = Apollo.BaseMutationOptions<RemoveShopClientMutation, RemoveShopClientMutationVariables>;