import React, { memo, useMemo } from "react";
import { Form, FormItemProps, Select } from "antd";
import { createNotificationTargetCompanyKey } from "models/notificationSettings";

import { Company } from "hooks/useShops/types";

type Props = {
  loading: boolean;
  disabled: boolean;
  companies: Company[];
} & Omit<FormItemProps, "name" | "label" | "children">;

export const CompanyKeysField = memo<Props>(({ loading, disabled, companies, ...props }) => {
  const companyOptions = useMemo(
    () =>
      companies.map(({ id, name }) => ({
        key: id,
        label: name,
        value: createNotificationTargetCompanyKey(id),
      })),
    [companies],
  );

  return (
    <Form.Item label="業態単位" name="companyKeys" {...props}>
      <Select
        mode="multiple"
        loading={loading}
        disabled={disabled}
        placeholder="選択してください"
        optionFilterProp="label"
        options={companyOptions}
      />
    </Form.Item>
  );
});
