import React, { memo, useMemo } from "react";
import { List, Modal } from "antd";

import { CheckInEventCustomer } from "../types";

import { CustomerCheckInEventTable } from "./CustomerCheckInEventTable";

type Props = {
  customer?: CheckInEventCustomer;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomerCheckInHistoryDialog = memo<Props>(({ customer, isOpen, onClose }) => {
  const checkInEvents = useMemo(() => customer?.checkInEvents ?? [], [customer?.checkInEvents]);

  return (
    <Modal
      title="ユーザーのチェックイン履歴（直近 10 件）"
      open={isOpen}
      onCancel={onClose}
      width={800}
    >
      <List>
        <List.Item>
          <List.Item.Meta title="ユーザー ID" description={customer?.id} />
        </List.Item>
      </List>
      <CustomerCheckInEventTable checkInEvents={checkInEvents} />
    </Modal>
  );
});
