import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Button } from "antd";
import dayjs from "dayjs";
import { isNotNull } from "util/type/primitive";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

const Code = styled.code`
  display: block;
  max-width: 600px;
`;

type Log = {
  id: string;
  operationName: string;
  variables: Record<string, unknown>;
  timestamp: Date;
  menu: { id: unknown; name?: string };
  choice: { id: unknown; name?: string };
  plan: { id: unknown; name?: string };
  planChoice: { id: unknown; name?: string };
  category: { id: unknown; name?: string };
  table: { id: unknown; name?: string };
};

type Props = {
  loading: boolean;
  logs: Log[];
  extraColumns: ("menu" | "choice" | "plan" | "planChoice" | "category" | "table")[];
  fetchMore?: () => void;
};

export const MutationLogsTable = memo<Props>(({ loading, logs, extraColumns, fetchMore }) => {
  const [pagination, setPagination] = usePagination();

  const columns = useMemo(
    () =>
      [
        { title: "操作名", dataIndex: "operationName" },
        extraColumns.includes("menu")
          ? {
              title: "メニュー",
              render(_: unknown, { menu }: Log) {
                return menu.name ? `${menu.name} (${menu.id})` : null;
              },
            }
          : null,
        extraColumns.includes("choice")
          ? {
              title: "メニュー",
              render(_: unknown, { choice }: Log) {
                return choice.name ? `${choice.name} (${choice.id})` : null;
              },
            }
          : null,
        extraColumns.includes("plan")
          ? {
              title: "プラン",
              render(_: unknown, { plan }: Log) {
                return plan.name ? `${plan.name} (${plan.id})` : null;
              },
            }
          : null,
        extraColumns.includes("planChoice")
          ? {
              title: "プランチョイス",
              render(_: unknown, { planChoice }: Log) {
                return planChoice.name ? `${planChoice.name} (${planChoice.id})` : null;
              },
            }
          : null,
        extraColumns.includes("category")
          ? {
              title: "カテゴリー",
              render(_: unknown, { category }: Log) {
                return category.name ? `${category.name} (${category.id})` : null;
              },
            }
          : null,
        extraColumns.includes("table")
          ? {
              title: "テーブル",
              render(_: unknown, { table }: Log) {
                return table.name ? `${table.name} (${table.id})` : null;
              },
            }
          : null,
        {
          title: "変数",
          render(_: unknown, { variables }: Log) {
            return <Code>{JSON.stringify(variables)}</Code>;
          },
        },
        {
          title: "日時",
          render(_: unknown, { timestamp }: Log) {
            return dayjs(timestamp).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss");
          },
        },
      ].filter(isNotNull),
    [extraColumns],
  );

  const isLastPage =
    Math.ceil(logs.length / pagination.defaultPageSize) === pagination.defaultCurrent;

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={logs}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      footer={
        isLastPage && fetchMore ? () => <Button onClick={fetchMore}>もっと見る</Button> : undefined
      }
    />
  );
});
