import { useCallback, useMemo } from "react";

import { useLocationState } from "hooks/useLocationState";

import type { Transformers } from "./types";

export type UpdateFilterConditionFunctionType<T extends Record<string, unknown>> = (
  conditions: Partial<T>,
) => void;

export const convertStringToBooleanOrUndefined = (string: string) =>
  string === "true" ? true : string === "false" ? false : undefined;

export const useFilterConditions = <T extends Record<string, unknown>, U = Partial<T>>(
  initialState: T,
  key = "filterConditions",
  transformers?: Transformers<Partial<T>, U>,
) => {
  const [filterConditions, setFilterConditions] = useLocationState<Partial<T>, U>(
    key,
    initialState,
    transformers,
  );

  const hasFilterConditions = useMemo(
    () => Object.keys(filterConditions).length === 0,
    [filterConditions],
  );

  const updateFilterCondition: UpdateFilterConditionFunctionType<T> = useCallback(
    (conditions: Partial<T>) =>
      setFilterConditions(
        Object.entries(conditions).reduce((acc, [key, value]) => {
          if (
            (Array.isArray(value) && value.length === 0) ||
            value === null ||
            value === undefined
          ) {
            const { [key]: _, ...newConditions } = filterConditions;

            return newConditions as Partial<T>;
          }

          return { ...acc, [key]: value } as Partial<T>;
        }, filterConditions),
      ),
    [setFilterConditions, filterConditions],
  );

  const clearFilterConditions = useCallback(
    () => setFilterConditions(initialState),
    [initialState, setFilterConditions],
  );

  return {
    filterConditions,
    hasFilterConditions,
    updateFilterCondition,
    clearFilterConditions,
    setFilterConditions,
  };
};
