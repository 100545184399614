import * as Types from '../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SignInSignInToMonitor = gql`
    mutation SignInSignInToMonitor {
  signInToMonitor
}
    `;
export type SignInSignInToMonitorMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SignInSignInToMonitorMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'signInToMonitor'>
);


export const SignInSignInToMonitorDocument = gql`
    mutation SignInSignInToMonitor {
  signInToMonitor
}
    `;
export type SignInSignInToMonitorMutationFn = Apollo.MutationFunction<SignInSignInToMonitorMutation, SignInSignInToMonitorMutationVariables>;

/**
 * __useSignInSignInToMonitorMutation__
 *
 * To run a mutation, you first call `useSignInSignInToMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInSignInToMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInSignInToMonitorMutation, { data, loading, error }] = useSignInSignInToMonitorMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignInSignInToMonitorMutation(baseOptions?: Apollo.MutationHookOptions<SignInSignInToMonitorMutation, SignInSignInToMonitorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInSignInToMonitorMutation, SignInSignInToMonitorMutationVariables>(SignInSignInToMonitorDocument, options);
      }
export type SignInSignInToMonitorMutationHookResult = ReturnType<typeof useSignInSignInToMonitorMutation>;
export type SignInSignInToMonitorMutationResult = Apollo.MutationResult<SignInSignInToMonitorMutation>;
export type SignInSignInToMonitorMutationOptions = Apollo.BaseMutationOptions<SignInSignInToMonitorMutation, SignInSignInToMonitorMutationVariables>;