import { useCallback, useState } from "react";
import { useAsync } from "react-use";
import { message } from "antd";
import { collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";

import { firestore } from "libs/firebase";

export type PrinterControllerSettings = {
  roleId: number;
  roleName: string;
  macAddress: string;
  target: string;
  deviceName: string;
  deviceType: number;
  bdAddress: string;
  ipAddress: string;
};

export const usePrinterControllerSettings = ({ shopClientIds }: { shopClientIds: string[] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [printerControllerSettings, setPrinterControllerSettings] = useState<
    Record<string, PrinterControllerSettings[]>
  >({});
  const [updatedAt, setUpdatedAt] = useState(Date.now());

  const saveTarget = useCallback(
    async (shopClientId: string, roleSettings: PrinterControllerSettings) => {
      setIsLoading(true);
      try {
        await setDoc(
          doc(
            collection(
              doc(collection(firestore, "deviceRemoteManagement"), shopClientId),
              "printerSettings",
            ),
            roleSettings.roleId.toString(),
          ),
          roleSettings,
        );

        setUpdatedAt(Date.now());

        message.success("設定を保存しました");
      } catch (e) {
        console.error(e);
        message.error("設定の保存に失敗しました");
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const deleteTarget = useCallback(
    async (shopClientId: string, roleSettings: PrinterControllerSettings) => {
      setIsLoading(true);
      try {
        await deleteDoc(
          doc(
            collection(
              doc(collection(firestore, "deviceRemoteManagement"), shopClientId),
              "printerSettings",
            ),
            roleSettings.roleId.toString(),
          ),
        );

        setUpdatedAt(Date.now());

        message.success("設定を削除しました");
      } catch (e) {
        console.error(e);
        message.error("設定の削除に失敗しました");
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  useAsync(async () => {
    // updatedAt の更新の度に走らせるために意味のない if 文を書いている
    if (!updatedAt) return;
    setIsLoading(true);

    const entries = await Promise.all(
      shopClientIds.map(async (shopClientId) => {
        const res = await getDocs(
          collection(
            doc(collection(firestore, "deviceRemoteManagement"), shopClientId),
            "printerSettings",
          ),
        );
        const settings = res.docs.map((d) => d.data() as PrinterControllerSettings);
        return [shopClientId, settings] as const;
      }),
    );

    setPrinterControllerSettings(Object.fromEntries(entries));
    setIsLoading(false);
  }, [shopClientIds, updatedAt]);

  return {
    isLoading,
    printerControllerSettings,
    saveTarget,
    deleteTarget,
  };
};
