import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import type { StructuredJson } from "@google-cloud/logging/build/src/entry";
import { isNotNull, isObject } from "util/type/primitive";

import { request } from "libs/api";
import { Entry } from "pages/MutationLogs/types";

const operation = "jsonPayload.detail.operation";
const userVars = `${operation}.user_vars`;

type ApiResponse = {
  entries: StructuredJson[];
  nextPageToken: string | null | undefined;
};

type Response = {
  entries: Entry[];
  nextPageToken: string | null | undefined;
  query: string;
};

const isEntry = (entry: unknown): entry is Entry =>
  isObject(entry) &&
  "message" in entry &&
  isObject(entry.message) &&
  "detail" in entry.message &&
  isObject(entry.message.detail) &&
  "http_info" in entry.message.detail &&
  "operation" in entry.message.detail &&
  "request_id" in entry.message.detail;

export const useLoggingQuery = ({
  variables,
  skip,
}: {
  variables?: {
    operationName?: string;
    corporationId: string;
    companyId: string;
    shopId: string;
    tableUserId?: string;
    userId?: string;
    role?: string;
    from?: string;
    to?: string;
  };
  skip?: boolean;
}) => {
  const {
    data: infiniteData,
    isLoading: loading,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery<Response | null>(
    ["logging", variables],
    async ({ pageParam }) => {
      if (!variables || skip === true) return null;

      const {
        operationName,
        corporationId,
        companyId,
        shopId,
        tableUserId,
        userId,
        role,
        from,
        to,
      } = variables;

      const isDashboardAccount = role === "dashboard-account";
      const isDashboardRole = role === "service-admin" || role === "dashboard-account";

      const filter = [
        `jsonPayload.type = "http-log"`,
        from ? `timestamp >= "${from}"` : null,
        to ? `timestamp <= "${to}"` : null,
        operationName ? `${operation}.query.operationName = "${operationName}"` : null,
        role ? `${userVars}."x-hasura-role" = "${role}"` : null,
        isDashboardAccount ? `${userVars}."x-hasura-corporation-id" = "${corporationId}"` : null,
        !isDashboardRole ? `${userVars}."x-hasura-company-uuid" = "${companyId}"` : null,
        !isDashboardRole ? `${userVars}."x-hasura-shop-id" = "${shopId}"` : null,
        tableUserId ? `${userVars}."x-hasura-table-user-id" = "${tableUserId}"` : null,
        userId ? `${userVars}."x-hasura-user-id" = "${userId}"` : null,
        `jsonPayload.detail.operation.query.query =~ "mutation.*"`,
      ]
        .filter(isNotNull)
        .join("\n");

      const { data } = await request.get<ApiResponse>(`admin/log/entries`, {
        params: { filter, pageSize: 50, pageToken: pageParam },
      });
      const { entries, nextPageToken } = data;
      return { entries: entries.filter(isEntry), nextPageToken, query: filter };
    },
    !variables || skip === true
      ? { enabled: false }
      : {
          getNextPageParam: (lastPage) => lastPage?.nextPageToken || undefined,
        },
  );
  const data = useMemo(() => {
    if (!infiniteData) return undefined;

    const firstPage = infiniteData.pages[0];
    if (!firstPage) return undefined;

    const { query } = firstPage;
    const entries = infiniteData.pages.filter(isNotNull).flatMap(({ entries }) => entries);
    return { entries, query };
  }, [infiniteData]);

  return { data, loading, error, hasNextPage, fetchNextPage };
};
