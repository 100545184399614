import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Input as AntdInput } from "antd";

type Props = (typeof AntdInput extends React.ComponentType<infer T>
  ? Omit<T, "value" | "onChange">
  : never) & {
  value?: string[];
  onChange?: (data: string[]) => void;
  deserialize: (raw: string) => string[];
  serialize: (data: string[]) => string;
};

export const InputCustomFormatText = ({
  value: data,
  onChange,
  deserialize,
  serialize,
  ...props
}: Props) => {
  const serializeRef = useRef(serialize);
  serializeRef.current = serialize;

  const deserializeRef = useRef(deserialize);
  useEffect(() => {
    deserializeRef.current = deserialize;
  }, [deserialize]);

  const value = useMemo(() => serializeRef.current(data ?? []), [data]);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.currentTarget.value ?? "";
      onChange?.(deserializeRef.current(value));
    },
    [onChange],
  );

  return <AntdInput value={value} onChange={handleChange} {...props} />;
};
