import { useCallback, useEffect, useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  CollectionReference,
  getDocs,
  limit,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  startAfter,
} from "firebase/firestore";
import { uniqBy } from "lodash";

import { useImmutableGetter } from "hooks/useImmutableGetter";

const DEFAULT_QUERY_LIMIT = 100;

export const useFirestorePagination = <T>(
  createQuery: () => {
    skip: boolean;
    collection: CollectionReference<T>;
    constraints: QueryConstraint[] | null;
    queryLimit?: number;
    getKey: (data: T) => string;
  },
  dependencies: unknown[],
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryConfig = useMemo(createQuery, dependencies);
  const queryLimit = queryConfig.queryLimit ?? DEFAULT_QUERY_LIMIT;
  const getQueryRef = useImmutableGetter(
    () => ({ ...queryConfig, queryLimit }),
    [queryConfig, queryLimit],
  );

  const [result, setResult] = useState<T[]>([]);
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<T> | undefined>(undefined);
  const [isLast, setIsLast] = useState<boolean>(false);

  const handleMoreData = useCallback(
    (snapshot: QuerySnapshot<T>) => {
      const data = snapshot.docs.map((d) => d.data());
      setResult((curr) => {
        const next = uniqBy([...curr, ...data], getQueryRef().getKey);
        return next.length === curr.length ? curr : next;
      });
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      if (data.length < getQueryRef().queryLimit) {
        setIsLast(true);
      }
    },
    [getQueryRef],
  );

  const [_, loading, error, snapshot] = useCollectionData<T>(
    queryConfig.constraints
      ? query<T>(queryConfig.collection, ...queryConfig.constraints, limit(queryLimit))
      : undefined,
  );

  useEffect(() => {
    if (!snapshot) return;
    handleMoreData(snapshot);
  }, [handleMoreData, snapshot]);

  useEffect(() => {
    if (queryConfig.collection.id && queryConfig.constraints) {
      setResult([]);
    }
  }, [queryConfig.collection.id, queryConfig.constraints]);

  const loadMore = useCallback(async () => {
    if (!queryConfig.constraints) return;
    const moreDataSnapshot = await getDocs(
      query<T>(
        queryConfig.collection,
        ...queryConfig.constraints,
        startAfter(lastDoc),
        limit(queryLimit),
      ),
    );
    handleMoreData(moreDataSnapshot);
  }, [handleMoreData, lastDoc, queryConfig.collection, queryConfig.constraints, queryLimit]);

  return { data: result, loading, error, loadMore, isLast };
};
