import React, { memo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

export type CashRegisterStatusType = {
  deviceId: string;
  uid: string;
  shopClientName: string;
  printerName: string | null;
  printerTarget: string | null;
  cashChangerTarget: string | null;
  nativeAppVersion: string;
  version: string;
  osName: string;
  osVersion: string;
  updatedAt: string;
  shopClientId: string;
  disableStandaloneOperationSupport: boolean | null;
};

type Props = {
  loading: boolean;
  cashRegisterStatuses: CashRegisterStatusType[];
  onEdit: ({ shopClientId }: { shopClientId: string }) => void;
};

export const CashRegisterStatusTable = memo<Props>(({ loading, cashRegisterStatuses, onEdit }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "Device ID", dataIndex: "deviceId" },
    { title: "Unique ID", dataIndex: "uid" },
    { title: "Account ID", dataIndex: "shopClientId" },
    { title: "Account Name", dataIndex: "shopClientName" },
    { title: "Printer Name", dataIndex: "printerName" },
    { title: "Printer Target", dataIndex: "printerTarget" },
    { title: "Cash Changer Target", dataIndex: "cashChangerTarget" },
    { title: "Native App Version", dataIndex: "nativeAppVersion" },
    { title: "Version", dataIndex: "version" },
    { title: "OS Name", dataIndex: "osName" },
    { title: "OS Version", dataIndex: "osVersion" },
    {
      title: "Standalone Enabled",
      dataIndex: "disableStandaloneOperationSupport",
      render(_: unknown, { disableStandaloneOperationSupport }: CashRegisterStatusType) {
        return disableStandaloneOperationSupport ? "disabled" : "enabled";
      },
    },
    { title: "Updated At", dataIndex: "updatedAt" },
    {
      title: "",
      align: "center",
      fixed: "right",
      width: 60,
      render(_: string, { shopClientId }: CashRegisterStatusType) {
        return <EditIcon onClick={() => onEdit({ shopClientId })} />;
      },
    } as const,
  ];

  return (
    <Table
      rowKey={({ deviceId, uid }) => `${uid}-${deviceId}`}
      columns={columns}
      dataSource={cashRegisterStatuses}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
