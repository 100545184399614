import React, { memo } from "react";
import { Table } from "antd";
import dayjs from "dayjs";
import { sum } from "lodash";
import { extractItemSales } from "models/onSitePayment";

import { usePagination } from "hooks/usePagination";

import { OnSitePayment } from "../types";

type Props = {
  loading: boolean;
  onSitePayments: OnSitePayment[];
};

export const OnSitePaymentTable = memo<Props>(({ loading, onSitePayments }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "会計ID",
      width: 500,
      render(_: unknown, { onSitePaymentId }: OnSitePayment) {
        return (
          <a
            href={`${import.meta.env.DASHBOARD_URL}/accounting/${onSitePaymentId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {onSitePaymentId}
          </a>
        );
      },
    },
    {
      title: "時刻",
      width: 500,
      render(_: unknown, { createdAt }: OnSitePayment) {
        return dayjs(createdAt).format("MM/DD HH:mm");
      },
    },
    {
      title: "レシート番号",
      width: 500,
      render(_: unknown, { localAccountingSlipId }: OnSitePayment) {
        return localAccountingSlipId;
      },
    },
    {
      title: "取扱総額",
      width: 500,
      render(_: unknown, onSitePayment: OnSitePayment) {
        const totalAmount = sum(
          extractItemSales(onSitePayment).map((sales) => sales.billingAmount),
        );
        return `${String(totalAmount)}円`;
      },
    },
    {
      title: "税額",
      width: 500,
      render(_: unknown, onSitePayment: OnSitePayment) {
        const totalTaxAmount = sum(extractItemSales(onSitePayment).map((sales) => sales.taxAmount));
        return `${String(totalTaxAmount)}円`;
      },
    },
  ];

  return (
    <Table
      rowKey={(t) => t.onSitePaymentId}
      columns={columns}
      dataSource={onSitePayments}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
