export const claimKeyForHasuraAllowedRoles = "x-hasura-allowed-roles";
export const claimKeyForHasuraDefaultRole = "x-hasura-default-role";
export const claimKeyForHasuraUserId = "x-hasura-user-id";
export const claimKeyForHasuraShopId = "x-hasura-shop-id";
export const claimKeyForHasuraTableId = "x-hasura-table-id";
export const claimKeyForHasuraTableUserId = "x-hasura-table-user-id";
export const claimKeyForHasuraCompanyUuid = "x-hasura-company-uuid";
export const claimKeyForHasuraDashboardAccountId = "x-hasura-dashboard-account-id";
export const claimKeyForHasuraDashboardAccountRole = "x-hasura-dashboard-account-role";
export const claimNamespaceForHasura = "https://hasura.io/jwt/claims";
