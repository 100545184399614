import React, { memo, useMemo } from "react";
import dayjs from "dayjs";

import { MainLayout } from "components/Layout/MainLayout";
import { LoadMoreButton } from "components/LoadMoreButton";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";
import { HandyStatusTable } from "pages/HandyStatus/HandyStatusTable";
import { useHandyStatusQuery } from "pages/HandyStatus/queries.firestore";

import { HandyConnectionHistoryToCashRegister } from "./HandyConnectionHistoryToCashRegister";

export const HandyStatus = memo(() => {
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId");

  const {
    loading,
    data: statuses = [],
    loadMore,
    isLast,
  } = useHandyStatusQuery(shopId ? { variables: { shopId } } : { skip: true });

  const rowItems = useMemo(
    () =>
      statuses.map((status) => ({
        deviceId: status.deviceId,
        uid: status.uid,
        nativeAppVersion: status.nativeAppVersion,
        version: status.version,
        modelName: status.modelName,
        osName: status.osName,
        osVersion: status.osVersion,
        updatedAt: dayjs(status.updatedAt.toDate()).format("YYYY/MM/DD HH:mm:ss"),
        shopClientId: status.shopClientId,
      })),
    [statuses],
  );

  return (
    <MainLayout title="ハンディ状況">
      <ShopSelector />

      <Spacer size={16} />

      <HandyConnectionHistoryToCashRegister shopId={shopId ?? null} />

      <Spacer size={16} />

      <LoadMoreButton loadMore={loadMore} isLast={isLast} />

      <Spacer size={20} />

      <HandyStatusTable loading={loading} handyStatuses={rowItems} />
    </MainLayout>
  );
});
