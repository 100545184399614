import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TableUserMovedEventsGetEvents = gql`
    query TableUserMovedEventsGetEvents($shopId: uuid!, $from: timestamptz!, $to: timestamptz!) {
  tableUserMovedEvent(
    where: {tableUser: {shopId: {_eq: $shopId}}, movedAt: {_gt: $from, _lt: $to}}
  ) {
    id
    movedAt
    tableUser {
      id
      activatedAt
      numPeople
    }
    fromTable {
      name
    }
    toTable {
      name
    }
  }
}
    `;
export type TableUserMovedEventsGetEventsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['timestamptz'];
  to: Types.Scalars['timestamptz'];
}>;


export type TableUserMovedEventsGetEventsQuery = (
  { __typename?: 'query_root' }
  & { tableUserMovedEvent: Array<(
    { __typename?: 'tableUserMovedEvent' }
    & Pick<Types.TableUserMovedEvent, 'id' | 'movedAt'>
    & { tableUser: (
      { __typename?: 'tableUser' }
      & Pick<Types.TableUser, 'id' | 'activatedAt' | 'numPeople'>
    ), fromTable: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'name'>
    ), toTable: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'name'>
    ) }
  )> }
);


export const TableUserMovedEventsGetEventsDocument = gql`
    query TableUserMovedEventsGetEvents($shopId: uuid!, $from: timestamptz!, $to: timestamptz!) {
  tableUserMovedEvent(
    where: {tableUser: {shopId: {_eq: $shopId}}, movedAt: {_gt: $from, _lt: $to}}
  ) {
    id
    movedAt
    tableUser {
      id
      activatedAt
      numPeople
    }
    fromTable {
      name
    }
    toTable {
      name
    }
  }
}
    `;

/**
 * __useTableUserMovedEventsGetEventsQuery__
 *
 * To run a query within a React component, call `useTableUserMovedEventsGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserMovedEventsGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserMovedEventsGetEventsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTableUserMovedEventsGetEventsQuery(baseOptions: Apollo.QueryHookOptions<TableUserMovedEventsGetEventsQuery, TableUserMovedEventsGetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserMovedEventsGetEventsQuery, TableUserMovedEventsGetEventsQueryVariables>(TableUserMovedEventsGetEventsDocument, options);
      }
export function useTableUserMovedEventsGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserMovedEventsGetEventsQuery, TableUserMovedEventsGetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserMovedEventsGetEventsQuery, TableUserMovedEventsGetEventsQueryVariables>(TableUserMovedEventsGetEventsDocument, options);
        }
export type TableUserMovedEventsGetEventsQueryHookResult = ReturnType<typeof useTableUserMovedEventsGetEventsQuery>;
export type TableUserMovedEventsGetEventsLazyQueryHookResult = ReturnType<typeof useTableUserMovedEventsGetEventsLazyQuery>;
export type TableUserMovedEventsGetEventsQueryResult = Apollo.QueryResult<TableUserMovedEventsGetEventsQuery, TableUserMovedEventsGetEventsQueryVariables>;