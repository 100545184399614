import React, { memo, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";
import styled from "styled-components";
import { Button, Input, Modal } from "antd";

import { Table } from "components/Table";
import {
  PrinterControllerSettings,
  usePrinterControllerSettings,
} from "hooks/usePrinterControllerSettings";

import { Role } from "../../../entity/role.entity";
import { request } from "../../../libs/api";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  isOpen: boolean;
  shopId?: string;
  uid?: string;
  onClose: () => void;
};

export const PrinterSettingsDialog = memo<Props>(({ isOpen, shopId, uid, onClose }: Props) => {
  const shopClientIds = useMemo(() => (uid ? [uid] : []), [uid]);
  const { isLoading, printerControllerSettings, saveTarget, deleteTarget } =
    usePrinterControllerSettings({ shopClientIds });

  const [roles, setRoles] = useState<Role[] | null>(null);
  useAsync(async () => {
    if (!shopId) return;
    const response = await request.get(`roles/${shopId}`);
    setRoles(response.data as Role[]);
  }, [shopId]);

  const [settings, setSettings] = useState([] as PrinterControllerSettings[]);

  useEffect(() => {
    const savedSettings = uid ? printerControllerSettings[uid] ?? [] : [];
    setSettings(
      (roles ?? []).map(
        (role) =>
          savedSettings.find((s) => s.roleId === role.roleId) || {
            ...role,
            roleName: role.name,
            macAddress: "",
            target: "",
            deviceName: "",
            deviceType: 0,
            bdAddress: "",
            ipAddress: "",
          },
      ),
    );
  }, [printerControllerSettings, roles, uid]);

  const columns = [
    {
      title: "Role",
      render(_: unknown, { roleId, roleName }: PrinterControllerSettings) {
        return `${roleId}:${roleName}`;
      },
    },
    {
      title: "Target",
      render(_: unknown, { roleId, target }: PrinterControllerSettings) {
        return (
          <Input
            value={target}
            onChange={(e) =>
              setSettings(
                settings.map((m) => {
                  if (m.roleId === roleId) return { ...m, target: e.target.value };

                  return m;
                }),
              )
            }
          />
        );
      },
    },
    {
      title: "",
      align: "center",
      fixed: "right",
      width: 60,
      render(_: string, setting: PrinterControllerSettings) {
        return (
          <ButtonContainer>
            <Button
              onClick={() => uid && saveTarget(uid, setting)}
              disabled={!setting.target.includes(":")}
            >
              保存
            </Button>
            <Button onClick={() => uid && deleteTarget(uid, setting)} color="secondary">
              削除
            </Button>
          </ButtonContainer>
        );
      },
    } as const,
  ];

  return (
    <Modal open={isOpen} title="プリンター設定" onCancel={onClose}>
      <Table loading={isLoading} columns={columns} dataSource={settings} pagination={false} />
    </Modal>
  );
});
