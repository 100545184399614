import React, { memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { collection, doc, setDoc } from "firebase/firestore";
import { checkNotificationStatus, NotificationSettings } from "models/notificationSettings";

import { Spacer } from "components/Spacer";
import { firestore } from "libs/firebase";

import { FormActions } from "../FormActions";

import { NotificationContentFields } from "./NotificationContentFields";
import { NotificationDeliveryFields } from "./NotificationDeliveryFields";
import { NotificationScheduleFields } from "./NotificationScheduleFields";
import {
  SubmitMode,
  SubmitOptions,
  useNotificationSettingsForm,
} from "./useNotificationSettingsForm";

type Props = {
  settings?: Partial<NotificationSettings>;
  onCancel: () => void;
};

export const NotificationSettingsForm = memo<Props>(
  ({ settings: currentSettings = {}, onCancel }) => {
    const navigate = useNavigate();
    const onError = useCallback((e: unknown) => {
      console.error(e);
      message.error("保存に失敗しました");
    }, []);
    const onSubmit = useCallback(
      async ({ mode, settings }: { mode: SubmitMode; settings: NotificationSettings }) => {
        try {
          await setDoc(doc(collection(firestore, "notifications"), settings.id), settings);
          message.success("保存しました");
          const menuType = mode === "saveDraft" ? "draft" : "published";
          navigate(`/notifications?menu=${menuType}`);
        } catch (e) {
          onError(e);
        }
      },
      [navigate, onError],
    );

    const {
      form,
      initialValues,
      submit: handleSubmit,
    } = useNotificationSettingsForm({
      settings: currentSettings,
      onSubmit,
      onError,
    });
    const submit = useCallback(
      async (options: SubmitOptions) => {
        try {
          await form.validateFields();
          handleSubmit(options);
        } catch (e) {
          onError(e);
        }
      },
      [form, onError, handleSubmit],
    );

    useEffect(() => form.resetFields(), [form, currentSettings]);

    const status = checkNotificationStatus(currentSettings);

    const saveDraft = useCallback(() => {
      if (status === "draft") {
        submit({ mode: "saveDraft" });
      }
    }, [submit, status]);

    const publish = useCallback(() => {
      if (status === "draft") {
        submit({ mode: "publish" });
      } else if (status === "reserved" || status === "started") {
        submit({
          mode: "publish",
          confirm: (onOk) => {
            Modal.confirm({
              centered: true,
              icon: <ExclamationCircleOutlined />,
              title: "配信内容を更新しますか？",
              content: "配信中の場合、直ちに内容が置き換えられます。",
              okText: "更新",
              cancelText: "キャンセル",
              onOk,
            });
          },
        });
      }
    }, [submit, status]);

    const stopDelivery = useCallback(() => {
      if (status === "started") {
        submit({
          mode: "close",
          confirm: (onOk) => {
            Modal.confirm({
              centered: true,
              icon: <ExclamationCircleOutlined />,
              title: "配信停止しますか？",
              content: "直ちに配信停止となり、再配信や編集ができなくなります。",
              okText: "配信停止",
              cancelText: "キャンセル",
              onOk,
            });
          },
        });
      }
    }, [status, submit]);

    return (
      <Form name="notificationSettings" form={form} layout="vertical" initialValues={initialValues}>
        <NotificationContentFields status={status} />
        <Spacer size={32} />
        <NotificationDeliveryFields status={status} />
        <Spacer size={32} />
        <NotificationScheduleFields status={status} />
        <Spacer size={32} />
        <FormActions>
          {status === "draft" && (
            <>
              <Button onClick={saveDraft}>下書き保存</Button>
              <Button type="primary" onClick={publish}>
                作成
              </Button>
            </>
          )}
          {(status === "reserved" || status === "started") && (
            <>
              {status === "started" && (
                <Button danger onClick={stopDelivery}>
                  配信停止
                </Button>
              )}
              <Button onClick={onCancel}>キャンセル</Button>
              <Button type="primary" onClick={publish}>
                更新
              </Button>
            </>
          )}
        </FormActions>
      </Form>
    );
  },
);
