import React, { memo } from "react";
import styled from "styled-components";

type Props =
  | { inline?: boolean; width?: number; height: number }
  | { inline?: boolean; width: number; height?: number }
  | { inline?: boolean; size: number };

const Container = styled.div<Props>`
  display: ${({ inline }) => (inline ? "inline-block" : "block")};
  width: ${(props) => ("size" in props ? props.size : props.width ?? 0)}px;
  height: ${(props) => ("size" in props ? props.size : props.height ?? 0)}px;
`;

export const Spacer = memo<Props>((props) => <Container {...props} />);
