import { ApolloClient, ApolloLink, createHttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import * as uuid from "uuid";

import { sessionId } from "../constants/sessionId";
import { auth } from "../libs/firebase";
import { createCache } from "../store/cache";
import { signIn } from "../store/mutations/signIn";
import { signOut } from "../store/mutations/signOut";

export const createApolloClient = () => {
  const cache = createCache();

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      setContext(async () => {
        const token = await auth.currentUser?.getIdToken();
        const headers = {
          Authorization: `Bearer ${token}`,
          "x-session-id": sessionId,
          "x-request-id": uuid.v4(),
        };

        return { headers };
        // NOTE: https://github.com/apollographql/apollo-client/issues/6011
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any,
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);

          return definition.kind === "OperationDefinition" && definition.operation === "query";
        },
        createHttpLink({ uri: import.meta.env.HASURA_QUERY_URL || import.meta.env.HASURA_URL }),
        createHttpLink({ uri: import.meta.env.HASURA_URL }),
      ),
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
    resolvers: {
      Mutation: {
        signIn,
        signOut,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    },
  });
};
