import React, { useMemo } from "react";
import { Card, Checkbox, Form, Input, Select, Typography } from "antd";
import { getNotificationCategoryLabel, NotificationStatus } from "models/notificationSettings";

import { Spacer } from "components/Spacer";

export const NotificationContentFields = ({ status }: { status: NotificationStatus }) => {
  const categoryOptions = useMemo(
    () => [
      {
        key: "highest",
        label: getNotificationCategoryLabel("highest"),
        value: "highest",
      },
      {
        key: "high",
        label: getNotificationCategoryLabel("high"),
        value: "high",
      },
      {
        key: "middle",
        label: getNotificationCategoryLabel("middle"),
        value: "middle",
      },
      {
        key: "low",
        label: getNotificationCategoryLabel("low"),
        value: "low",
      },
    ],
    [],
  );
  const disabled = status !== "draft" && status !== "reserved" && status !== "started";
  return (
    <Card>
      <Typography.Title level={4}>概要</Typography.Title>
      <Form.Item
        label={
          <>
            タイトル
            <Spacer inline size={16} />
            <Form.Item name="isImportant" valuePropName="checked" noStyle>
              <Checkbox>重要ラベルをつける</Checkbox>
            </Form.Item>
          </>
        }
        name="title"
        required
        rules={[{ required: true, message: "タイトルを入力してください" }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="本文"
        name="message"
        required
        rules={[{ required: true, message: "本文を入力してください" }]}
      >
        <Input.TextArea disabled={disabled} placeholder="本文を入力" rows={6} showCount />
      </Form.Item>
      <Form.Item
        label="カテゴリ設定"
        name="category"
        required
        rules={[{ required: true, message: "カテゴリを選択してください" }]}
      >
        <Select
          disabled={disabled}
          placeholder="選択してください"
          optionFilterProp="label"
          options={categoryOptions}
        />
      </Form.Item>
    </Card>
  );
};
