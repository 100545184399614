import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

export type FilterConditions = {
  tableName?: string;
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  align-items: center;
  margin-bottom: 16px;
`;

type Props = {
  tableNames: string[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const TableUserFilter = memo<Props>(
  ({
    tableNames,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => {
    const handleChangeTableName = useCallback(
      (tableName: string) => updateFilterCondition({ tableName }),
      [updateFilterCondition],
    );

    return (
      <Wrapper>
        <Col flex="1 0">
          <Select<string>
            showSearch
            allowClear
            placeholder="テーブル名"
            value={filterConditions.tableName}
            onChange={handleChangeTableName}
            optionFilterProp="label"
            options={tableNames.map((tableName) => ({ label: tableName, value: tableName }))}
          />
        </Col>
        <Col flex="none">
          <Tooltip title="フィルター条件をクリア">
            <Button
              shape="circle"
              icon={<ClearOutlined />}
              disabled={hasFilterConditions}
              onClick={clearFilterConditions}
            />
          </Tooltip>
        </Col>
      </Wrapper>
    );
  },
);
