import React, { memo } from "react";
import { DatePicker } from "antd";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { TableUserFilter } from "pages/TableUserGroupingEvents/TableUserFilter";
import { TableUserGroupingEventsTable } from "pages/TableUserGroupingEvents/TableUserGroupingEventsTable";

import { useTableUserGroupingEvents } from "./useTableUserGroupEvents";

export const TableUserGroupingEvents = memo(() => {
  const {
    fromDate,
    untilDate,
    tableNames,
    filterConditions,
    hasFilterConditions,
    handleUpdateFilterCondition,
    handleClearFilterCondition,
    loading,
    tableUserGroupingEvents,
    handleCalendarSelectableDateRangeChange,
    handleCalendarDateChange,
    disabledDate,
  } = useTableUserGroupingEvents();

  return (
    <MainLayout title="グルーピング履歴">
      <ShopSelector />

      <Spacer size={16} />

      <DatePicker.RangePicker
        onCalendarChange={handleCalendarSelectableDateRangeChange}
        onChange={handleCalendarDateChange}
        disabledDate={disabledDate}
        defaultValue={[fromDate, untilDate]}
      />
      <Spacer size={16} />

      <TableUserFilter
        tableNames={tableNames}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={handleUpdateFilterCondition}
        clearFilterConditions={handleClearFilterCondition}
      />

      <TableUserGroupingEventsTable
        loading={loading}
        tableUserGroupingEvents={tableUserGroupingEvents}
      />
    </MainLayout>
  );
});
