import React from "react";
import styled, { css } from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";

import { Spacer } from "components/Spacer";

import {
  isPrinterInfoExpired,
  useStandaloneOperationSupportAvailability,
} from "./useStandaloneOperationSupportAvailability";

const ListItem = styled.div`
  padding-left: 20px;
  ::before {
    content: "- ";
  }
`;

const Separator = styled.span`
  ::after {
    content: "-";
    padding: 0 0.5em;
  }
`;

const emphasized = css`
  color: red;
  font-weight: bold;
`;
const Emphasized = styled.span<{ shouldEmphasize: boolean }>`
  ${({ shouldEmphasize }) => (shouldEmphasize ? emphasized : "")}
`;

export const CashRegisterOfflineAvailability = ({ shopId }: { shopId: string }) => {
  const {
    canEnableSafely,
    roleAssignments,
    hasNoRoleAssignment,
    hasDuplicatedRoleAssignment,
    hasPrinterControllerRoleAssignment,
    hasCashRegisterUnavailablePrinter,
    hasOutdatedDeviceInfo,
    unknownLocalAreaPrinters,
  } = useStandaloneOperationSupportAvailability({ shopId });

  return (
    <Alert
      type={canEnableSafely ? "info" : "error"}
      description={
        <>
          <h3>スタンドアロン対応機能利用{canEnableSafely ? "可能" : "不可"}</h3>
          {hasNoRoleAssignment && <ListItem>ロールの割当がありません</ListItem>}
          {hasDuplicatedRoleAssignment && (
            <ListItem>同じロールが複数のプリンターに設定されています</ListItem>
          )}
          {hasPrinterControllerRoleAssignment && <ListItem>PCを利用している店舗です</ListItem>}
          {(hasCashRegisterUnavailablePrinter || hasOutdatedDeviceInfo) && (
            <ListItem>レジから利用できないプリンターがあります</ListItem>
          )}
          <Spacer size={10} />
          <h3>キッチンプリンター:</h3>
          {roleAssignments.map((assignment, idx) => {
            const { type, role } = assignment;
            const localAreaPrinter =
              type === "DirectPrint" ? assignment.localAreaPrinter ?? null : null;
            const deviceInfoUpdatedAt = localAreaPrinter?.deviceInfoUpdatedAt?.toDate() ?? null;
            const isExpired = deviceInfoUpdatedAt
              ? isPrinterInfoExpired(dayjs(deviceInfoUpdatedAt))
              : false;
            return (
              <ListItem key={idx}>
                {role.roleId}:{role.name}
                <Separator />
                {type}
                {type === "DirectPrint" && (
                  <>
                    <Separator />
                    {assignment.shopPrinter.email}
                    <Separator />
                    {assignment.deviceId}
                    <Separator />
                    <Emphasized shouldEmphasize={!localAreaPrinter || isExpired}>
                      レジから利用{localAreaPrinter ? (isExpired ? "不可" : "可") : "不可"}
                    </Emphasized>
                    <Separator />
                    {deviceInfoUpdatedAt ? (
                      <>
                        レジとの最終接続:{" "}
                        <Emphasized shouldEmphasize={isExpired}>
                          {dayjs(deviceInfoUpdatedAt).format("YYYY-MM-DD HH:mm")}
                        </Emphasized>
                      </>
                    ) : null}
                  </>
                )}
              </ListItem>
            );
          })}
          <Spacer size={10} />
          <h3>レジからの認識のみができているプリンター:</h3>
          {unknownLocalAreaPrinters.map(({ macAddress, deviceInfoUpdatedAt }) => (
            <ListItem key={macAddress}>
              {macAddress}
              <Separator />
              レジとの最終接続:{" "}
              {deviceInfoUpdatedAt
                ? dayjs(deviceInfoUpdatedAt.toDate()).format("YYYY-MM-DD HH:mm")
                : "不明"}
            </ListItem>
          ))}
        </>
      }
    />
  );
};
