import React, { memo } from "react";
import { Table } from "antd";
import dayjs from "dayjs";

import { usePagination } from "hooks/usePagination";

import { TableUserMovedEvent } from "../types";

type Props = {
  loading: boolean;
  tableUserMovedEvents: TableUserMovedEvent[];
};

export const TableUserMovedEventTable = memo<Props>(({ loading, tableUserMovedEvents }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "人数",
      width: 500,
      render(_: unknown, { tableUser: { numPeople } }: TableUserMovedEvent) {
        return numPeople;
      },
    },
    {
      title: "移動元卓番号",
      width: 500,
      render(_: unknown, { fromTable: { name } }: TableUserMovedEvent) {
        return name;
      },
    },
    {
      title: "移動先卓番号",
      width: 500,
      render(_: unknown, { toTable: { name } }: TableUserMovedEvent) {
        return name;
      },
    },
    {
      title: "卓立ち上げ時刻",
      width: 500,
      render(_: unknown, { tableUser: { activatedAt } }: TableUserMovedEvent) {
        return dayjs(activatedAt).format("MM/DD HH:mm");
      },
    },
    {
      title: "移動時刻",
      width: 500,
      render(_: unknown, { movedAt }: TableUserMovedEvent) {
        return dayjs(movedAt).format("MM/DD HH:mm");
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={tableUserMovedEvents}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
