import React, { memo } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  margin-left: 8px;
`;

type Props = {
  help: React.ReactNode;
  label: string;
};

export const FormHelp = memo<Props>(({ help, label }) => (
  <Tooltip title={help}>
    <span>
      {label}
      <StyledQuestionCircleOutlined />
    </span>
  </Tooltip>
));
