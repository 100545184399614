import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, DatePicker, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

export type FilterConditions = {
  role?: string;
  date?: dayjs.Dayjs;
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  align-items: center;
  margin-bottom: 16px;
`;

const roles = {
  customer: "User App",
  regi: "Cash Register",
  handy: "Handy",
  kd: "Kitchen Display",
  kiosk: "Kiosk",
  "dashboard-account": "Dashboard (Customer)",
  "service-admin": "Dashboard (dinii)",
};

type Props = {
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const LogFilter = memo<Props>(
  ({ hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions }) => (
    <Wrapper>
      <Col flex="1 0">
        <DatePicker
          onChange={(date) => updateFilterCondition({ date: date ?? undefined })}
          defaultValue={filterConditions.date}
        />
      </Col>
      <Col flex="1 0">
        <Select<string>
          showSearch
          allowClear
          placeholder="アプリ名"
          value={filterConditions.role}
          onChange={(role) => updateFilterCondition({ role })}
          optionFilterProp="label"
          options={Object.entries(roles).map(([value, label]) => ({ label, value }))}
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
