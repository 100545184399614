import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TableUserGroupingEventsGetTableUserGroupingEvents = gql`
    query TableUserGroupingEventsGetTableUserGroupingEvents($shopId: uuid!, $from: timestamp!, $until: timestamp!) {
  tableUserGroupingEvent(
    where: {tableUser: {shopId: {_eq: $shopId}}, createdAt: {_gte: $from, _lte: $until}}
    limit: 10000
    order_by: {createdAt: desc}
  ) {
    id
    billableTableUserId
    createdAt
    tableUser {
      shopId
      table {
        tableId
        name
      }
    }
    tableUserGroupingEventTableUsers(order_by: {revision: desc}) {
      tableUserId
      revision
      state
      tableUser {
        table {
          tableId
          name
        }
      }
    }
  }
}
    `;
export type TableUserGroupingEventsGetTableUserGroupingEventsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['timestamp'];
  until: Types.Scalars['timestamp'];
}>;


export type TableUserGroupingEventsGetTableUserGroupingEventsQuery = (
  { __typename?: 'query_root' }
  & { tableUserGroupingEvent: Array<(
    { __typename?: 'tableUserGroupingEvent' }
    & Pick<Types.TableUserGroupingEvent, 'id' | 'billableTableUserId' | 'createdAt'>
    & { tableUser: (
      { __typename?: 'tableUser' }
      & Pick<Types.TableUser, 'shopId'>
      & { table: (
        { __typename?: 'table' }
        & Pick<Types.Table, 'tableId' | 'name'>
      ) }
    ), tableUserGroupingEventTableUsers: Array<(
      { __typename?: 'tableUserGroupingEventTableUser' }
      & Pick<Types.TableUserGroupingEventTableUser, 'tableUserId' | 'revision' | 'state'>
      & { tableUser: (
        { __typename?: 'tableUser' }
        & { table: (
          { __typename?: 'table' }
          & Pick<Types.Table, 'tableId' | 'name'>
        ) }
      ) }
    )> }
  )> }
);


export const TableUserGroupingEventsGetTableUserGroupingEventsDocument = gql`
    query TableUserGroupingEventsGetTableUserGroupingEvents($shopId: uuid!, $from: timestamp!, $until: timestamp!) {
  tableUserGroupingEvent(
    where: {tableUser: {shopId: {_eq: $shopId}}, createdAt: {_gte: $from, _lte: $until}}
    limit: 10000
    order_by: {createdAt: desc}
  ) {
    id
    billableTableUserId
    createdAt
    tableUser {
      shopId
      table {
        tableId
        name
      }
    }
    tableUserGroupingEventTableUsers(order_by: {revision: desc}) {
      tableUserId
      revision
      state
      tableUser {
        table {
          tableId
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTableUserGroupingEventsGetTableUserGroupingEventsQuery__
 *
 * To run a query within a React component, call `useTableUserGroupingEventsGetTableUserGroupingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableUserGroupingEventsGetTableUserGroupingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableUserGroupingEventsGetTableUserGroupingEventsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useTableUserGroupingEventsGetTableUserGroupingEventsQuery(baseOptions: Apollo.QueryHookOptions<TableUserGroupingEventsGetTableUserGroupingEventsQuery, TableUserGroupingEventsGetTableUserGroupingEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableUserGroupingEventsGetTableUserGroupingEventsQuery, TableUserGroupingEventsGetTableUserGroupingEventsQueryVariables>(TableUserGroupingEventsGetTableUserGroupingEventsDocument, options);
      }
export function useTableUserGroupingEventsGetTableUserGroupingEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableUserGroupingEventsGetTableUserGroupingEventsQuery, TableUserGroupingEventsGetTableUserGroupingEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableUserGroupingEventsGetTableUserGroupingEventsQuery, TableUserGroupingEventsGetTableUserGroupingEventsQueryVariables>(TableUserGroupingEventsGetTableUserGroupingEventsDocument, options);
        }
export type TableUserGroupingEventsGetTableUserGroupingEventsQueryHookResult = ReturnType<typeof useTableUserGroupingEventsGetTableUserGroupingEventsQuery>;
export type TableUserGroupingEventsGetTableUserGroupingEventsLazyQueryHookResult = ReturnType<typeof useTableUserGroupingEventsGetTableUserGroupingEventsLazyQuery>;
export type TableUserGroupingEventsGetTableUserGroupingEventsQueryResult = Apollo.QueryResult<TableUserGroupingEventsGetTableUserGroupingEventsQuery, TableUserGroupingEventsGetTableUserGroupingEventsQueryVariables>;