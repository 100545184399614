import React, { Fragment, memo, useCallback, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useAsync } from "react-use";
import styled from "styled-components";
import { Alert, Collapse, Tooltip } from "antd";
import { isNotNull } from "dinii-self-js-lib/types";
import { maxBy } from "lodash";

import { MainLayout } from "components/Layout/MainLayout";
import { Spacer } from "components/Spacer";
import { useOfflineFeatureUsage } from "hooks/useOfflineFeatureUsage";
import { useShops } from "hooks/useShops";
import { markRecordAsDispatched } from "libs/firebase";

import { ActivePlanContent } from "./ActivePlanContent";
import { OfflineFeatureActivityTable } from "./OfflineFeatureActivityTable";
import { OnSitePaymentContent } from "./OnSitePaymentContent";
import { OrderContent } from "./OrderContent";
import { StartTableUserContent } from "./StartTableUserContent";
import {
  createLocalActivePlanDataSummary,
  createLocalOnSitePaymentDataSummary,
  createLocalOrderDataSummary,
  createLocalStartTableUserDataSummary,
  OfflineFeatureActivity,
  OfflineFeatureType,
} from "./type";

const ListItem = styled.div`
  padding-left: 20px;
  font-family: monospace;

  ::before {
    content: "- ";
  }
`;

const typeToDisplayName = (type: OfflineFeatureType) => {
  switch (type) {
    case OfflineFeatureType.TableUser:
      return "卓立ち上げ";
    case OfflineFeatureType.Order:
      return "注文";
    case OfflineFeatureType.ActivePlan:
      return "プラン開始";
    case OfflineFeatureType.Payment:
      return "会計";
    default:
      throw new Error(`unreachable ${type}`);
  }
};

const firebase_console_base_url = `https://console.firebase.google.com`;
const projectId = import.meta.env.FIREBASE_PROJECT;

const getFirestoreConsoleUrl = ({
  type,
  shopId,
  recordId,
  cashRegisterId,
}: {
  type: OfflineFeatureType;
  shopId: string;
  recordId: string;
  cashRegisterId: string;
}) =>
  `${firebase_console_base_url}/project/${projectId}/firestore/data/${[
    "shops",
    shopId,
    "cashRegister",
    cashRegisterId,
    `${type}s`,
    recordId,
  ].join("~2F")}`;

export const OfflineFeatureUsage = memo(() => {
  const { fetchOfflineData, shopIdToOfflineDataRecordMap, unsentOfflineDataRecordMap } =
    useOfflineFeatureUsage();
  const { shops } = useShops();

  const shopIdToShopMap = useMemo(() => new Map(shops.map((shop) => [shop.shopId, shop])), [shops]);

  useAsync(async () => {
    await fetchOfflineData();
  }, [fetchOfflineData]);

  const handleMarkRecordAsDispatched = useCallback(
    async ({
      type,
      shopName,
      shopId,
      recordId,
      cashRegisterId,
    }: {
      type: OfflineFeatureType;
      shopName: string;
      shopId: string;
      recordId: string;
      cashRegisterId: string;
    }) => {
      if (
        confirm(
          [
            `「${shopName}」の${typeToDisplayName(type)}を破棄しますか？`,
            `🚨 レジの未送信データ一覧からは表示が消えますが、レジやサーバーにはデータが記録されません。`,
            `別会計などで記録済みになっているか、情報の控えがあることを確かめてから実行してください。`,
          ].join("\n"),
        )
      ) {
        toast.promise(
          markRecordAsDispatched(
            ["shops", shopId, "cashRegister", cashRegisterId, `${type}s`, recordId],
            type === "tableUser" ? "deactivatedAt" : "dispatchedAt",
          ).then(() => fetchOfflineData()),
          {
            loading: "データ破棄を実行中",
            success: "データを破棄しました",
            error: "操作に失敗しました",
          },
        );
      }
    },
    [fetchOfflineData],
  );

  // 未送信データだけ抽出し、アラートに表示する
  const unsentOfflineUsageData = useMemo(
    () =>
      [...(unsentOfflineDataRecordMap?.entries() ?? [])]
        .map(([shopId, record]) => {
          const activities: OfflineFeatureActivity[] = [
            ...record.tableUsers.map<OfflineFeatureActivity>(({ cashRegisterId, data }) => ({
              type: OfflineFeatureType.TableUser,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.activatedAt,
              resolvedAt: data.deactivatedAt,
              summary: JSON.stringify(data),
            })),
            ...record.orders.map<OfflineFeatureActivity>(({ cashRegisterId, data }) => ({
              type: OfflineFeatureType.Order,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.orderedAt,
              resolvedAt: data.dispatchedAt,
              summary: JSON.stringify(data),
            })),
            ...record.activePlans.map<OfflineFeatureActivity>(({ cashRegisterId, data }) => ({
              type: OfflineFeatureType.ActivePlan,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.createdAt,
              resolvedAt: data.dispatchedAt,
              summary: JSON.stringify(data),
            })),
            ...record.payments.map<OfflineFeatureActivity>(({ cashRegisterId, data }) => ({
              type: OfflineFeatureType.Payment,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.createdAt,
              resolvedAt: data.dispatchedAt,
              summary: JSON.stringify(data),
            })),
          ].sort((a, b) => b.issuedAt.toDate().getTime() - a.issuedAt.toDate().getTime());

          if (activities.length === 0) return null;

          return {
            shopId,
            shopName: shopIdToShopMap.get(shopId)?.name ?? null,
            activities,
          };
        })
        .filter(isNotNull),
    [shopIdToShopMap, unsentOfflineDataRecordMap],
  );

  const offlineUsageData = useMemo(
    () =>
      [...(shopIdToOfflineDataRecordMap?.entries() ?? [])]
        .map(([shopId, record]) => {
          const activities: OfflineFeatureActivity[] = [
            ...record.tableUsers.map<OfflineFeatureActivity>(({ cashRegisterId, data }) => ({
              type: OfflineFeatureType.TableUser,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.activatedAt,
              resolvedAt: data.deactivatedAt,
              summary: JSON.stringify(data),
            })),
            ...record.orders.map<OfflineFeatureActivity>(({ data, cashRegisterId }) => ({
              type: OfflineFeatureType.Order,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.orderedAt,
              resolvedAt: data.dispatchedAt,
              summary: JSON.stringify(data),
            })),
            ...record.activePlans.map<OfflineFeatureActivity>(({ data, cashRegisterId }) => ({
              type: OfflineFeatureType.ActivePlan,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.createdAt,
              resolvedAt: data.dispatchedAt,
              summary: JSON.stringify(data),
            })),
            ...record.payments.map<OfflineFeatureActivity>(({ data, cashRegisterId }) => ({
              type: OfflineFeatureType.Payment,
              recordId: data.id,
              cashRegisterId,
              issuedAt: data.createdAt,
              resolvedAt: data.dispatchedAt,
              summary: JSON.stringify(data),
            })),
          ].sort((a, b) => b.issuedAt.toDate().getTime() - a.issuedAt.toDate().getTime());

          const lastActivityTime =
            maxBy(
              activities.map(({ issuedAt, resolvedAt }) => resolvedAt ?? issuedAt),
              (activityTime) => activityTime.toDate().getTime(),
            ) ?? null;

          return lastActivityTime
            ? {
                shopId,
                shopName: shopIdToShopMap.get(shopId)?.name ?? null,
                lastActivityTime,
                activities,
              }
            : null;
        })
        .filter(isNotNull)
        .sort(
          (a, b) => b.lastActivityTime.toDate().getTime() - a.lastActivityTime.toDate().getTime(),
        ),
    [shopIdToOfflineDataRecordMap, shopIdToShopMap],
  );

  return (
    <MainLayout title="オフライン機能利用状況" withoutEmergencyRoleIdReplacement>
      {unsentOfflineUsageData.length > 0 && (
        <>
          <Alert
            type="warning"
            description={
              <>
                <h3>未送信データ一覧</h3>
                <div>
                  現在未送信になっているデータの一覧です。
                  <br />
                  会計は未送信かどうかに限らず一旦データを保存してから処理を開始し、成功すれば送信済みとして扱われるようになっています。
                  そのためデータ取得のタイミングによって今まさに会計中のものが表示されることを防ぐため、作成から3分経過した未送信状態のもののみを表示するようにしています。
                </div>
              </>
            }
          />
          <Spacer size={12} />
          <Alert
            type="error"
            description={
              <>
                {unsentOfflineUsageData.map(({ shopId, shopName, activities }) => (
                  <Fragment key={shopId}>
                    <p>
                      {shopName} - {shopId}
                      {activities.map((activity, idx) => {
                        const { type, cashRegisterId, recordId, issuedAt } = activity;
                        const url = cashRegisterId
                          ? getFirestoreConsoleUrl({ type, shopId, recordId, cashRegisterId })
                          : "";

                        return (
                          <ListItem key={idx}>
                            <a href={url} target="_blank" rel="noreferrer">
                              {issuedAt.format("YYYY-MM-DD\nHH:mm:ss")} - {typeToDisplayName(type)}
                            </a>{" "}
                            |
                            <Tooltip
                              placement="right"
                              overlayInnerStyle={{ width: "500px" }}
                              title={
                                activity.type === "order" ? (
                                  <OrderContent
                                    summary={createLocalOrderDataSummary({
                                      order: JSON.parse(activity.summary),
                                    })}
                                  />
                                ) : activity.type === "tableUser" ? (
                                  <StartTableUserContent
                                    summary={createLocalStartTableUserDataSummary({
                                      tableUser: JSON.parse(activity.summary),
                                    })}
                                  />
                                ) : activity.type === "payment" ? (
                                  <OnSitePaymentContent
                                    summary={createLocalOnSitePaymentDataSummary({
                                      onSitePayment: JSON.parse(activity.summary),
                                    })}
                                  />
                                ) : activity.type === "activePlan" ? (
                                  <ActivePlanContent
                                    summary={createLocalActivePlanDataSummary({
                                      activePlan: JSON.parse(activity.summary),
                                    })}
                                  />
                                ) : null
                              }
                            >
                              {" "}
                              {/* eslint-disable-next-line */}
                              <a
                                onClick={() =>
                                  shopName &&
                                  cashRegisterId &&
                                  handleMarkRecordAsDispatched({
                                    type,
                                    shopId,
                                    shopName,
                                    cashRegisterId,
                                    recordId,
                                  })
                                }
                              >
                                破棄する
                              </a>
                            </Tooltip>
                          </ListItem>
                        );
                      })}
                    </p>
                  </Fragment>
                ))}
              </>
            }
          />
          <Spacer size={12} />
        </>
      )}
      <Alert
        type="warning"
        description={
          <>
            <h3>オフライン機能利用記録</h3>
            <div>
              過去二週間にオフライン機能を使って行われた卓立ち上げ・注文・プラン開始を確認することができます
            </div>
          </>
        }
      />

      <Spacer size={20} />
      <Collapse bordered={false}>
        {offlineUsageData.map(({ shopId, shopName, lastActivityTime, activities }) => (
          <Collapse.Panel
            key={shopId}
            header={
              <>
                <h4>
                  {shopName} - {shopId}
                </h4>
                {lastActivityTime.format("YYYY-MM-DD HH:mm:ss")}
              </>
            }
          >
            <OfflineFeatureActivityTable activities={activities} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </MainLayout>
  );
});
