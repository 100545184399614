import React, { useMemo } from "react";
import { Badge, Table, TableColumnsType, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  checkNotificationStatus,
  getNotificationCategoryLabel,
  NotificationChannelType,
  NotificationSettings,
} from "models/notificationSettings";
import { isNotNullable } from "util/type/primitive";

import { EditLink } from "./EditLink";

export type TableDisplayTarget = "published" | "draft";

type Props = {
  loading?: boolean;
  displayTarget: TableDisplayTarget;
  notifications: NotificationSettings[];
};

type DataSourceType = ReturnType<typeof selectTableData>;
type Column = TableColumnsType<DataSourceType>[number];
const selectTableData = (notification: NotificationSettings) => {
  const { id, title, labels, category, targets, channels, availableFrom, availableUntil } =
    notification;
  return {
    id,
    title,
    labels,
    category,
    deliveryType: targets.includes("broadcast") ? ("broadcast" as const) : ("narrowcast" as const),
    channels,
    availableTerm: [dayjs(availableFrom.toDate()), dayjs(availableUntil.toDate())],
    status: checkNotificationStatus(notification),
  };
};

export const NotificationTable = ({ loading, displayTarget, notifications }: Props) => {
  const dataSource = useMemo(
    () =>
      notifications
        .map((notification) => selectTableData(notification))
        .sort(({ availableTerm: [a] }, { availableTerm: [b] }) => (a?.isBefore(b) ? 1 : -1)),
    [notifications],
  );

  const filteredDataSource = useMemo(
    () =>
      dataSource.filter(({ status }) => {
        const isDraft = status === "draft" || status === "unknown";
        return displayTarget === "draft" ? isDraft : !isDraft;
      }),
    [dataSource, displayTarget],
  );

  const channelLabels = useMemo<{ key: NotificationChannelType; label: string }[]>(
    () => [
      { key: "handy", label: "ハンディ" },
      { key: "cashRegister", label: "レジ" },
      { key: "dashboard", label: "ダッシュボード" },
    ],
    [],
  );

  const columns = useMemo<TableColumnsType<DataSourceType>>(() => {
    const columns: (Column | null)[] = [
      {
        title: "タイトル",
        dataIndex: "title",
        ellipsis: true,
        render: (value: DataSourceType["title"], record: DataSourceType) => (
          <EditLink record={record}>
            {record.labels.includes("important") && "[重要] "} {value}
          </EditLink>
        ),
      },
      {
        title: "カテゴリ",
        dataIndex: "category",
        width: 160,
        render: (value: DataSourceType["category"]) => getNotificationCategoryLabel(value),
      },
      displayTarget === "draft"
        ? null
        : {
            title: "ステータス",
            dataIndex: "status",
            width: 120,
            render: (value: DataSourceType["status"]) => {
              switch (value) {
                case "draft":
                  return <Badge color="purple" text="下書き" />;
                case "reserved":
                  return <Badge color="green" text="配信予定" />;
                case "started":
                  return <Badge color="blue" text="配信中" />;
                case "ended":
                  return <Badge color="gray" text="配信終了" />;
                default:
                  return null;
              }
            },
          },
      {
        title: "公開期間",
        dataIndex: "availableTerm",
        width: 170,
        render: ([availableFrom, availableUntil]: DataSourceType["availableTerm"]) => (
          <>
            {availableFrom?.format("YYYY/MM/DD HH:mm")}〜
            <br />
            {availableUntil?.format("YYYY/MM/DD HH:mm")}
          </>
        ),
      },
      {
        title: "配信対象",
        dataIndex: "deliveryType",
        width: 100,
        render: (value: DataSourceType["deliveryType"]) => {
          switch (value) {
            case "broadcast":
              return <Tag>すべて</Tag>;
            case "narrowcast":
              return <Tag>個別</Tag>;
            default:
              return null;
          }
        },
      },
      {
        title: "プロダクト",
        dataIndex: "channels",
        width: 230,
        render: (value: DataSourceType["channels"]) => {
          const tags = channelLabels
            .filter((channel) => value.includes(channel.key))
            .map((tag) => <Tag key={tag.key}>{tag.label}</Tag>);

          if (tags.length === channelLabels.length) {
            return <Tag>すべて</Tag>;
          }
          return <>{...tags}</>;
        },
      },
      {
        width: 60,
        align: "center",
        render: (_, record: DataSourceType) => (
          <EditLink record={record}>
            <EditOutlined />
          </EditLink>
        ),
      },
    ];
    return columns.filter(isNotNullable);
  }, [channelLabels, displayTarget]);

  return <Table bordered loading={loading} columns={columns} dataSource={filteredDataSource} />;
};
