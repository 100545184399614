import React, { memo } from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { SELECTED_THEME_KEY } from "context/AppContext";

import { gray } from "constants/colors";

const Content = memo<Props>(({ loading: _, ...props }) => <div {...props} />);

const Wrapper = styled(Content)<Props>`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${localStorage.getItem(SELECTED_THEME_KEY) === "dark" ? gray[9] : gray[1]};
  font-size: 64px;
  transition: opacity ease-out 0.2s;
  opacity: ${({ loading }) => (loading ? "1" : "0")};
  pointer-events: none;
  z-index: 1;
`;

type Props = {
  loading: boolean;
  children?: React.ReactNode;
};

export const Loading = memo<Props>(({ loading, children }) => (
  <>
    <Wrapper loading={loading}>
      <Spin size="large" />
    </Wrapper>
    {loading ? null : children}
  </>
));
