import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OrdersGetMenuOrderItemEvents = gql`
    query OrdersGetMenuOrderItemEvents($shopId: uuid!, $from: timestamptz!) {
  menuOrderItemCreatedEvent(
    where: {menuOrderItem: {order: {shopId: {_eq: $shopId}, orderedAt: {_gt: $from}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        orderedUserId
        orderedClerkName
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
      menuOrderItemQuantityIncreasedEvents {
        id
        quantity
        diffQuantity
        createdAt
      }
      menuOrderItemQuantityDecreasedEvents {
        id
        quantity
        diffQuantity
        createdAt
      }
    }
    createdAt
  }
  menuOrderItemQuantityIncreasedEvent(
    where: {menuOrderItem: {order: {shopId: {_eq: $shopId}}}, createdAt: {_gt: $from}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
  menuOrderItemQuantityDecreasedEvent(
    where: {menuOrderItem: {order: {shopId: {_eq: $shopId}}}, createdAt: {_gt: $from}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
}
    `;
export type OrdersGetMenuOrderItemEventsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['timestamptz'];
}>;


export type OrdersGetMenuOrderItemEventsQuery = (
  { __typename?: 'query_root' }
  & { menuOrderItemCreatedEvent: Array<(
    { __typename?: 'menuOrderItemCreatedEvent' }
    & Pick<Types.MenuOrderItemCreatedEvent, 'id' | 'createdAt'>
    & { menuOrderItem: (
      { __typename?: 'menuOrderItem' }
      & Pick<Types.MenuOrderItem, 'id' | 'name' | 'quantity'>
      & { order: (
        { __typename?: 'order' }
        & Pick<Types.Order, 'id' | 'orderedUserId' | 'orderedClerkName'>
        & { tableUser: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ) }
      ), menuOrderItemQuantityIncreasedEvents: Array<(
        { __typename?: 'menuOrderItemQuantityIncreasedEvent' }
        & Pick<Types.MenuOrderItemQuantityIncreasedEvent, 'id' | 'quantity' | 'diffQuantity' | 'createdAt'>
      )>, menuOrderItemQuantityDecreasedEvents: Array<(
        { __typename?: 'menuOrderItemQuantityDecreasedEvent' }
        & Pick<Types.MenuOrderItemQuantityDecreasedEvent, 'id' | 'quantity' | 'diffQuantity' | 'createdAt'>
      )> }
    ) }
  )>, menuOrderItemQuantityIncreasedEvent: Array<(
    { __typename?: 'menuOrderItemQuantityIncreasedEvent' }
    & Pick<Types.MenuOrderItemQuantityIncreasedEvent, 'id' | 'quantity' | 'diffQuantity' | 'createdAt' | 'updatedClerkName'>
    & { menuOrderItem: (
      { __typename?: 'menuOrderItem' }
      & Pick<Types.MenuOrderItem, 'id' | 'name' | 'quantity'>
      & { order: (
        { __typename?: 'order' }
        & Pick<Types.Order, 'id'>
        & { tableUser: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ) }
      ) }
    ) }
  )>, menuOrderItemQuantityDecreasedEvent: Array<(
    { __typename?: 'menuOrderItemQuantityDecreasedEvent' }
    & Pick<Types.MenuOrderItemQuantityDecreasedEvent, 'id' | 'quantity' | 'diffQuantity' | 'createdAt' | 'updatedClerkName'>
    & { menuOrderItem: (
      { __typename?: 'menuOrderItem' }
      & Pick<Types.MenuOrderItem, 'id' | 'name' | 'quantity'>
      & { order: (
        { __typename?: 'order' }
        & Pick<Types.Order, 'id'>
        & { tableUser: (
          { __typename?: 'tableUser' }
          & Pick<Types.TableUser, 'id'>
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name'>
          ) }
        ) }
      ) }
    ) }
  )> }
);


export const OrdersGetMenuOrderItemEventsDocument = gql`
    query OrdersGetMenuOrderItemEvents($shopId: uuid!, $from: timestamptz!) {
  menuOrderItemCreatedEvent(
    where: {menuOrderItem: {order: {shopId: {_eq: $shopId}, orderedAt: {_gt: $from}}}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        orderedUserId
        orderedClerkName
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
      menuOrderItemQuantityIncreasedEvents {
        id
        quantity
        diffQuantity
        createdAt
      }
      menuOrderItemQuantityDecreasedEvents {
        id
        quantity
        diffQuantity
        createdAt
      }
    }
    createdAt
  }
  menuOrderItemQuantityIncreasedEvent(
    where: {menuOrderItem: {order: {shopId: {_eq: $shopId}}}, createdAt: {_gt: $from}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
  menuOrderItemQuantityDecreasedEvent(
    where: {menuOrderItem: {order: {shopId: {_eq: $shopId}}}, createdAt: {_gt: $from}}
  ) {
    id
    menuOrderItem {
      id
      name
      quantity
      order {
        id
        tableUser {
          id
          table {
            tableId
            name
          }
        }
      }
    }
    quantity
    diffQuantity
    createdAt
    updatedClerkName
  }
}
    `;

/**
 * __useOrdersGetMenuOrderItemEventsQuery__
 *
 * To run a query within a React component, call `useOrdersGetMenuOrderItemEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersGetMenuOrderItemEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersGetMenuOrderItemEventsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useOrdersGetMenuOrderItemEventsQuery(baseOptions: Apollo.QueryHookOptions<OrdersGetMenuOrderItemEventsQuery, OrdersGetMenuOrderItemEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersGetMenuOrderItemEventsQuery, OrdersGetMenuOrderItemEventsQueryVariables>(OrdersGetMenuOrderItemEventsDocument, options);
      }
export function useOrdersGetMenuOrderItemEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersGetMenuOrderItemEventsQuery, OrdersGetMenuOrderItemEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersGetMenuOrderItemEventsQuery, OrdersGetMenuOrderItemEventsQueryVariables>(OrdersGetMenuOrderItemEventsDocument, options);
        }
export type OrdersGetMenuOrderItemEventsQueryHookResult = ReturnType<typeof useOrdersGetMenuOrderItemEventsQuery>;
export type OrdersGetMenuOrderItemEventsLazyQueryHookResult = ReturnType<typeof useOrdersGetMenuOrderItemEventsLazyQuery>;
export type OrdersGetMenuOrderItemEventsQueryResult = Apollo.QueryResult<OrdersGetMenuOrderItemEventsQuery, OrdersGetMenuOrderItemEventsQueryVariables>;