import { useEffect, useState } from "react";
import { isNotNull } from "dinii-self-js-lib/types";
import { Role } from "entity/role.entity";
import { collection, onSnapshot, query, Timestamp, where } from "firebase/firestore";

import { firestore } from "libs/firebase";

export type DeviceStatus = {
  docId: `DiniiDoc:${string}` | `Epson:${string}`;
  lastRebootedAt?: Timestamp;
  lastUpdatedAt: Timestamp;
  lastReceivedCommand?: string;
  lastUpdatedType?: string;
  detail?: string;
  companyId: string;
  shopId: string;
  uid: string;
  battery: number;
  appVersion: number;
  memo?: string;
  disableAlertUntilDate?: Timestamp | null;
  epsonDeviceIdToRoleIdMap?: { [deviceId: string]: Role | null };
  epsonPrinterSerialNumber?: string;
  email?: string;
};

export const useDeviceStatus = ({ shopId }: { shopId: string | undefined }) => {
  const [statuses, setStatuses] = useState<DeviceStatus[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shopId) return;
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(collection(firestore, "deviceStatus"), where("shopId", "==", shopId)),
      (snapshot) => {
        const statuses = snapshot.docs
          .map((doc) => {
            const docId = doc.id;
            if (!docId.startsWith("DiniiDoc") && !docId.startsWith("Epson")) return null;

            const data = doc.data();
            if (!data.uid) return null;

            return {
              ...data,
              docId,
              type: docId.startsWith("Epson") ? "DP" : "PC",
            } as unknown as DeviceStatus;
          })
          .filter(isNotNull)
          .sort((d1, d2) => (d1.lastUpdatedAt > d2.lastUpdatedAt ? -1 : 1));
        setStatuses(statuses);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        console.error(`Encountered error: ${err}`);
      },
    );
    return unsubscribe;
  }, [shopId]);

  return { statuses, loading };
};
