import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../../libs/firebase";
import { MutationRootResolvers } from "../../types";

import {
  SignInSignInToMonitorDocument,
  SignInSignInToMonitorMutation,
  SignInSignInToMonitorMutationVariables,
} from "./queries";

export const signIn: MutationRootResolvers["signIn"] = async (
  _,
  { email, password },
  { client },
) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);

    await client.mutate<SignInSignInToMonitorMutation, SignInSignInToMonitorMutationVariables>({
      mutation: SignInSignInToMonitorDocument,
      fetchPolicy: "no-cache",
    });

    await auth.currentUser?.getIdToken(true);

    return true;
  } catch (error) {
    return false;
  }
};
