import React, { memo } from "react";
import { sumBy } from "lodash";
import { formatPrice } from "util/price";

import { calcMenuOrderItemPrice, LocalOrderDataSummary } from "../type";

type Props = {
  summary: LocalOrderDataSummary;
};

export const OrderContent = memo<Props>(({ summary }) => {
  const { menuOrderItems, tableUserId } = summary;

  const totalQuantity = sumBy(menuOrderItems, (moi) => moi.quantity);

  return (
    <div>
      <p>{totalQuantity} 点</p>
      <pre>
        卓（{tableUserId}）{"\n\n"}
        {summary.menuOrderItems.map((moi) => {
          const price = calcMenuOrderItemPrice(moi);
          const formattedPrice = formatPrice(price);

          return (
            <span key={moi.id}>
              {moi.name}
              {moi.menuOrderItemOptions.length > 0
                ? moi.menuOrderItemOptions
                    .flatMap((option) => {
                      const choicesText = option.menuOrderItemChoices
                        .filter((choice) => choice.quantity > 0)
                        // eslint-disable-next-line no-irregular-whitespace
                        .map((choice) => `　${choice.name}(x${choice.quantity})`)
                        .join("\n");

                      return choicesText ? `${option.name}\n${choicesText}` : [];
                    })
                    .join("\n")
                : null}{" "}
              | {formattedPrice} x {moi.quantity} {"\n"}
            </span>
          );
        })}
      </pre>
    </div>
  );
});
