import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "antd";

import { MainLayout } from "components/Layout/MainLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";
import { ManagedConfig, PrinterAddress } from "libs/createEpsonWebConfigUpdateScript/types";
import { parseProductInfoFromUrl } from "libs/parseProductInfoFromUrl";

import { PrinterAddressForm } from "./PrinterAddressForm";
import { usePrinterSetupGetShopClientsQuery } from "./queries";
import { SetupGuidance } from "./SetupGuidance";
import { ShopClientTable } from "./ShopClientTable";
import { ShopClient } from "./types";

export const PrinterSetup = () => {
  const { getSearchParam } = useSearchParams<"shopId">();
  const shopId = getSearchParam("shopId");

  const [printerAddress, setPrinterAddress] = useState<PrinterAddress | null>(null);
  const productInfo = useMemo(() => parseProductInfoFromUrl(location.href), []);

  const { data, loading } = usePrinterSetupGetShopClientsQuery(
    shopId
      ? {
          variables: { shopId },
        }
      : { skip: true },
  );
  const shopClients = useMemo(() => data?.shopClient ?? [], [data?.shopClient]);

  const onSelectShopClient = useCallback(
    (shopClient: ShopClient) => {
      const env = productInfo?.env;
      const shopClientName = shopClient.email.split("@")[0];
      const password = shopClient.shopId.split("-")[0];

      if (!env || !shopClientName || !password || !printerAddress) return;

      const config: ManagedConfig = {
        env,
        shopClientName,
        password,
        printerList: null,
        printInterval: 3,
        statusInterval: 60,
      };

      Modal.info({
        width: "70%",
        title: "プリンターセットアップ",
        content: <SetupGuidance config={config} printer={printerAddress} />,
      });
    },
    [printerAddress, productInfo?.env],
  );

  return (
    <MainLayout title="DP設定">
      <ShopSelector />

      <Spacer size={16} />

      <PrinterAddressForm onSubmit={setPrinterAddress} />

      <ShopClientTable
        loading={loading}
        shopClients={shopClients}
        disabled={!printerAddress}
        onSelectShopClient={onSelectShopClient}
      />
    </MainLayout>
  );
};
