import React, { memo } from "react";
import styled from "styled-components";
import { Button, message, Typography } from "antd";
import { red } from "@ant-design/colors";
import dayjs from "dayjs";

import { Props as TableProps, Table } from "components/Table";
import { gray } from "constants/colors";

import { EmergencyRoleIdReplacement } from "../../entity/emergencyRoleIdReplacement";
import { Role } from "../../entity/role.entity";
import { useShops } from "../../hooks/useShops";
import { Shop } from "../../hooks/useShops/types";
import { request } from "../../libs/api";

const findShopNameById = (shopId: string, shops: Shop[]): string => {
  const found = shops.find((shop) => shop.shopId === shopId);
  if (found) {
    return found.name;
  } else {
    return "no-name";
  }
};

const findRoleNameById = (roleId: number, roles: Role[]): string => {
  const found = roles.find((role) => role.roleId === roleId);
  if (found) {
    return found.name;
  } else {
    return "no-name";
  }
};

const stopReplacementRequest = async ({
  shopId,
  roleId,
}: {
  shopId: string;
  roleId: number;
}): Promise<string> => {
  try {
    await request.get(`admin/${shopId}/roleReplacement/${roleId}/stop`);
    return "ok";
  } catch (e) {
    return `error: ${e}`;
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const EmergencyRoleIdReplacementTable: React.FC<TableProps<EmergencyRoleIdReplacement>> = Table;

const StyledTable = styled(EmergencyRoleIdReplacementTable)`
  .ant-table-thead > tr > th {
    color: ${gray[0]};
    background: ${red[4]};
  }
`;

type Props = {
  emergencyRoleIdReplacement: EmergencyRoleIdReplacement[];
  roles: Role[];
  refetch: () => void;
};

export const EmergencyPrintBackup = memo<Props>(
  ({ emergencyRoleIdReplacement, roles, refetch }) => {
    const { shops } = useShops();

    const onClickCancelButton = (shopId: string, roleId: number) => {
      const result = window.confirm("緊急伝票振り分け設定を解除します。よろしいですか？");
      if (!result) {
        return;
      }
      stopReplacementRequest({
        shopId,
        roleId,
      }).then((ret) => {
        message.success(ret);
        refetch();
      });
    };

    if (emergencyRoleIdReplacement.length === 0) return null;

    const columns = [
      {
        title: "作成時日時",
        render(_: unknown, { createdAt }: EmergencyRoleIdReplacement) {
          return dayjs(createdAt).tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm:ss");
        },
      },
      {
        title: "Shop ID",
        render(_: unknown, { shopId }: EmergencyRoleIdReplacement) {
          return `${shopId} / ${findShopNameById(shopId, shops)}`;
        },
      },
      {
        title: "振替元",
        render(_: unknown, { roleId }: EmergencyRoleIdReplacement) {
          return `${roleId} / ${findRoleNameById(roleId, roles)}`;
        },
      },
      {
        title: "振替先",
        render(_: unknown, { replaceToRoleId }: EmergencyRoleIdReplacement) {
          return `${replaceToRoleId} / ${findRoleNameById(replaceToRoleId, roles)}`;
        },
      },
      {
        title: "",
        align: "center",
        fixed: "right",
        width: 60,
        render(_: string, { shopId, roleId }: EmergencyRoleIdReplacement) {
          return <Button onClick={() => onClickCancelButton(shopId, roleId)}>Cancel</Button>;
        },
      } as const,
    ];

    return (
      <div>
        <Typography.Title level={5}>緊急伝票振替状況</Typography.Title>
        <StyledTable columns={columns} dataSource={emergencyRoleIdReplacement} pagination={false} />
      </div>
    );
  },
);
