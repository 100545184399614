import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Descriptions, Modal, Tag } from "antd";
import dayjs from "dayjs";
import {
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";

import { Table } from "components/Table";

import { firestore } from "../../../libs/firebase";

const Spacer = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  isEpson: boolean;
  isOpen: boolean;
  uid?: string;
  onClose: () => void;
  onClickPing(): void;
};

type PrinterStatus = {
  docId: string;
  roleId: number;
  roleName: string;
  deviceAddress: string;
  lastUpdatedAt?: Timestamp | null;
  status?: {
    buzzer: number;
    connection: number; // NOTE: 1 が online 0 が offline
    coverOpen: number; // NOTE: 1 が open
    drawer: number;
    errorStatus: number;
    online: number; // NOTE: 1 が online 0 が offline
    panelSwitch: number;
    paper: number; // NOTE: 1 が empty
    paperFeed: number;
    waitOnline: number;
  };
};

const getErrorStatusMessage = (status: number) => {
  switch (status) {
    case 0:
      return "正常";
    case 1:
      return "メカニカルエラー";
    case 2:
      return "オートカッターエラー";
    case 3:
      return "復帰不可能エラー";
    case 4:
      return "自動復帰エラー";
    case 5:
      return "状態不明";
  }
  return "";
};

const StatusBadge = ({ text }: { text: string }) => (
  <Tag color={text === "正常" ? "green" : "red"}>{text}</Tag>
);

const forceResetStatus = ({ docId }: { docId: string }) =>
  setDoc(
    doc(collection(firestore, "printerStatus"), docId),
    {
      lastUpdatedAt: serverTimestamp(),
      status: {
        buzzer: 0,
        connection: 1,
        coverOpen: 0,
        drawer: 0,
        errorStatus: 0,
        online: 1,
        panelSwitch: 0,
        paper: 0,
        paperFeed: 0,
        waitOnline: 0,
      },
    },
    { merge: true },
  );

export const PrinterStatusesDialog = memo<Props>(
  ({ isOpen, uid, onClose, onClickPing, isEpson }) => {
    const [statuses, setStatuses] = useState<PrinterStatus[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (!uid) return;
      setIsLoading(true);
      const subscribe = onSnapshot(
        query(collection(firestore, "printerStatus"), where("uid", "==", uid)),
        (snapshot) => {
          setIsLoading(false);
          const printerStatuses = snapshot.docs
            .map((d) => ({ ...d.data(), docId: d.id } as PrinterStatus))
            .sort((a1, a2) => a1.roleId - a2.roleId);
          setStatuses(printerStatuses);
        },
      );
      return subscribe;
    }, [uid]);

    const columns = [
      {
        title: "Role",
        render(_: unknown, { roleId, roleName }: PrinterStatus) {
          return `${roleId}:${roleName}`;
        },
      },
      { title: "Address", dataIndex: "deviceAddress" },
      {
        title: "",
        align: "center",
        fixed: "right",
        width: 380,
        render(_: string, status: PrinterStatus) {
          return (
            <Descriptions bordered column={1}>
              <Descriptions.Item label="最終更新日時">
                {status.lastUpdatedAt
                  ? dayjs(status.lastUpdatedAt?.toDate()).format("MM/DD HH:mm")
                  : ""}
              </Descriptions.Item>
              <Descriptions.Item label="接続状態">
                <StatusBadge text={status.status?.connection ? "正常" : "非接続です"} />
              </Descriptions.Item>
              <Descriptions.Item label="カバー">
                <StatusBadge text={status.status?.coverOpen ? "カバーが開いています" : "正常"} />
              </Descriptions.Item>
              <Descriptions.Item label="オンライン">
                <StatusBadge text={status.status?.online ? "正常" : "オフラインです"} />
              </Descriptions.Item>
              <Descriptions.Item label="用紙">
                <StatusBadge text={status.status?.paper ? "紙切を起こしています" : "正常"} />
              </Descriptions.Item>
              <Descriptions.Item label="その他エラー">
                <StatusBadge text={getErrorStatusMessage(status.status?.errorStatus ?? -1)} />
              </Descriptions.Item>
              <Descriptions.Item>
                <Button onClick={() => forceResetStatus({ docId: status.docId })}>
                  ログを強制リセット
                </Button>
              </Descriptions.Item>
            </Descriptions>
          );
        },
      } as const,
    ];

    return (
      <>
        <Modal title="プリンター状況" open={isOpen} onCancel={onClose} width={800}>
          <>
            {!isEpson && <Button onClick={onClickPing}>更新リクエスト</Button>}
            <Spacer />
            <Table loading={isLoading} columns={columns} dataSource={statuses} pagination={false} />
          </>
        </Modal>
      </>
    );
  },
);
