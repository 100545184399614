import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledTextArea = styled(Input.TextArea)`
  min-width: 200px;
  margin-bottom: 8px;
`;

export const MemoField = memo<{ onSave(text: string): void; defaultValue: string }>(
  ({ onSave, defaultValue }) => {
    const [value, setValue] = useState(defaultValue);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value),
      [],
    );
    const confirm = useCallback(() => onSave(value), [onSave, value]);

    return (
      <Container>
        <StyledTextArea
          placeholder="メモ(臨時の店舗対応状況や sim, BT アドレスなど)"
          rows={12}
          defaultValue={defaultValue}
          onChange={handleChange}
        />
        <Button type="primary" disabled={defaultValue === value} onClick={confirm}>
          保存
        </Button>
      </Container>
    );
  },
);
