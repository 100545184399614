import React, { Fragment, memo, useCallback, useDeferredValue, useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Button, Divider, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { isOneOfUnion, UnionKey } from "dinii-self-js-lib/object-types";
import { timestampLike } from "dinii-self-js-lib/timestamp";
import { unreachable } from "dinii-self-js-lib/unreachable";
import { ServiceWideFeatureConfig } from "dinii-universal-logic/service-wide-config/v1";
import { Condition } from "dinii-universal-logic/service-wide-config/v1/condition";
import { features } from "dinii-universal-logic/service-wide-config/v1/features";
import { Timestamp } from "firebase/firestore";

import { Spacer } from "components/Spacer";
import { Company } from "hooks/useShops/types";

import { InputCondition } from "./InputCondition";

type InnerProps = {
  disabled: boolean;
  featureName: ServiceWideFeatureConfig["name"];
  defaultValue: Partial<ServiceWideFeatureConfig> | null;
  companies: Company[];
  getShopName: (shopId: string) => string | undefined;
  onSave: (config: ServiceWideFeatureConfig) => Promise<void>;
  onDismiss: () => void;
};

type Props = Omit<InnerProps, "featureName"> & {
  featureName: ServiceWideFeatureConfig["name"] | null;
};

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const Scroll = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const EditServiceWideConfigModalInner = memo<InnerProps>(
  ({ disabled, featureName, companies, defaultValue, getShopName, onSave, onDismiss }) => {
    const [extras, setExtras] = useState<string>(() =>
      defaultValue?.extras ? JSON.stringify(defaultValue.extras) : "null",
    );
    useEffect(() => {
      try {
        JSON.parse(extras);
      } catch {
        setExtras("null");
      }
    }, [extras]);
    const deferredExtras = useDeferredValue(extras);

    const handleChangeExtras = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
      (event) => {
        setExtras(event.currentTarget.value ?? "");
      },
      [],
    );

    const [conditions, setConditions] = useState<Condition[]>(() => defaultValue?.conditions ?? []);

    const addCondition = useCallback((type: UnionKey<Condition>) => {
      setConditions((curr) => {
        switch (type) {
          case "all":
            return [...curr, { all: true }];
          case "disabledShopIds":
            return [...curr, { disabledShopIds: [] }];
          case "rate":
            return [...curr, { rate: 0 }];
          case "shopIds":
            return [...curr, { shopIds: [] }];
          default:
            throw unreachable(type, "addCondition");
        }
      });
    }, []);

    const removeCondition = useCallback((index: number) => {
      setConditions((curr) => {
        if (index < curr.length) {
          return [...curr.slice(0, index), ...curr.slice(index + 1)];
        }
        throw new Error("Index out of range");
      });
    }, []);

    const setCondition = useCallback((index: number, condition: Condition) => {
      setConditions((curr) => {
        if (index < curr.length) {
          return [...curr.slice(0, index), condition, ...curr.slice(index + 1)];
        }
        throw new Error("Index out of range");
      });
    }, []);

    const moveCondition = useCallback((from: number, to: number) => {
      if (from === to) return;
      setConditions((curr) => {
        const value = curr[from];
        if (value) {
          const f = Math.min(Math.max(from, 0), curr.length - 1);
          const t = Math.min(Math.max(to, 0), curr.length - 1);
          return f < t
            ? [...curr.slice(0, f), ...curr.slice(f + 1, t + 1), value, ...curr.slice(t + 1)]
            : [...curr.slice(0, t), value, ...curr.slice(t, f), ...curr.slice(f + 1)];
        }
        throw new Error("Index out of range");
      });
    }, []);

    const renderAddConditionButton = useCallback(
      ({ type, avoidDuplication }: { type: UnionKey<Condition>; avoidDuplication: boolean }) => (
        <Button
          disabled={
            disabled || (avoidDuplication ? conditions.some((c) => isOneOfUnion(c, type)) : false)
          }
          onClick={() => addCondition(type)}
        >
          + {type}
        </Button>
      ),
      [addCondition, conditions, disabled],
    );

    const [{ loading: saving }, handleSave] = useAsyncFn(async () => {
      if (!featureName) return;

      const config: ServiceWideFeatureConfig = {
        name: featureName,
        conditions,
        extras: JSON.parse(extras),
        availableFrom: defaultValue?.availableFrom ?? timestampLike.fromDate(Timestamp, new Date()),
      };

      await onSave(config);
    }, [conditions, defaultValue?.availableFrom, extras, featureName, onSave]);

    return (
      <Modal
        open
        width={900}
        okText="保存する"
        okButtonProps={{ disabled }}
        confirmLoading={saving}
        onOk={disabled || saving ? undefined : handleSave}
        onCancel={onDismiss}
      >
        <h2>{featureName}</h2>
        {features[featureName].description.split("\n").map((line, index) => (
          <div key={index}>{line.trim()}</div>
        ))}

        <Spacer size={24} />

        <h3>Extras (JSON Text)</h3>
        {/* NOTE: transform された結果を反映しつつ、自由に JSON を編集できるように deferred value との比較を defaultValue を更新している */}
        {deferredExtras === extras ? (
          <TextArea
            key="editing"
            disabled={disabled}
            defaultValue={deferredExtras}
            onBlur={handleChangeExtras}
          />
        ) : (
          <TextArea key="refresh" disabled={disabled} value={extras} />
        )}

        <Spacer size={24} />

        <h3>Conditions</h3>
        <Actions>
          {renderAddConditionButton({ type: "all", avoidDuplication: true })}
          <Spacer size={4} inline />
          {renderAddConditionButton({ type: "rate", avoidDuplication: true })}
          <Spacer size={4} inline />
          {renderAddConditionButton({ type: "shopIds", avoidDuplication: false })}
          <Spacer size={4} inline />
          {renderAddConditionButton({ type: "disabledShopIds", avoidDuplication: false })}
        </Actions>
        <Spacer size={24} />
        <Scroll>
          {conditions.map((condition, idx) => (
            <Fragment key={`${idx}-${Object.keys(condition).at(0)}`}>
              <InputCondition
                disabled={disabled}
                index={idx}
                moveCondition={moveCondition}
                removeCondition={removeCondition}
                setCondition={setCondition}
                value={condition}
                companies={companies}
                getShopName={getShopName}
              />
              <Divider />
            </Fragment>
          ))}
        </Scroll>
      </Modal>
    );
  },
);

export const EditServiceWideConfigModal = memo<Props>((props) =>
  props.featureName ? (
    <EditServiceWideConfigModalInner {...props} featureName={props.featureName} />
  ) : null,
);
