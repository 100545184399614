import React, { memo, useState } from "react";
import styled from "styled-components";
import { Checkbox, Form, Input, message, Modal } from "antd";

import {
  ShopClientFormFormValues,
  useCreateShopClientDialogForm,
} from "pages/ShopClients/CreateShopClientDialog/useCreateShopClientDialogForm";

import { Shop } from "../../../hooks/useShops/types";
import { request } from "../../../libs/api";

const shopRoles = ["shop", "shop_admin", "kiosk", "kd", "regi", "handy"];

const CheckboxContainer = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  shop: Shop;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateShopClientDialog = memo<Props>(({ shop, isOpen, onClose }: Props) => {
  const { company, shopId, name } = shop;

  const [roles, setRoles] = useState<string[]>([]);

  const setShopRole = (roleName: string) => {
    if (roles.includes(roleName)) {
      setRoles((roles) => roles.filter((role) => role !== roleName));
    } else {
      setRoles((roles) => [...roles, roleName]);
    }
  };

  const createShopClient = async (formValues: ShopClientFormFormValues) => {
    if (!company) return;

    const createShopClientRequest = {
      ...formValues,
      roles,
      companyId: company.id,
      _companyId: company.serial,
    };

    await request
      .post(`shops/${shopId}/shop_clients`, createShopClientRequest)
      .then(() => {
        message.success("作成しました");
        onClose();
      })
      .catch((err) => {
        console.error(err);
        message.error("作成に失敗しました");
      });
  };

  const {
    form,
    initialValues,
    submit: handleSubmit,
    submitting,
  } = useCreateShopClientDialogForm({
    onSubmit: createShopClient,
  });

  return (
    <Modal
      title={`${name} の Shop Client を作成`}
      open={isOpen}
      onOk={handleSubmit}
      onCancel={onClose}
      confirmLoading={submitting}
    >
      <Form name="createShopClient" form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Name" name="name" colon={false}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" colon={false}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="password" colon={false}>
          <Input />
        </Form.Item>
      </Form>
      {shopRoles.map((name) => (
        <CheckboxContainer key={name}>
          <Checkbox checked={roles.includes(name)} onChange={() => setShopRole(name)}>
            {name}
          </Checkbox>
        </CheckboxContainer>
      ))}
    </Modal>
  );
});
