import * as Types from '../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const HandyStatusGetClientConnectionHistory = gql`
    query HandyStatusGetClientConnectionHistory($input: ClientConnectionHistoryInput!) {
  monitorApiClientConnectionHistory(input: $input) {
    history {
      clientName
      hostName
      timestamp
    }
  }
}
    `;
export type HandyStatusGetClientConnectionHistoryQueryVariables = Types.Exact<{
  input: Types.ClientConnectionHistoryInput;
}>;


export type HandyStatusGetClientConnectionHistoryQuery = (
  { __typename?: 'query_root' }
  & { monitorApiClientConnectionHistory: (
    { __typename?: 'ClientConnectionHistory' }
    & { history: Array<(
      { __typename?: 'ClientConnectionHistoryEntry' }
      & Pick<Types.ClientConnectionHistoryEntry, 'clientName' | 'hostName' | 'timestamp'>
    )> }
  ) }
);


export const HandyStatusGetClientConnectionHistoryDocument = gql`
    query HandyStatusGetClientConnectionHistory($input: ClientConnectionHistoryInput!) {
  monitorApiClientConnectionHistory(input: $input) {
    history {
      clientName
      hostName
      timestamp
    }
  }
}
    `;

/**
 * __useHandyStatusGetClientConnectionHistoryQuery__
 *
 * To run a query within a React component, call `useHandyStatusGetClientConnectionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandyStatusGetClientConnectionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandyStatusGetClientConnectionHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandyStatusGetClientConnectionHistoryQuery(baseOptions: Apollo.QueryHookOptions<HandyStatusGetClientConnectionHistoryQuery, HandyStatusGetClientConnectionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HandyStatusGetClientConnectionHistoryQuery, HandyStatusGetClientConnectionHistoryQueryVariables>(HandyStatusGetClientConnectionHistoryDocument, options);
      }
export function useHandyStatusGetClientConnectionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HandyStatusGetClientConnectionHistoryQuery, HandyStatusGetClientConnectionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HandyStatusGetClientConnectionHistoryQuery, HandyStatusGetClientConnectionHistoryQueryVariables>(HandyStatusGetClientConnectionHistoryDocument, options);
        }
export type HandyStatusGetClientConnectionHistoryQueryHookResult = ReturnType<typeof useHandyStatusGetClientConnectionHistoryQuery>;
export type HandyStatusGetClientConnectionHistoryLazyQueryHookResult = ReturnType<typeof useHandyStatusGetClientConnectionHistoryLazyQuery>;
export type HandyStatusGetClientConnectionHistoryQueryResult = Apollo.QueryResult<HandyStatusGetClientConnectionHistoryQuery, HandyStatusGetClientConnectionHistoryQueryVariables>;