import React, { memo } from "react";
import dayjs from "dayjs";
import { unreachable } from "dinii-self-js-lib/unreachable";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { MenuOrderItemEvent } from "pages/Orders/types";

type Props = {
  loading: boolean;
  events: MenuOrderItemEvent[];
};

const createdEvent = "menuOrderItemCreatedEvent";

const increasedEvent = "menuOrderItemQuantityIncreasedEvent";

const decreasedEvent = "menuOrderItemQuantityDecreasedEvent";

const commonStaffName = "スタッフ (ハンディ or レジ)";

export const OrderTable = memo<Props>(({ loading, events }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "メニュー名",
      width: 500,
      render(_: unknown, event: MenuOrderItemEvent) {
        return event.menuOrderItem.name;
      },
    },
    {
      title: "テーブル名",
      render(_: unknown, event: MenuOrderItemEvent) {
        return event.menuOrderItem.order.tableUser.table.name;
      },
    },
    {
      title: "テーブルユーザー ID",
      render(_: unknown, event: MenuOrderItemEvent) {
        return event.menuOrderItem.order.tableUser.id;
      },
    },
    {
      title: "数量",
      render(_: unknown, event: MenuOrderItemEvent) {
        if (event.__typename === createdEvent) {
          const { menuOrderItemQuantityIncreasedEvents, menuOrderItemQuantityDecreasedEvents } =
            event.menuOrderItem;
          const events = [
            ...menuOrderItemQuantityIncreasedEvents,
            ...menuOrderItemQuantityDecreasedEvents,
          ].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          const firstEvent = events[0];

          if (!firstEvent) return event.menuOrderItem.quantity;

          const { quantity, diffQuantity } = firstEvent;

          // NOTE: 注文時点の数量を最初のイベントから算出する
          if (firstEvent.__typename === increasedEvent) return quantity - diffQuantity;

          // NOTE: 注文時点の数量を最初のイベントから算出する
          if (firstEvent.__typename === decreasedEvent) return quantity - diffQuantity * -1;
        }

        if (event.__typename === increasedEvent) return event.diffQuantity;

        if (event.__typename === decreasedEvent) return event.diffQuantity * -1;
      },
    },
    {
      title: "注文種別",
      render(_: unknown, event: MenuOrderItemEvent) {
        if (event.__typename === createdEvent) return "新規注文";
        if (event.__typename === increasedEvent) return "増量";
        if (event.__typename === decreasedEvent) return "減量";
      },
    },
    {
      title: "注文者",
      render(_: unknown, event: MenuOrderItemEvent) {
        const typeName = event.__typename;

        if (typeof typeName !== "string") return commonStaffName;
        switch (typeName) {
          case createdEvent: {
            const orderedUserId = event.menuOrderItem.order.orderedUserId;
            const orderedClerkName = event.menuOrderItem.order.orderedClerkName;

            if (orderedUserId) return `お客様 (${orderedUserId})`;
            if (orderedClerkName) return `${orderedClerkName} (ハンディ)`;
            return commonStaffName;
          }
          case increasedEvent:
          case decreasedEvent: {
            const updatedClerkName = event.updatedClerkName;
            if (updatedClerkName) return `${updatedClerkName} (ハンディ)`;
            return commonStaffName;
          }
          default:
            unreachable(typeName);
        }
      },
    },
    {
      title: "注文時刻",
      render(_: unknown, event: MenuOrderItemEvent) {
        return dayjs(event.createdAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={events}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
