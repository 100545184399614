import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { collection, doc, getDoc } from "firebase/firestore";
import { NotificationSettings } from "models/notificationSettings";

import { Loading } from "components/App/Loading";
import { NotificationSettingsForm } from "components/Form/NotificationSettingsForm";
import { BaseLayout } from "components/Layout/BaseLayout";
import { firestore } from "libs/firebase";

export const EditNotification = () => {
  const navigate = useNavigate();
  const { id: notificationId } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<NotificationSettings | null>(null);

  useEffect(() => {
    const fetchNotification = async () => {
      setIsLoading(true);
      const res = await getDoc(doc(collection(firestore, "notifications"), notificationId));
      const settings = res.data() as NotificationSettings | undefined;
      if (settings) {
        setSettings(settings);
      }
      setIsLoading(false);
    };

    if (notificationId) {
      fetchNotification().catch(() => {
        setIsLoading(false);
      });
    }
  }, [notificationId]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <BaseLayout
      title="一斉通知を編集する"
      breadcrumb={{ items: [{ name: "一斉通知" }, { name: "詳細" }] }}
      onBack={onBack}
    >
      <Loading loading={isLoading} />
      {!isLoading && !settings && <Alert type="error" message="データが見つかりませんでした" />}
      {settings && <NotificationSettingsForm settings={settings} onCancel={onBack} />}
    </BaseLayout>
  );
};
