import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { Select } from "components/Input/Select";
import { useSearchParams } from "hooks/useSearchParams";
import { useSelectedShop } from "hooks/useSelectedShop";
import { useShops } from "hooks/useShops";

const Container = styled.div`
  margin: 0px;
`;

export const ShopSelector = () => {
  const { setSelectedShop } = useSelectedShop();
  const { getSearchParam, setSearchParam } = useSearchParams<"shopId">();
  const { shops } = useShops();

  const clearShop = useCallback(() => {
    setSelectedShop(null);
    setSearchParam("shopId", null);
  }, [setSelectedShop, setSearchParam]);

  const selectShop = useCallback(
    (shopId: string | null) => {
      setSelectedShop(shopId);
      setSearchParam("shopId", shopId);
    },
    [setSelectedShop, setSearchParam],
  );

  const options = useMemo(
    () => shops?.map((shop) => ({ label: shop.name, value: shop.shopId })) ?? [],
    [shops],
  );

  return (
    <Container>
      <Select
        placeholder="店舗"
        optionFilterProp="label"
        options={options}
        value={options.length ? getSearchParam("shopId") : null}
        onSelect={selectShop}
        onClear={clearShop}
        showSearch
        allowClear
      />
    </Container>
  );
};
