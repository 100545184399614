import { parseProductInfoFromUrl } from "libs/parseProductInfoFromUrl";

import {
  EpsonPrinterWebConfig,
  LocalAreaPrinterInfo,
  ManagedConfig,
  PrintEndpoint,
  PrintEndpointType,
} from "./types";

export type SupportedConfigSections = Pick<
  EpsonPrinterWebConfig,
  "PrinterList" | "ServerDirectPrint" | "StatusNotification"
>;

export const converter = {
  REDACTED_PASSWORD: "P1xx-_a2xx-_S3xx-_s4xx-_xx",
  PRIMARY_PRINTER_TARGET_DEVICE_ID: "local_printer",

  parseProductInfoFromUrl,

  parseDirectPrintUrl(url: string): PrintEndpoint | null {
    const { pathname } = new URL(url);
    const type: PrintEndpointType | null =
      pathname === "/printer/direct_print"
        ? "print"
        : pathname === "/printer/direct_print/status"
        ? "status"
        : null;

    if (!type) return null;

    const productInfo = this.parseProductInfoFromUrl(url);

    if (!productInfo) return null;

    if (productInfo.isLocalhostProxy) {
      return productInfo.name === "backend" ? { env: productInfo.env, type } : null;
    }

    return productInfo.name === "dp" ? { env: productInfo.env, type } : null;
  },

  stringifyDirectPrintUrl({ env, type }: PrintEndpoint) {
    const [_, localProxyName] = env.split(":");
    const hostname = localProxyName
      ? `backend.${localProxyName}.localhost.self.dinii.jp`
      : env === "prod"
      ? "dp.self.dinii.jp"
      : env === "beta"
      ? "dp.beta.self.dinii.jp"
      : env === "stag"
      ? "dp.stag.self.dinii.jp"
      : env === "develop"
      ? "dp.develop.self.dinii.jp"
      : null;
    return `https://${hostname}/printer/direct_print${type === "status" ? "/status" : ""}`;
  },

  fromWebConfig(config: SupportedConfigSections): ManagedConfig | null {
    const printShopClientName = config.ServerDirectPrint.serverDirectPrint.ID;
    const statusShopClientName = config.StatusNotification.statusNotification.ID;

    if (
      !printShopClientName ||
      !statusShopClientName ||
      printShopClientName !== statusShopClientName
    ) {
      return null;
    }
    const shopClientName = printShopClientName;

    const printEndpoint = this.parseDirectPrintUrl(config.ServerDirectPrint.serverDirectPrint.Url1);
    const statusEndpoint = this.parseDirectPrintUrl(
      config.StatusNotification.statusNotification.Url,
    );

    if (
      !printEndpoint ||
      !statusEndpoint ||
      printEndpoint.env !== statusEndpoint.env ||
      printEndpoint.type !== "print" ||
      statusEndpoint.type !== "status"
    ) {
      return null;
    }
    const { env } = printEndpoint;

    const printerList = config.PrinterList.printerList.map(
      ({ DeviceID, IPAddress, ModelName }): LocalAreaPrinterInfo => ({
        deviceId: DeviceID,
        ipAddress: IPAddress,
        model: ModelName,
      }),
    );
    // .filter(({ deviceId }) => deviceId !== this.PRIMARY_PRINTER_TARGET_DEVICE_ID);

    const printInterval = parseInt(config.ServerDirectPrint.serverDirectPrint.Interval1);
    const statusInterval = parseInt(config.StatusNotification.statusNotification.Interval);
    if (isNaN(printInterval) || isNaN(statusInterval)) {
      return null;
    }

    const password =
      config.ServerDirectPrint.serverDirectPrint.Password === this.REDACTED_PASSWORD
        ? null
        : config.ServerDirectPrint.serverDirectPrint.Password;

    return {
      printerList,
      env,
      shopClientName,
      printInterval,
      statusInterval,
      password,
    };
  },

  toWebConfig(next: ManagedConfig, prev: SupportedConfigSections): SupportedConfigSections | null {
    const password = next.password ?? this.REDACTED_PASSWORD;

    const primaryPrinterInfo = prev.PrinterList.printerList.find(
      ({ DeviceID }) => DeviceID === this.PRIMARY_PRINTER_TARGET_DEVICE_ID,
    );

    if (!primaryPrinterInfo) return null;

    const { ControlJSFile } = primaryPrinterInfo;

    return {
      PrinterList: {
        printerList:
          next.printerList?.map(({ deviceId, ipAddress, model }) => ({
            ControlJSFile,
            DeviceID: deviceId,
            IPAddress: ipAddress,
            ModelName: model,
          })) ?? prev.PrinterList.printerList,
      },
      ServerDirectPrint: {
        serverDirectPrint: {
          Active: "1",
          CutReserveTimeout: prev.ServerDirectPrint.serverDirectPrint.CutReserveTimeout,
          ErrorType: prev.ServerDirectPrint.serverDirectPrint.ErrorType,
          ID: next.shopClientName,
          Interval1: `${next.printInterval}`,
          Interval2: `${next.printInterval}`,
          Interval3: `${next.printInterval}`,
          Name: prev.ServerDirectPrint.serverDirectPrint.Name,
          Password: password,
          Url1: this.stringifyDirectPrintUrl({ env: next.env, type: "print" }),
          Url2: "",
          Url3: "",
          UrlEncode: prev.ServerDirectPrint.serverDirectPrint.UrlEncode,
          UseProxy: prev.ServerDirectPrint.serverDirectPrint.UseProxy,
          UseServerAuthentication: prev.ServerDirectPrint.serverDirectPrint.UseServerAuthentication,
        },
      },
      StatusNotification: {
        statusNotification: {
          Active: "1",
          ErrorType: prev.StatusNotification.statusNotification.ErrorType,
          ID: next.shopClientName,
          Interval: `${next.statusInterval}`,
          Name: prev.StatusNotification.statusNotification.Name,
          Password: password,
          Url: this.stringifyDirectPrintUrl({ env: next.env, type: "status" }),
          UrlEncode: prev.StatusNotification.statusNotification.UrlEncode,
          UseProxy: prev.StatusNotification.statusNotification.UseProxy,
          UseServerAuthentication:
            prev.StatusNotification.statusNotification.UseServerAuthentication,
        },
      },
    };
  },
};
