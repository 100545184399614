import {
  CpssioResponse,
  EpsonPrinterWebConfigSection,
  FetchPrinterWebConfigResult,
  GetRequest,
  PrinterAddress,
  SetRequest,
} from "./types";

export const client = {
  getAuthorizationHeader(serialNumber: string) {
    return `Basic ${btoa(`epson:${serialNumber}`)}`;
  },

  isValidPrivateIpAddress(value: unknown) {
    if (typeof value !== "string") return false;
    const bytes = value.split(".");
    if (bytes.length !== 4) return false;
    if (bytes.map((byte) => parseInt(byte, 10)).some((byte) => byte < 0 || byte > 255)) {
      return false;
    }
    return value.startsWith("10.") || value.startsWith("172.16.") || value.startsWith("192.168.");
  },

  async sendWebConfigIRequest(
    {
      path,
      ipAddress,
      serialNumber,
    }: {
      path: string;
      ipAddress: string;
      serialNumber: string;
    },
    req: RequestInit,
  ): Promise<Response | null> {
    return this.isValidPrivateIpAddress(ipAddress)
      ? fetch(`https://${ipAddress}/webconfig-i/${path}`, {
          ...req,
          headers: {
            ...req.headers,
            Authorization: this.getAuthorizationHeader(serialNumber),
          },
        })
      : null;
  },

  async invokeCpssio<T extends EpsonPrinterWebConfigSection>(
    { ipAddress, serialNumber }: PrinterAddress,
    requestInit: { get: GetRequest<T>; set?: never } | { get?: never; set: SetRequest<T> },
  ): Promise<FetchPrinterWebConfigResult<T>> {
    try {
      const request = requestInit.get
        ? { method: "get", json: encodeURIComponent(JSON.stringify(requestInit.get)) }
        : requestInit.set
        ? { method: "set", json: encodeURIComponent(JSON.stringify(requestInit.set)) }
        : null;

      if (!request) {
        return { error: { type: "InvalidRequest", request: requestInit } };
      }

      const res = await this.sendWebConfigIRequest(
        {
          path: `cgi/a_cpssio.php?method=${request.method}&json=${request.json}`,
          ipAddress,
          serialNumber,
        },
        {
          method: "POST",
        },
      );
      if (!res) {
        return { error: { type: "InvalidTarget", ipAddress } };
      }

      if (res.status === 200) {
        const data = (await res.json()) as CpssioResponse<T>;
        return {
          data: data.cpssio ?? null,
        };
      }

      if (res.status === 401) {
        return { error: { type: "AuthenticationError" } };
      }

      return { error: { type: "UnhandledResponse", response: res } };
    } catch (e) {
      if (!(e instanceof Error)) return { error: { type: "UnknownError", error: e } };

      if (e.name === "TypeError") {
        return { error: { type: "NetworkError" } };
      }

      if (e.name === "SyntaxError") {
        return { error: { type: "ResponseJsonSyntaxError" } };
      }

      return { error: { type: "UnknownError", error: e } };
    }
  },

  async invokeRequestToCore0(
    { ipAddress, serialNumber }: PrinterAddress,
    cmd: "MODEOUT" | "MODEIN",
  ) {
    const res = await this.sendWebConfigIRequest(
      {
        path: `cgi/a_request_to_core0.php?cmd=${cmd}`,
        ipAddress,
        serialNumber,
      },
      { method: "POST" },
    );
    return res?.status === 200;
    // 今は使わないがこんな感じのレスポンスが返ってくる
    // {
    //   "info": "(3):37 20 00 ",
    //   "res_code": 0,
    //   "res_msg": ""
    // }
  },

  async fetchIndexOptions({ ipAddress, serialNumber }: PrinterAddress) {
    const res = await this.sendWebConfigIRequest(
      {
        path: `index_options.json?_=${Date.now()}`,
        ipAddress,
        serialNumber,
      },
      { method: "POST" },
    );
    return res?.status === 200;
  },
};
