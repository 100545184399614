import React, { memo } from "react";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

type HandyStatus = {
  deviceId: string;
  uid: string;
  nativeAppVersion: string;
  version: string;
  modelName: string;
  osName: string;
  osVersion: string;
  updatedAt: string;
  shopClientId: string;
};

type Props = {
  loading: boolean;
  handyStatuses: HandyStatus[];
};

export const HandyStatusTable = memo<Props>(({ loading, handyStatuses }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "Device ID", dataIndex: "deviceId" },
    { title: "Account ID", dataIndex: "uid" },
    { title: "Native App Version", dataIndex: "nativeAppVersion" },
    { title: "Version", dataIndex: "version" },
    { title: "Model Name", dataIndex: "modelName" },
    { title: "OS Name", dataIndex: "osName" },
    { title: "OS Version", dataIndex: "osVersion" },
    { title: "Updated At", dataIndex: "updatedAt" },
  ];

  return (
    <Table
      rowKey="deviceId"
      columns={columns}
      dataSource={handyStatuses}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
