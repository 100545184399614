import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Input, message, Modal, Select, Typography } from "antd";

import { Table } from "components/Table";
import { DeviceStatus } from "hooks/useDeviceStatus";

import { Role } from "../../../entity/role.entity";
import { request } from "../../../libs/api";

import { useEpsonDevicePrinterSettingsDialogTestPrintMutation } from "./queries";

const Column = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

type Props = {
  isOpen: boolean;
  status?: DeviceStatus;
  onClose: () => void;
};

const paperWidthOptions = [58, 80];

export const EpsonDevicePrinterSettingsDialog = memo<Props>(({ isOpen, status, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(Date.now());
  const [testPrintMutation] = useEpsonDevicePrinterSettingsDialogTestPrintMutation();

  const addTestPrint = useCallback(
    async ({ roleId, shopId, title }: { roleId: number; shopId: string; title?: string }) => {
      const result = window.confirm("テスト印刷を行いますか？");
      if (!result) {
        return;
      }
      try {
        const { errors } = await testPrintMutation({
          variables: {
            input: {
              roleId,
              shopId,
              title,
            },
          },
        });
        if (errors) {
          const errorMessage = ["エラーが発生しました:", ...errors.map((e) => e.message)].join(
            "\n",
          );
          window.alert(errorMessage);
        }
      } catch (e) {
        window.alert(e);
      }
    },
    [testPrintMutation],
  );

  const [currentRoles, setCurrentRoles] = useState<Record<string, Role | null>>(
    status?.epsonDeviceIdToRoleIdMap ?? {},
  );
  const [testTitles, setTestTitles] = useState<Record<string, string>>({});

  useEffect(() => {
    setCurrentRoles(status?.epsonDeviceIdToRoleIdMap ?? {});
  }, [isOpen, status?.epsonDeviceIdToRoleIdMap]);

  useEffect(() => {
    setTestTitles({});
  }, [isOpen]);

  const saveRole = useCallback(
    async ({
      deviceId,
      roleId,
      paperWidth,
    }: {
      deviceId: string;
      roleId: number;
      paperWidth?: number;
    }) => {
      if (!status?.docId || !status.shopId || !status.companyId) return;
      setIsLoading(true);
      try {
        await request.put(`admin/deviceStatuses/${status.docId}/devices/${deviceId}/role`, {
          shopId: status.shopId,
          roleId,
          paperWidth,
        });
        setUpdatedAt(Date.now());
        onClose();
        message.success("設定を保存しました");
      } catch (e) {
        console.error(e);
        message.error("設定の保存に失敗しました");
      } finally {
        setIsLoading(false);
      }
    },
    [status, onClose],
  );

  const deleteRole = useCallback(
    async (deviceId: string) => {
      if (!status?.docId || !status.companyId) return;
      setIsLoading(true);
      try {
        await request.delete(`admin/deviceStatuses/${status.docId}/devices/${deviceId}/role`);
        setUpdatedAt(Date.now());
        onClose();
        message.success("設定を削除しました");
      } catch (e) {
        console.error(e);
        message.error("設定の削除に失敗しました");
      } finally {
        setIsLoading(false);
      }
    },
    [onClose, status],
  );

  const [roles, setRoles] = useState<Role[]>([]);
  useEffect(() => {
    if (!status) return;
    const f = async () => {
      setIsLoading(true);

      const response = await request.get(`roles/${status.shopId}`);
      const result = response.data as Role[];
      setRoles(result);
      setIsLoading(false);
    };
    f();
  }, [updatedAt, status]);

  const columns = [
    { title: "Device ID", dataIndex: "deviceId" },
    {
      title: "Role",
      render(_: unknown, { deviceId }: { deviceId: string; role: Role | null }) {
        const currentRole = currentRoles[deviceId];

        return (
          <Select
            value={currentRole?.roleId}
            onChange={(roleId) => {
              const role = roles.find((role) => role.roleId === roleId);
              if (!role) return;
              setCurrentRoles((roles) => ({ ...roles, [deviceId]: role }));
            }}
            placeholder="設定なし"
          >
            {roles.map((role) => (
              <Select.Option key={role.roleId} value={role.roleId}>
                {role.roleId}:{role.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Paper Width",
      render(_: unknown, { deviceId }: { deviceId: string; role: Role | null }) {
        const currentRole = currentRoles[deviceId];
        return (
          <Select
            value={currentRole?.paperWidth}
            placeholder="設定なし"
            onChange={(paperWidth) => {
              if (!currentRole) return;
              setCurrentRoles({
                [deviceId]: { ...currentRole, paperWidth },
              });
            }}
          >
            {paperWidthOptions.map((paperWidth) => (
              <Select.Option key={paperWidth} value={paperWidth}>
                {paperWidth}mm
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "",
      render(_: unknown, { deviceId, role }: { deviceId: string; role: Role | null }) {
        const currentRole = currentRoles[deviceId];
        const testTitle = testTitles[deviceId];

        return (
          <>
            <Column>
              <Button
                disabled={!currentRole}
                onClick={() =>
                  currentRole &&
                  saveRole({
                    roleId: currentRole.roleId,
                    deviceId,
                    paperWidth: currentRole.paperWidth,
                  })
                }
              >
                保存
              </Button>
              {role && <Button onClick={() => deleteRole(deviceId)}>削除</Button>}
              {role && <Button onClick={() => addTestPrint(role)}>テスト印刷</Button>}
            </Column>
            {role && (
              <>
                <Typography>テスト印刷タイトル</Typography>
                <Input
                  onChange={(e) =>
                    setTestTitles((titles) => ({ ...titles, [deviceId]: e.target.value }))
                  }
                  value={testTitle}
                />
                {testTitle && (
                  <Button onClick={() => addTestPrint({ ...role, title: testTitle })}>
                    タイトルを変更してテスト印刷
                  </Button>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  const dataSource = useMemo(
    () =>
      Object.entries(status?.epsonDeviceIdToRoleIdMap ?? [])
        .sort(([deviceId1], [deviceId2]) => deviceId1.localeCompare(deviceId2))
        .map(([deviceId, role]) => ({ deviceId, role })),
    [status?.epsonDeviceIdToRoleIdMap],
  );

  return (
    <Modal title="プリンター設定" open={isOpen} onCancel={onClose} width={800}>
      <Table loading={isLoading} columns={columns} dataSource={dataSource} pagination={false} />
    </Modal>
  );
});
