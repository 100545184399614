import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, DatePicker, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const LeftAlignedTypography = styled(Typography)`
  align-self: flex-start;
`;

const EnableSpan = styled.span`
  color: red;
`;

const DisableSpan = styled.span`
  color: gray;
`;

export const DisableAlertField = memo<{
  onSave(date?: Date | null): void;
  defaultValue?: Date | null;
}>(({ onSave, defaultValue }) => {
  const [value, setValue] = useState(defaultValue ?? dayjs("2021-01-01 00:00").toDate());
  const handleChange = useCallback((value: Dayjs | null) => {
    if (!value) return;

    setValue(value.toDate());
  }, []);
  const confirm = useCallback(() => onSave(value), [onSave, value]);
  const isAlertOffEnabled = dayjs().isBefore(dayjs(defaultValue));

  return (
    <Container>
      <LeftAlignedTypography>
        臨時アラート OFF：
        {isAlertOffEnabled ? <EnableSpan>有効</EnableSpan> : <DisableSpan>無効</DisableSpan>}
      </LeftAlignedTypography>
      <StyledDatePicker
        showTime={{ format: "HH:mm" }}
        format="YYYY-MM-DD HH:mm"
        onChange={handleChange}
        defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
        placeholder="日時を選択"
      />
      <Button type="primary" disabled={defaultValue === value} onClick={confirm}>
        保存
      </Button>
    </Container>
  );
});
