import styled from "styled-components";

import { gray } from "constants/colors";

export const Slip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  &:not(:first-child) {
    border-top: 2px dashed ${gray[4]};
  }
`;
