import { useEffect, useState } from "react";
import { collection, onSnapshot, query, Timestamp } from "firebase/firestore";

import { firestore } from "libs/firebase";

export type LocalAreaPrinter = {
  macAddress: string;
} & WebConfigInfo &
  DeviceInfo;

type WebConfigInfo = {
  serialNumber: string | null;
  shopClientName: string | null;
  childPrinters: { deviceId: string; ipAddress: string }[];
  webConfigInfoRawDataJson: string | null;
  webConfigInfoUpdatedAt: Timestamp | null;
};

type DeviceInfo = {
  deviceInfoRawDataJson: string | null;
  deviceInfoUpdatedAt: Timestamp | null;
};

export const useLocalAreaPrinters = ({ shopId }: { shopId: string | undefined }) => {
  const [printers, setPrinters] = useState<LocalAreaPrinter[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shopId) return;
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(collection(firestore, `shops/${shopId}/localAreaPrinters`)),
      (snapshot) => {
        setPrinters(snapshot.docs.map((doc) => doc.data() as LocalAreaPrinter));
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        console.error(`Encountered error: ${err}`);
      },
    );
    return unsubscribe;
  }, [shopId]);

  return { printers, loading };
};
