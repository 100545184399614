import React, { memo } from "react";
import { Form, FormItemProps, Radio } from "antd";

type Props = {
  disabled: boolean;
} & Omit<FormItemProps, "name" | "label" | "children">;

export const DeliveryTypeField = memo<Props>(({ disabled, ...props }) => (
  <Form.Item label="配信対象" name="deliveryType" {...props}>
    <Radio.Group disabled={disabled}>
      <Radio.Button value="broadcast">全体配信</Radio.Button>
      <Radio.Button value="narrowcast">個別配信</Radio.Button>
    </Radio.Group>
  </Form.Item>
));
