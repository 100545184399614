import { useCallback, useState } from "react";
import dayjs from "dayjs";
import { isNotNullish } from "dinii-self-js-lib/types";
import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  where,
} from "firebase/firestore";
import { groupBy } from "lodash";

import { firestore } from "libs/firebase";

type Doc<T> = {
  shopId: string | null;
  cashRegisterId: string | null;
  data: T;
};

type TableUserData<T> = {
  id: string;
  activatedAt: T;
  deactivatedAt: T | null;
};

type OrderData<T> = {
  id: string;
  orderedAt: T;
  dispatchedAt: T | null;
};

type ActivePlanData<T> = {
  id: string;
  createdAt: T;
  dispatchedAt: T | null;
};

type PaymentData<T> = {
  id: string;
  createdAt: T;
  dispatchedAt: T | null;
};

type ShopIdToOfflineDataRecordMap = Map<
  string,
  {
    tableUsers: Doc<TableUserData<dayjs.Dayjs>>[];
    orders: Doc<OrderData<dayjs.Dayjs>>[];
    activePlans: Doc<ActivePlanData<dayjs.Dayjs>>[];
    payments: Doc<PaymentData<dayjs.Dayjs>>[];
  }
>;

const getData = <T>(doc: QueryDocumentSnapshot) => {
  const [_ /* "shops" */, shopId = null, __ /* "cashRegister" */, cashRegisterId = null] =
    doc.ref.path.split("/");
  const data = doc.data() as T;
  return { shopId, cashRegisterId, data };
};

const toDayjs = (value: string | Timestamp) => {
  if (value instanceof Timestamp) return dayjs(value.toDate());
  return dayjs(value);
};

export const useOfflineFeatureUsage = () => {
  const [shopIdToOfflineDataRecordMap, setShopIdToOfflineDataRecordMap] =
    useState<ShopIdToOfflineDataRecordMap | null>(null);

  const [unsentOfflineDataRecordMap, setUnsentOfflineDataRecordMap] =
    useState<ShopIdToOfflineDataRecordMap | null>(null);

  const fetchOfflineData = useCallback(() => {
    const twoWeeksAgoFirestoreTimestamp = Timestamp.fromDate(dayjs().subtract(14, "days").toDate());
    const twoWeeksAgoIsoTimestamp = twoWeeksAgoFirestoreTimestamp.toDate().toISOString();

    const getTableUsers = async () => {
      const result = await getDocs(
        query(
          collectionGroup(firestore, "tableUsers"),
          // NOTE: 卓立ち上げのみ activatedAt が firestore の Timestamp ではなく ISO String で保存されている
          where("activatedAt", ">=", twoWeeksAgoIsoTimestamp),
          orderBy("activatedAt", "desc"),
          limit(1000),
        ),
      );
      return result.docs.map((doc): Doc<TableUserData<dayjs.Dayjs>> => {
        const { shopId, cashRegisterId, data } = getData<TableUserData<string | Timestamp>>(doc);
        return {
          shopId,
          cashRegisterId,
          data: {
            ...data,
            activatedAt: toDayjs(data.activatedAt),
            deactivatedAt: data.deactivatedAt ? toDayjs(data.deactivatedAt) : null,
          },
        };
      });
    };

    const getOrders = async () => {
      const result = await getDocs(
        query(
          collectionGroup(firestore, "orders"),
          where("orderedAt", ">=", twoWeeksAgoFirestoreTimestamp),
          orderBy("orderedAt", "desc"),
          limit(1000),
        ),
      );
      return result.docs.map((doc): Doc<OrderData<dayjs.Dayjs>> => {
        const { shopId, cashRegisterId, data } = getData<OrderData<string | Timestamp>>(doc);
        return {
          shopId,
          cashRegisterId,
          data: {
            ...data,
            orderedAt: toDayjs(data.orderedAt),
            dispatchedAt: data.dispatchedAt ? toDayjs(data.dispatchedAt) : null,
          },
        };
      });
    };

    const getActivePlans = async () => {
      const result = await getDocs(
        query(
          collectionGroup(firestore, "activePlans"),
          where("createdAt", ">=", twoWeeksAgoFirestoreTimestamp),
          orderBy("createdAt", "desc"),
          limit(1000),
        ),
      );
      return result.docs.map((doc): Doc<ActivePlanData<dayjs.Dayjs>> => {
        const { shopId, cashRegisterId, data } = getData<ActivePlanData<string | Timestamp>>(doc);
        return {
          shopId,
          cashRegisterId,
          data: {
            ...data,
            createdAt: toDayjs(data.createdAt),
            dispatchedAt: data.dispatchedAt ? toDayjs(data.dispatchedAt) : null,
          },
        };
      });
    };

    const getPayments = async () => {
      const fewMinutesAgo = dayjs().subtract(3, "minute");
      const result = await getDocs(
        query(
          collectionGroup(firestore, "payments"),
          where("createdAt", ">=", twoWeeksAgoFirestoreTimestamp),
          where("dispatchedAt", "==", null),
          orderBy("createdAt", "desc"),
          limit(1000),
        ),
      );
      return result.docs
        .map((doc): Doc<PaymentData<dayjs.Dayjs>> | null => {
          const { shopId, cashRegisterId, data } = getData<PaymentData<string | Timestamp>>(doc);
          const createdAt = toDayjs(data.createdAt);
          // NOTE: 会計は未送信かどうかに限らず一度 firestore に保存され、
          // 成功していれば dispatchedAt を設定するようになっている。
          // データ取得のタイミングによっては今まさに会計中のものも表示されてしまうため、
          // 直近の数分以内に作成されたものは除外しておく
          if (createdAt.isAfter(fewMinutesAgo)) {
            return null;
          }
          return {
            shopId,
            cashRegisterId,
            data: {
              ...data,
              createdAt,
              dispatchedAt: data.dispatchedAt ? toDayjs(data.dispatchedAt) : null,
            },
          };
        })
        .filter(isNotNullish);
    };

    const fetchData = async () => {
      const [tableUsers, orders, activePlans, payments] = await Promise.all([
        getTableUsers(),
        getOrders(),
        getActivePlans(),
        getPayments(),
      ]);

      const shopIdToTableUserDocsRecord = groupBy(tableUsers, ({ shopId }) => shopId);
      const shopIdToOrderDocsRecord = groupBy(orders, ({ shopId }) => shopId);
      const shopIdToActivePlanDocsRecord = groupBy(activePlans, ({ shopId }) => shopId);
      const shopIdToPaymentDocsRecord = groupBy(payments, ({ shopId }) => shopId);

      const shopIds = [
        ...new Set([
          ...Object.keys(shopIdToTableUserDocsRecord),
          ...Object.keys(shopIdToOrderDocsRecord),
          ...Object.keys(shopIdToActivePlanDocsRecord),
          ...Object.keys(shopIdToPaymentDocsRecord),
        ]),
      ];

      setUnsentOfflineDataRecordMap(
        new Map(
          shopIds.map((shopId) => [
            shopId,
            {
              tableUsers:
                shopIdToTableUserDocsRecord[shopId]?.filter(
                  (doc) => doc.data.deactivatedAt === null,
                ) ?? [],
              orders:
                shopIdToOrderDocsRecord[shopId]?.filter((doc) => doc.data.dispatchedAt === null) ??
                [],
              activePlans:
                shopIdToActivePlanDocsRecord[shopId]?.filter(
                  (doc) => doc.data.dispatchedAt === null,
                ) ?? [],
              payments: shopIdToPaymentDocsRecord[shopId] ?? [],
            },
          ]),
        ),
      );

      setShopIdToOfflineDataRecordMap(
        new Map(
          shopIds.map((shopId) => [
            shopId,
            {
              tableUsers: shopIdToTableUserDocsRecord[shopId] ?? [],
              orders: shopIdToOrderDocsRecord[shopId] ?? [],
              activePlans: shopIdToActivePlanDocsRecord[shopId] ?? [],
              payments: shopIdToPaymentDocsRecord[shopId] ?? [],
            },
          ]),
        ),
      );
    };

    return fetchData();
  }, []);

  return { fetchOfflineData, shopIdToOfflineDataRecordMap, unsentOfflineDataRecordMap };
};
