import React, { memo, useMemo } from "react";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { ShopClient } from "../types";

type Props = {
  loading: boolean;
  shopClients: ShopClient[];
  disabled: boolean;
  onSelectShopClient: (shopClient: ShopClient) => void;
};

export const ShopClientTable = memo<Props>(
  ({ loading, shopClients, disabled, onSelectShopClient }) => {
    const [pagination, setPagination] = usePagination();

    const columns = useMemo<ColumnsType<ShopClient>>(
      () => [
        {
          title: "名前",
          dataIndex: "name",
        },
        {
          title: "メールアドレス",
          dataIndex: "email",
        },
        {
          title: "",
          width: 100,
          render: (_, shopClient) => (
            <>
              <Button
                type="primary"
                disabled={disabled}
                onClick={() => onSelectShopClient(shopClient)}
              >
                設定
              </Button>
            </>
          ),
        },
      ],
      [disabled, onSelectShopClient],
    );

    return (
      <Table
        rowKey="shopClientId"
        loading={loading}
        columns={columns}
        dataSource={shopClients}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
