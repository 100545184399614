import React, { memo } from "react";
import { Col, Form, Input, Row } from "antd";

import { PrinterAddress } from "libs/createEpsonWebConfigUpdateScript/types";

import { usePrinterAddressForm } from "./usePrinterAddressForm";

type Props = {
  onSubmit: (next: PrinterAddress | null) => void;
};

export const PrinterAddressForm = memo<Props>(({ onSubmit }) => {
  const { form, initialValues, submit } = usePrinterAddressForm({ onSubmit });

  return (
    <Form form={form} initialValues={initialValues} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item label="IPアドレス" name="ipAddress">
            <Input onBlur={submit} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="シリアル番号" name="serialNumber">
            <Input onBlur={submit} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
