import React, { memo } from "react";
import { Button, Table } from "antd";
import dayjs from "dayjs";

import { usePagination } from "hooks/usePagination";

import { CheckInEvent } from "../types";

type Props = {
  loading: boolean;
  checkInEvents: CheckInEvent[];
  onSelectCustomer(customerId: string): void;
};

export const CheckInEventTable = memo<Props>(({ loading, checkInEvents, onSelectCustomer }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "ユーザー ID",
      render(_: unknown, { customer: { id } }: CheckInEvent) {
        return id;
      },
    },
    {
      title: "チェックイン時刻",
      render(_: unknown, { checkedInAt }: CheckInEvent) {
        return dayjs(checkedInAt).format("MM/DD HH:mm");
      },
    },
    {
      title: "",
      width: 200,
      align: "center",
      render(_: unknown, { customer }: CheckInEvent) {
        return (
          <Button type="primary" onClick={() => onSelectCustomer(customer.id)}>
            チェックイン履歴
          </Button>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={checkInEvents}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
