import React, { memo, useMemo } from "react";
import { Table } from "antd";

import { usePagination } from "hooks/usePagination";
import { TableUserCustomer as TableUserCustomerComplete } from "types/graphql";

type TableUserCustomer = Pick<TableUserCustomerComplete, "customerId">;

type Props = {
  loading: boolean;
  tableUserCustomers: TableUserCustomer[];
};

export const TableUserCustomerTable = memo<Props>(({ loading, tableUserCustomers }) => {
  const [pagination, setPagination] = usePagination();

  const columns = useMemo(
    () => [
      {
        title: "ユーザー ID",
        dataIndex: "customerId",
      },
    ],
    [],
  );

  return (
    <Table
      rowKey="customerId"
      columns={columns}
      dataSource={tableUserCustomers}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
