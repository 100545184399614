import React from "react";
import { Table as AntdTable } from "antd";
import { TableProps } from "antd/es/table";

export type Props<T> = TableProps<T> & { loading?: boolean };

export const Table = <T extends Record<string, unknown>>({
  loading = false,
  ...props
}: Props<T>) => (
  <AntdTable
    bordered
    loading={{
      spinning: loading,
      size: "large" as const,
    }}
    scroll={{ x: true }}
    {...props}
  />
);
