import { useEffect, useState } from "react";

let cache: Readonly<{ address: string; fetchedAt: number }> | null = null;

export const useIpAddress = () => {
  const [ipAddress, setIpAddress] = useState<string | null>(() => {
    if (!cache) return null;
    if (cache.fetchedAt < Date.now() - 10 * 60000) return null;
    return cache.address;
  });

  const hasAddress = Boolean(ipAddress);

  useEffect(() => {
    if (hasAddress) return;

    const refresh = async () => {
      try {
        const res = await fetch("https://ipinfo.io?callback");
        const data = await res.json();
        const address = (data.ip as string) ?? null;
        setIpAddress(address);
      } catch {
        cache = null;
        setIpAddress(null);
      }
    };

    refresh();
  }, [hasAddress]);

  return { ipAddress };
};
