import "../../configs/dayjs";

import React, { memo } from "react";
import { ConfigProvider, theme } from "antd";
import { useAppContext } from "context/AppContext";

import { Loading } from "components/App/Loading";
import { useAuthEffect } from "hooks/useAuthEffect";

export const App = memo<React.PropsWithChildren>(({ children }) => {
  useAuthEffect();

  const { theme: selectedTheme, isAuthenticated } = useAppContext();

  const loading = isAuthenticated === null;

  const algorithm = { light: theme.defaultAlgorithm, dark: theme.darkAlgorithm }[selectedTheme];

  return (
    <ConfigProvider theme={{ algorithm }}>
      <Loading loading={loading}>{children}</Loading>
    </ConfigProvider>
  );
});
