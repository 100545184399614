import React, { memo, useMemo } from "react";
import { Checkbox, Form, FormItemProps } from "antd";

type Props = {
  disabled: boolean;
} & Omit<FormItemProps, "name" | "label" | "children">;

export const ChannelsField = memo<Props>(({ disabled, ...props }) => {
  const options = useMemo(
    () => [
      { label: "レジ", value: "cashRegister" },
      { label: "ハンディ", value: "handy" },
      { label: "ダッシュボード", value: "dashboard" },
    ],
    [],
  );

  return (
    <Form.Item label="配信対象デバイス" name="channels" {...props}>
      <Checkbox.Group disabled={disabled} options={options} />
    </Form.Item>
  );
});
