import React from "react";
import { Card, DatePicker, Form, Typography } from "antd";
import dayjs from "dayjs";
import { NotificationStatus } from "models/notificationSettings";

import { NotificationSettingsFormValues } from "../useNotificationSettingsForm";

const hours = Array.from({ length: 24 }).map((_, hour) => hour);
const minutes = Array.from({ length: 60 }).map((_, minute) => minute);

type AvailableTerm = NotificationSettingsFormValues["availableTerm"];

export const NotificationScheduleFields = ({ status }: { status: NotificationStatus }) => {
  const startDisabled = status === "started" || status === "ended";
  const endDisabled = status === "ended";
  return (
    <Card>
      <Typography.Title level={4}>配信日時</Typography.Title>
      <Form.Item
        required
        label="配信期間"
        name="availableTerm"
        rules={[
          {
            validator: async (_, [from, until]: AvailableTerm) => {
              if (!from && !until) {
                throw new Error("配信期間を指定してください");
              }
              if (!from) {
                throw new Error("配信開始日時を指定してください");
              }
              if (!until) {
                throw new Error("配信終了日時を指定してください");
              }
            },
          },
        ]}
        normalize={([start, end]: AvailableTerm) => [
          start?.startOf("minute"),
          end?.startOf("minute"),
        ]}
      >
        <DatePicker.RangePicker
          placeholder={["開始日時", "終了日時"]}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          disabledDate={(date) => date.isBefore(dayjs().startOf("date"))}
          disabledTime={(date) => {
            const now = dayjs();
            const disabledHours = () => hours.filter((hour) => now.hour() > hour);
            const disabledMinutes = () => minutes.filter((minute) => now.minute() > minute);
            if (!(date || now).isSame(now, "date")) return {};
            if (!(date || now).isSame(now, "hour")) return { disabledHours };
            return { disabledHours, disabledMinutes };
          }}
          disabled={[startDisabled, endDisabled]}
        />
      </Form.Item>
    </Card>
  );
};
