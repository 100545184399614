import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "context/AppContext";

export const useAuthEffect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAppContext();

  const signInPagePath = "/sign-in";
  const isSignInPagePath = location.pathname === signInPagePath;
  const redirect = useCallback(() => {
    if (isAuthenticated === null) return;
    if (isAuthenticated && isSignInPagePath) navigate("/");
    if (!isAuthenticated && !isSignInPagePath) navigate(signInPagePath);
  }, [isAuthenticated, isSignInPagePath, navigate]);

  useEffect(redirect, [redirect]);
};
