import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { NotificationSettingsForm } from "components/Form/NotificationSettingsForm";
import { BaseLayout } from "components/Layout/BaseLayout";

export const AddNotification = () => {
  const navigate = useNavigate();
  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <BaseLayout
      title="一斉通知を新規作成する"
      breadcrumb={{ items: [{ name: "一斉通知" }, { name: "新規作成" }] }}
      onBack={onBack}
    >
      <NotificationSettingsForm onCancel={onBack} />
    </BaseLayout>
  );
};
