import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useSearchParams = <T extends string>() => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const getSearchParam = useCallback(
    (name: T) => searchParams.get(name) || undefined,
    [searchParams],
  );

  const getSearchParamAll = useCallback(
    <P>(name: T) => searchParams.getAll(name) as P[],
    [searchParams],
  );

  const setSearchParam = useCallback(
    (name: T, value?: string | string[] | null) => {
      if (value === null || typeof value === "undefined") {
        searchParams.delete(name);
      } else if (Array.isArray(value)) {
        searchParams.delete(name);
        value?.forEach((val) => {
          searchParams.append(name, val);
        });
      } else if (searchParams.has(name)) {
        searchParams.set(name, value);
      } else {
        searchParams.append(name, value);
      }

      navigate(
        {
          ...location,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    },
    [searchParams, navigate, location],
  );

  return {
    getSearchParam,
    getSearchParamAll,
    setSearchParam,
  };
};
