import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { SELECTED_THEME_KEY } from "context/AppContext";
import { isObject } from "util/type/primitive";

import BlackLogo from "assets/logo_black.svg";
import WhiteLogo from "assets/logo_white.svg";
import { SimpleLayout } from "components/Layout/SimpleLayout";
import { useSignInSignInMutation } from "pages/SignIn/queries";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  /* NOTE: モード切替の影響で Ant Design の CSS が styled-components より後に読み込まれる */
  margin-top: 16px !important;
  padding: 48px !important;
  border-radius: 8px;
  box-sizing: border-box;
  background: ${() =>
    localStorage.getItem(SELECTED_THEME_KEY) === "dark"
      ? "rgba(255, 255, 255, 0.2)"
      : "rgba(0, 0, 0, 0.2)"};
`;

const FormItem = styled(Form.Item)`
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledInput = styled(Input)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

export const SignIn = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");

  const [signInMutation] = useSignInSignInMutation({ fetchPolicy: "no-cache" });

  const handleFinish = useCallback(async () => {
    const values = await form.validateFields();
    try {
      const email = values.email;
      const password = values.password;

      await signInMutation({ variables: { email, password } });
    } catch (err) {
      if (isObject(err)) {
        if (err.code === "auth/user-not-found") {
          return form.setFields([
            {
              name: "email",
              value: values.email,
              errors: ["ユーザーが存在しません"],
            },
          ]);
        }

        if (err.code === "auth/invalid-email") {
          return form.setFields([
            {
              name: "email",
              value: values.email,
              errors: ["メールアドレスが不正です"],
            },
          ]);
        }

        if (err.code === "auth/wrong-password") {
          return form.setFields([
            {
              name: "password",
              value: values.password,
              errors: ["パスワードが間違っています"],
            },
          ]);
        }
      }

      if (err instanceof Error) return setError(`ログインに失敗しました（${err.message}）`);

      return setError("ログインに失敗しました");
    }
  }, [form, signInMutation]);

  const handleChange = useCallback(() => setError(""), [setError]);

  const Logo = localStorage.getItem(SELECTED_THEME_KEY) === "dark" ? WhiteLogo : BlackLogo;

  return (
    <SimpleLayout title="ログイン">
      <Logo width={200} />

      <StyledForm
        name="sign-in"
        form={form}
        onFinish={handleFinish}
        initialValues={
          import.meta.env.PR_NUMBER
            ? { email: "admin-monitor@pr-test.dinii.jp", password: "testshop2020" }
            : undefined
        }
      >
        <FormItem
          name="email"
          rules={[{ required: true, message: "メールアドレスを入力してください" }]}
          colon={false}
        >
          <StyledInput
            prefix={<UserOutlined />}
            placeholder="メールアドレス"
            onChange={handleChange}
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
          colon={false}
        >
          <StyledInput
            prefix={<LockOutlined />}
            type="password"
            placeholder="パスワード"
            onChange={handleChange}
          />
        </FormItem>

        {error && <StyledAlert message={error} type="error" />}

        <FormItem>
          <StyledButton type="primary" htmlType="submit" block>
            ログイン
          </StyledButton>
        </FormItem>
      </StyledForm>
    </SimpleLayout>
  );
};
