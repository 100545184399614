import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Menu, MenuItemProps } from "antd";
import { collection, getDocs } from "firebase/firestore";
import { NotificationSettings } from "models/notificationSettings";

import { BaseLayout } from "components/Layout/BaseLayout";
import { Spacer } from "components/Spacer";
import { useSearchParams } from "hooks/useSearchParams";
import { firestore } from "libs/firebase";

import { NotificationTable, TableDisplayTarget } from "./NotificationTable";

const availableMenuKeys: TableDisplayTarget[] = ["published", "draft"];

export const NotificationList = () => {
  const navigate = useNavigate();
  const { getSearchParam, setSearchParam } = useSearchParams<"menu">();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<NotificationSettings[]>([]);

  const selectedKey = useMemo(() => {
    const menuKey = getSearchParam("menu") as TableDisplayTarget | undefined;
    if (!menuKey || !availableMenuKeys.includes(menuKey)) {
      setSearchParam("menu", "published");
      return "published";
    }
    return menuKey;
  }, [getSearchParam, setSearchParam]);

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);
      const res = await getDocs(collection(firestore, "notifications"));
      const notifications = res.docs.map((doc) => doc.data() as NotificationSettings);
      setNotifications(notifications);
      setLoading(false);
    };
    fetchNotifications();
  }, []);

  const goToCreation = useCallback(() => {
    navigate("/notifications/add");
  }, [navigate]);

  const onMenuSelect = useCallback<NonNullable<MenuItemProps["onClick"]>>(
    (menuInfo) => {
      setSearchParam("menu", menuInfo.key);
    },
    [setSearchParam],
  );

  return (
    <BaseLayout
      title="一斉通知一覧"
      headerExtra={
        <Button type="primary" onClick={goToCreation}>
          新規作成
        </Button>
      }
    >
      <Card>
        <Menu selectedKeys={[selectedKey]} mode="horizontal">
          <Menu.Item key="published" onClick={onMenuSelect}>
            配信一覧
          </Menu.Item>
          <Menu.Item key="draft" onClick={onMenuSelect}>
            下書き
          </Menu.Item>
        </Menu>
        <Spacer size={16} />
        <NotificationTable
          loading={loading}
          displayTarget={selectedKey}
          notifications={notifications}
        />
      </Card>
    </BaseLayout>
  );
};
