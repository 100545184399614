import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Transformers<T, U> = {
  serialize: (data: T) => U;
  deserialize: (data: U) => T;
};

export const useLocationState = <T extends Record<string, unknown>, U = T>(
  key: string,
  initialState: T,
  transformers?: Transformers<T, U>,
) => {
  const serialize = transformers?.serialize;
  const deserialize = transformers?.deserialize;

  const navigate = useNavigate();
  const location = useLocation();
  const stateRecord = location.state as Record<string, U> | undefined;
  const state = stateRecord?.[key];
  const [locationState, setLocationState] = useState<T>({
    ...initialState,
    ...(state && deserialize ? deserialize(state) : state),
  });

  const handleSetLocationState = useCallback(
    (state: T) => {
      navigate(location, {
        replace: true,
        state: { ...stateRecord, [key]: serialize ? serialize(state) : state },
      });
      setLocationState(state);
    },
    [navigate, location, stateRecord, key, serialize],
  );

  return [locationState, handleSetLocationState] as const;
};
