import React, { memo } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Layout } from "antd";
import { SELECTED_THEME_KEY } from "context/AppContext";
import { getTitle } from "util/title";

import { gray } from "constants/colors";

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100%;
  align-items: center;
  background: ${localStorage.getItem(SELECTED_THEME_KEY) === "dark" ? gray[8] : gray[2]};
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`;

type Props = {
  title: string | string[];
  children: React.ReactNode;
};

export const SimpleLayout = memo<Props>(({ title, children }) => (
  <>
    <Helmet>
      <title>{getTitle(title)}</title>
    </Helmet>
    <StyledLayout>
      <StyledContent>{children}</StyledContent>
    </StyledLayout>
  </>
));
