import React, { memo } from "react";
import { Typography } from "antd";

import { createEpsonWebConfigUpdateScript } from "libs/createEpsonWebConfigUpdateScript";
import { ManagedConfig, PrinterAddress } from "libs/createEpsonWebConfigUpdateScript/types";

type Props = {
  config: ManagedConfig;
  printer: PrinterAddress;
};

export const SetupGuidance = memo<Props>(({ printer, config }) => (
  <ol>
    <li>
      <Typography.Paragraph
        copyable={{
          text:
            printer && config
              ? createEpsonWebConfigUpdateScript(printer, config)
              : "console.log('モニターでの入力内容を確認して再度お試しください')",
        }}
      >
        右のアイコンをクリックしてスクリプトをコピーする
      </Typography.Paragraph>
    </li>
    <li>
      <a target="_blank" href={`https://${printer?.ipAddress}/webconfig-i/`} rel="noreferrer">
        https://{printer?.ipAddress}/
      </a>{" "}
      を開く(セキュリティの警告が出る場合は無視して詳細確認からページを閲覧する)
    </li>
    <li>ログインフォームを無視して &quot;401 - Unauthorized&quot; のページを表示させる</li>
    <li>「F12キー押下」または「右クリック→検証」から開発者ツールを開く</li>
    <li>開発者ツールの中からコンソールのタブを開く</li>
    <li>コピーしたスクリプトをコンソールに貼り付けて Enter で実行する</li>
  </ol>
));
