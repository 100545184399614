import { useEffect, useState } from "react";

import { EmergencyRoleIdReplacement } from "../../entity/emergencyRoleIdReplacement";
import { Role } from "../../entity/role.entity";
import { request } from "../../libs/api";

export const useEmergencyPrintBackup = () => {
  const [emergencyRoleIdReplacement, setEmergencyRoleIdReplacement] = useState(
    [] as EmergencyRoleIdReplacement[],
  );
  const [roles, setRoles] = useState([] as Role[]);

  useEffect(() => {
    request.get("emergency_role_replacement/all").then((ret) => {
      setEmergencyRoleIdReplacement(ret.data);
    });

    request.get("roles").then((ret) => {
      setRoles(ret.data);
    });
  }, []);

  const refetch = async () => {
    const ret = await request.get("emergency_role_replacement/all");
    setEmergencyRoleIdReplacement(ret.data);
  };

  return { emergencyRoleIdReplacement, roles, refetch };
};
