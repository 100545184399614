import React, { useMemo } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";

import { useHandyStatusGetClientConnectionHistoryQuery } from "../queries";

const ListItem = styled.li`
  padding-bottom: 4px;
`;

export const HandyConnectionHistoryToCashRegister = ({ shopId }: { shopId: string | null }) => {
  const { data: clientConnectionHistoryData } = useHandyStatusGetClientConnectionHistoryQuery(
    shopId ? { variables: { input: { shopIds: [shopId] } } } : { skip: true },
  );

  const clientConnectionHistory = useMemo(
    () => clientConnectionHistoryData?.monitorApiClientConnectionHistory.history ?? [],
    [clientConnectionHistoryData?.monitorApiClientConnectionHistory.history],
  );

  return (
    <Alert
      type="info"
      description={
        <>
          <h3>ハンディ・レジ連携接続状況</h3>
          <ul>
            {clientConnectionHistory.map(({ clientName, hostName, timestamp }, idx) => {
              const connectedTime = timestamp
                ? dayjs(timestamp).format("YYYY/MM/DD HH:mm:ss")
                : "接続なし";
              return (
                <ListItem key={idx}>
                  [ハンディ] <strong>{clientName}</strong> ({connectedTime})
                  {hostName ? (
                    <>
                      <br />→ [レジ] <strong>{hostName}</strong>
                    </>
                  ) : null}
                </ListItem>
              );
            })}
          </ul>
        </>
      }
    />
  );
};
