import React, { memo, useCallback, useMemo } from "react";
import JSONViewer from "react-json-viewer";
import styled from "styled-components";
import { Badge, Button, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { keyBy } from "lodash";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { DishUpSlip } from "pages/PrintTargets/PrintTargetTable/DishUpSlip";
import { ShortSlip } from "pages/PrintTargets/PrintTargetTable/ShortSlip";
import { Role } from "pages/PrintTargets/types";

export type PrintTarget = {
  printTargetId: string;
  printTargetType: string;
  jsonContent: string;
  isPrinted: boolean;
  numRead: number;
  totalSlipNum: number;
  roleId: number;
  shopId: string;
  createdAt: Timestamp;
  revision?: number;
};

type ShortSlip = {
  type: "shortSlip";
  roleId: number;
  numberOfPeople: number;
  orderedAt: string;
  tableName: string;
  name: string;
  quantity?: string;
  optionText: string;
  remark: string;
  isMadeForOptionSlip?: boolean;
  ordererName: string;
};

type ShortSlipInput = {
  name: string;
  optionText: string;
  description: string;
  [key: string]: unknown;
};

type DishUpSlip = {
  type: "dishUpSlip";
  roleId: number;
  numberOfPeople: number;
  tableName: string;
  orders: {
    name: string;
    description: string;
    quantity?: number;
    price?: number;
    remark: string;
    choices: {
      name: string;
      quantity?: number;
      price?: number;
    }[];
  }[];
  totalPrice: number;
  orderedAt: string;
  ordererName: string;
};

type Slip = ShortSlip | DishUpSlip;

type PrintTargetDishUpSlipOrder = { description: string } & Omit<
  DishUpSlip["orders"][number],
  "remark"
>;

const convertToSlips = (printTarget: PrintTarget): Slip[] | null => {
  try {
    const { roleId, printTargetType, jsonContent } = printTarget;
    const content = JSON.parse(jsonContent);

    if (printTargetType === "DISH_UP_AND_SHORT_SLIPS") {
      const {
        numberOfPeople,
        orderedAtString,
        tableName,
        ordererName,
        dishUp: { orders, ...dishUp },
        ordersInShortSlips,
      } = content;
      const dishUpSlip: DishUpSlip = {
        type: "dishUpSlip",
        roleId,
        numberOfPeople,
        orderedAt: dishUp.createdAtString,
        tableName,
        orders: orders.map(({ description, ...order }: PrintTargetDishUpSlipOrder) => ({
          remark: description,
          ...order,
        })),
        ...dishUp,
        ordererName,
      };
      return [
        dishUpSlip,
        ...ordersInShortSlips.map(
          (shortSlip: ShortSlipInput): ShortSlip => ({
            ...shortSlip,
            type: "shortSlip",
            roleId,
            numberOfPeople,
            orderedAt: orderedAtString,
            tableName,
            name: shortSlip.name,
            optionText: shortSlip.optionText,
            remark: shortSlip.description,
            isMadeForOptionSlip: Boolean(shortSlip.isMadeForOptionSlip),
            ordererName,
          }),
        ),
      ];
    }

    if (printTargetType === "SHORT_SLIPS") {
      const { numberOfPeople, orderedAtString, tableName, orders, ordererName } = content;
      return orders.map(
        (shortSlip: ShortSlipInput): ShortSlip => ({
          ...shortSlip,
          type: "shortSlip",
          roleId,
          numberOfPeople,
          orderedAt: orderedAtString,
          tableName,
          name: shortSlip.name,
          optionText: shortSlip.optionText,
          remark: shortSlip.description,
          isMadeForOptionSlip: Boolean(shortSlip.isMadeForOptionSlip),
          ordererName,
        }),
      );
    }
  } catch (error) {
    console.error(error);
    return null;
  }

  return null;
};

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SlipContents = styled.div``;

const JSONContainer = styled.div`
  overflow-x: scroll;
`;

type Props = {
  loading: boolean;
  printTargets: PrintTarget[];
  roles: Role[];
  setIsPrinted: (printTarget: PrintTarget, isPrinted: boolean) => void;
  notifyAgain: (printTarget: PrintTarget) => void;
};

export const PrintTargetTable = memo<Props>(
  ({ loading, printTargets, roles, setIsPrinted, notifyAgain }) => {
    const [pagination, setPagination] = usePagination();

    const roleIdToRoleMap = useMemo(() => keyBy(roles, ({ roleId }) => roleId), [roles]);

    const columns = [
      {
        title: "印刷状況",
        render(_: unknown, target: PrintTarget) {
          const { isPrinted, numRead, totalSlipNum } = target;

          return (
            <>
              {isPrinted ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  印刷済み
                </Tag>
              ) : (
                <Tag icon={<SyncOutlined spin />} color="error">
                  未印刷
                </Tag>
              )}
              <Badge
                status={
                  isPrinted ? (numRead === totalSlipNum ? "success" : "warning") : "processing"
                }
                text={`${numRead} / ${totalSlipNum}`}
              />
            </>
          );
        },
      },
      {
        title: "プレビュー",
        render(_: unknown, target: PrintTarget) {
          const slips = convertToSlips(target);

          if (slips === null) return;
          <Tag icon={<CloseCircleOutlined />} color="error">
            印刷プレビューを利用できません
          </Tag>;

          return (
            <SlipContents>
              {slips.map((slip) => {
                if (slip.type === "shortSlip") return <ShortSlip {...slip} />;
                if (slip.type === "dishUpSlip") return <DishUpSlip {...slip}></DishUpSlip>;
              })}
            </SlipContents>
          );
        },
      },
      { title: "Document ID", dataIndex: "printTargetId" },
      {
        title: "作成日",
        render(_: unknown, { createdAt }: PrintTarget) {
          return dayjs(createdAt.toDate()).format("YYYY/MM/DD HH:mm:ss");
        },
      },
      { title: "ロール ID", dataIndex: "roleId" },
      {
        title: "ロール名",
        render(_: unknown, { roleId }: PrintTarget) {
          return roleIdToRoleMap[roleId]?.name;
        },
      },
      {
        title: "タイプ",
        render(_: unknown, { printTargetType }: PrintTarget) {
          return <Tag>{printTargetType}</Tag>;
        },
      },
      {
        title: "",
        align: "center",
        fixed: "right",
        width: 120,
        render(_: unknown, target: PrintTarget) {
          const { isPrinted, revision } = target;

          return (
            <ActionContainer>
              {isPrinted ? (
                <Button type="primary" onClick={() => setIsPrinted(target, false)}>
                  未印刷にする
                </Button>
              ) : (
                <>
                  <Button type="primary" onClick={() => setIsPrinted(target, true)}>
                    印刷済みにする
                  </Button>
                  <Button onClick={() => notifyAgain(target)}>PCに更新通知</Button>
                </>
              )}
              {revision !== undefined && <Tag>Revision: {revision}</Tag>}
            </ActionContainer>
          );
        },
      } as const,
    ];

    const expandedRowRender = useCallback(
      ({ jsonContent }: PrintTarget) => (
        <JSONContainer>
          <JSONViewer json={JSON.parse(jsonContent)} />
        </JSONContainer>
      ),
      [],
    );

    return (
      <Table
        rowKey="printTargetId"
        columns={columns}
        dataSource={printTargets}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        expandable={{ expandedRowRender }}
      />
    );
  },
);
